<template>
  <div>
    <v-row>
      <v-col offset-sm="2" sm="8" offset-md="3" md="6" cols="12">
        <v-form ref="form" v-model="valid" @submit="submit">
          <v-card>
            <v-card-title>
              Please enter your email to receive an email<br>with instructions
              to reset your password.
            </v-card-title>
            <v-card-text>
              <v-text-field
                autofocus
                :rules="[rules.required, rules.validEmail]"
                label="E-Mail"
                required
                v-model="item.email"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn @click="submit" :disabled="!valid" color="error" dark>
                    Reset Link anfordern
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="text-sm-right">
                  <v-btn @click="$router.back()"> zurück </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      :color="sb.color"
      :timeout="sb.timeout"
      bottom
      right
      v-model="sb.visible"
    >
      {{ sb.text }}
      <v-btn @click.native="sb.visible = false" dark text>Schliessen</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AccountService from "../../services/AccountService";
import RulesUtil from "../../util/RulesUtil";

export default {
  name: "ForgotPassword",
  data() {
    return {
      item: {},
      valid: true,
      rules: RulesUtil,
      sb: {
        timeout: 2000,
        color: "success",
        visible: false,
        text: "Info",
      },
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.sb.color = "warning";
        this.sb.text = "Fordere Link an";
        this.sb.visible = true;

        try {
          this.item.url =
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            this.$router.resolve("resetPassword").href;
          const response = await AccountService.forgotPassword(this.item);
          if (response.status === 200) {
            this.sb.color = "success";
            this.sb.text = "Link erfolgreich angefordert";
            this.sb.visible = true;
          }
        } catch (e) {
          this.sb.color = "error";
          this.sb.text =
            "Es existiert kein User für die angegebene E-Mail Addresse";
          if (
            e.response &&
            e.response.status === 400 &&
            e.response.data.message
          ) {
            this.sb.text = e.response.data.message;
          }
          this.sb.visible = true;
        }
      }
    },
  },
};
</script>
