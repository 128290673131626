<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
export default {
  name: "BenefitsTotal",
  components: {
    highcharts: Chart,
  },
  data() {
    return {};
  },
  computed: {
    timeSteps() {
      return this.$store.getters["energyPlanner/benefitsDataTimeSteps"];
    },
    community() {
      return this.$store.getters["energyPlanner/benefitsDataCommunity"];
    },
    chartOptions() {
      return {
        ...this.options,
        series: this.series,
      };
    },
    options() {
      return {
        chart: {
          type: "area",
          zoomType: "x",
        },
        title: {
          text: "",
        },
        colors: [
          "#b8d59e",
          "#ee749f",
          "#99caa0",
          "#132437",
          "#b8d59e",
          "#e43b16",
          ],
        accessibility: { enabled: false },
        xAxis: {
          title: {
            text: this.$t("panels.energyPlanner.benefits.x_axis"),
          },
          crosshair: true,
          type: "datetime",
        },
        yAxis: {
          title: {
            text: this.$t("panels.energyPlanner.benefits.y_axis"),
          },
        },
        tooltip: {
          shared: true,
          pointFormat: "{series.name}: {point.y} kW<br/>",
          valueDecimals: 2,
        },
        credits: { enabled: false },
        plotOptions: {
          area: { fillOpacity: 0.7, stacking: "normal", lineWidth: 1 },
          spline: { lineWidth: 3 },
          series: { marker: { enabled: false } },
        },
      };
    },
    series() {
      const dataTotalPowerCons = [];
      const dataGridCons = [];
      const dataSumSelfCons1 = [];
      const dataSumSelfCons2Plus3 = [];
      const dataTotalProd = [];
      const dataPvSurplus = [];
      let startindex = 0;
      let endIndex = this.timeSteps.length;

      for (let i = 0; i < this.timeSteps.length; i++) {
        const dTime = new Date(this.timeSteps[i]);

        if (startindex <= 0 && dTime >= this.date) {
          startindex = i;
        }
        if (dTime > this.dateEnd) {
          endIndex = i;
          break;
        }
      }
      const stepLength = endIndex - startindex;
      let increment = 1;

      while (
        stepLength / increment >
        process.env.VUE_APP_CHART_DISPLAYED_TIMESTEPS
      ) {
        increment++;
      }
      for (let i = startindex; i < endIndex; i = i + increment) {
        const x = this.$moment(this.timeSteps[i]).valueOf();
        dataTotalPowerCons.push({ x, y: this.community.total_power_cons[i] });
        dataGridCons.push({ x, y: this.community.grid_cons[i] });
        dataSumSelfCons1.push({ x, y: this.community.sum_self_cons1[i] });
        dataSumSelfCons2Plus3.push({
          x,
          y: this.community.sum_self_cons2plus3[i],
        });
        dataTotalProd.push({ x, y: this.community.total_prod[i] });
        dataPvSurplus.push({ x, y: this.community.pv_surplus[i] });
      }

      return [
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.pv_surplus"),
          type: "area",
          data: dataPvSurplus,
        },
        {
          name: this.$t(
            "panels.energyPlanner.benefits.kpi.sum_self_cons2plus3"
          ),
          type: "area",
          data: dataSumSelfCons2Plus3,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.sum_self_cons1"),
          type: "area",
          data: dataSumSelfCons1,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.grid_cons"),
          type: "area",
          data: dataGridCons,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.total_prod"),
          type: "line",
          data: dataTotalProd,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.total_power_cons"),
          type: "line",
          data: dataTotalPowerCons,
        },
      ];
    },
  },
};
</script>
