<template>
  <v-dialog :value="true" @input="close">
    <v-card>
      <v-card-title>
        <div class="d-flex justify-space-between" style="width: 100%">
          <h1></h1>
          <v-btn
            @click="close"
            color="accent"
            :title="$t('general.close')"
            icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </v-card-title>
      <div class="d-flex align-start justify-space-around">
        <date-picker
          :date.sync="dateFrom"
          :date_end.sync="dateTo"
          style="width: 75%"
        />
        <div class="d-flex flex-column justify-space-between">
          <v-btn
            @click="compute"
            :disabled="loading"
            class="ma-5"
            color="accent"
            >{{ $t("panels.energyPlanner.benefits.compute") }}</v-btn
          >
          <v-btn
            v-if="hasBenefitsData"
            color="secondary"
            outlined
            class="ma-5"
            @click="saveLocalBenefits"
          >
            {{ $t("panels.energyPlanner.benefits.save") }}
          </v-btn>
        </div>
      </div>
      <v-spacer />

      <v-divider />
      <v-progress-linear indeterminate :active="loading" />
      <v-card-text>
        <template v-if="!hasBenefitsData">
          <p>{{ $t("panels.energyPlanner.benefits.no_data") }}</p>
        </template>
        <template v-else>
          <v-select
            v-model="selectedUnit"
            :items="dropDownItems"
            return-object
            item-value="unit"
          />
          <v-divider />
          <template v-if="selectedUnit && selectedUnit.id">
            <benefits-unit :unitId="selectedUnit.id" />
          </template>
          <template v-else>
            <benefits-total-kpi-big />
            <v-divider />
            <benefits-total />
            <v-divider />
            <benefits-total-kpi-small />
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import BenefitsTotal from "../benefits/BenefitsTotal.vue";
import BenefitsTotalKpiSmall from "./BenefitsTotalKpiSmall.vue";
import BenefitsTotalKpiBig from "./BenefitsTotalKpiBig.vue";
import BenefitsUnit from "../benefits/BenefitsUnit.vue";
import { EnergyCropType } from "@/util/structs";

export default {
  name: "Benefits",
  components: {
    BenefitsTotal,
    BenefitsTotalKpiSmall,
    BenefitsTotalKpiBig,
    BenefitsUnit,
    DatePicker,
  },
  data() {
    return {
      dateFrom: this.$moment().format("YYYY-MM-DD"),
      dateTo: this.$moment(this.localDate).add(7, "day").format("YYYY-MM-DD"),
      selectedUnit: undefined,
      unitCheckboxes: {},
    };
  },
  computed: {
    noSelectedUnit() {
      return {
        text: this.$t("panels.energyPlanner.benefits.total"),
      };
    },
    loading() {
      return this.$store.getters["energyPlanner/loading"];
    },
    community() {
      return this.$store.getters["energyPlanner/benefitsDataCommunity"];
    },
    buildings() {
      return this.$store.getters["energyPlanner/building/buildings"];
    },
    dataUnits() {
      const units = [];
      if (
        !this.community.properties ||
        this.community.properties.length === 0
      ) {
        return units;
      }

      for (const property of this.community.properties) {
        for (const building of property.buildings) {
          for (const unit of building.units) {
            units.push({ id: unit.id, text: this.unitName(building, unit) });
          }
        }
      }

      return units;
    },
    dropDownItems() {
      let items = [this.noSelectedUnit];
      for (const unit of this.dataUnits) {
        items.push(unit);
      }

      return items;
    },
    benefitsData() {
      return this.$store.getters["energyPlanner/benefitsData"];
    },
    hasBenefitsData() {
      return this.$store.getters["energyPlanner/hasBenefitsData"];
    },
  },
  mounted() {
    this.selectedUnit = this.noSelectedUnit;
  },
  methods: {
    unitName(building, unit) {
      let name = `${building.name} / ${unit.name}`;
      if (unit.name in EnergyCropType) {
        name =
          building.name +
          " /  " +
          this.$t("panels.energyPlanner.crop_type." + unit.name);
      }
      return name;
    },
    initUnitCheckboxes() {
      const unitCheckboxes = {};

      for (const building of this.buildings) {
        for (const unit of building.units) {
          unitCheckboxes[unit.id] = {
            value: true,
            label: this.unitName(building, unit),
          };
        }
      }

      this.unitCheckboxes = unitCheckboxes;
    },
    async saveLocalBenefits() {
      const benefitsjson = this.benefitsData;

      const opts = {
        suggestedName: "ecc_benefits.json",
        types: [
          {
            description: "json file",
            accept: { "application/json": [".json"] },
          },
        ],
      };
      try {
        const fileHandle = await window.showSaveFilePicker(opts);
        const fileStream = await fileHandle.createWritable();
        await fileStream.write(
          new Blob([JSON.stringify(benefitsjson)], { type: "application/json" })
        );
        await fileStream.close();
      } catch (error) {
        // console.log("aborted");
        console.log(error);
      }
    },
    compute() {
      this.$store.dispatch("energyPlanner/computeBenefits", {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
