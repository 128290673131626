<template>
  <v-dialog :value="true" max-width="800" @input="close">
    <v-card>
      <v-card-title>
        {{ $t('panels.energyPlanner.buildings.edit_unit_header') }}
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="errors && errors.general" type="error" color="error">
          {{ errors.general }}
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('general.name') + '*'" v-model="item.name"
                :rules="[rules.required, rules.maxLength(255)]" counter="255" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select :label="$t('panels.energyPlanner.attributes.building.unit.type') + '*'" v-model="item.unitType"
                :items="unitTypes" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.area')" v-model="item.area" type="number"
                :rules="[rules.validOptionalFloat]" suffix="m²" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.aed')" v-model="item.aed" type="number"
                :rules="[rules.validOptionalFloat]" suffix="MWh p.a." />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.occupancy')" type="number"
                v-model.number="item.occupancy" :rules="[rules.validOptionalInteger]" suffix="#" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.eVehicles')" type="number"
                v-model.number="item.eVehicles" :rules="[rules.validOptionalInteger]" suffix="#" />
            </v-col>
          </v-row>
          <h6>{{ $t('panels.energyPlanner.attributes.optional') }}</h6>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.shd')" v-model="item.shd" type="number"
                :rules="[rules.validOptionalFloat]" suffix="MWh p.a." />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.dwd')" v-model="item.dwd" type="number"
                :rules="[rules.validOptionalFloat]" suffix="MWh p.a." />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <ProducerForm :item="item" :errors="errors" producerPrefix="shd_producer"></ProducerForm>
            </v-col>
            <v-col cols="12" sm="6">
              <ProducerForm :item="item" :errors="errors" producerPrefix="dwd_producer"></ProducerForm>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <BatteryForm :item="item" :errors="errors" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="accent" @click="save">{{ $t('general.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'
import { EnergyUnitType } from '@/util/structs'
import BatteryForm from './BatteryForm.vue'
import ProducerForm from './ProducerForm.vue'

export default {
  name: "UnitEditDialog",
  components: {
    BatteryForm,
    ProducerForm
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          name: '',
          occupancy: 0,
          area: 0,
          unitType: EnergyUnitType.RESIDENTIAL,
          eVehicles: 0,
          shd: 0,
          dwd: 0,
          aed: 0,
          battery: undefined,
          shd_producer: undefined,
          dwd_producer: undefined
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      errors: false
    }
  },
  computed: {
    rules() {
      return RulesUtil
    },
    buildingId() {
      return this.$store.getters['energyPlanner/building/buildingId']
    },
    unitTypes() {
      const types = []
      for (const key in EnergyUnitType) {
        types.push({ value: EnergyUnitType[key], text: this.$t('panels.energyPlanner.unit_type.' + key) })
      }

      return types
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true

      this.errors = await this.$store.dispatch('energyPlanner/building/saveUnit', {
        buildingId: this.buildingId,
        unit: {
          ...this.item
        }
      })
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages(field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field]
      }

      return ''
    }
  },
  watch: {
    item() {
      this.errors = false
    }
  }
}
</script>
