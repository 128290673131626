<template>
  <v-dialog
    :value="open"
    max-width="600"
    @input="close"
  >
    <v-card>
      <v-card-title>
        {{ $t('panels.substation.edit_substation_header') }}
        <v-spacer></v-spacer>
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            :label="$t('general.name') + (editMode ? '*' : '')"
            v-model="item.name"
            :rules="nameRules"
            :error-messages="errorMessages('name')"
            persistent-hint
            :hint="$t('panels.substation.name_hint')"
          />
          <v-text-field
            :label="$t('general.address')"
            v-model="item.address"
            :rules="[rules.maxOptionalLength(255)]"
            :error-messages="errorMessages('address')"
          />
          <v-text-field
            :label="$t('panels.substation.power') + '*'"
            v-model.number="item.power"
            :rules="[rules.required, rules.validFloat]"
            :error-messages="errorMessages('power')"
          />
          <!-- <v-text-field
            :label="$t('panels.substation.customer_id')"
            v-model="item.customer_id"
            :rules="[rules.maxOptionalLength(255)]"
            :error-messages="errorMessages('customer_id')"
          />
          -->
          <!-- <v-text-field
            :label="$t('panels.substation.area')"
            v-model.number="item.area"
          /> -->
          <!--
          <v-text-field
            :label="$t('panels.substation.rented')"
            v-model.number="item.rented"
            disabled
          /> -->
          <v-text-field
            :label="$t('panels.substation.fid')"
            v-model.number="item.fid"
          />
        </v-form>
      </v-card-text>
      <v-card-subtitle style="color:red" v-if="errors">{{errors}}</v-card-subtitle>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="green" @click="save">{{ $t('general.save') }}</v-btn>
        <!-- <v-btn @click="close">{{ $t('general.close') }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'

export default {
  name: "SubstationEditDialog",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () {
        return {
          name: '',
          power: 0,
          area: 0,
          address: '',
          customer_id: '',
          fid: null
        }
      }
    }
  },
  data () {
    return {
      valid: true,
      loading: false,
      errors: false
    }
  },
  computed: {
    rules () {
      return RulesUtil
    },
    editMode() {
      return !!this.item.id
    },
    nameRules() {
      if (this.editMode) {
        return [this.rules.maxLength(255)];
      } else {
        return [this.rules.maxOptionalLength(255)];
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.errors = await this.$store.dispatch('substation/saveItem', this.item)
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field]
      }

      return ''
    }
  },
  watch: {
    item () {
      this.errors = false
    }
  }
}
</script>
