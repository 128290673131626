<template>
  <v-dialog :value="true" max-width="800" @input="close">
    <v-card>
      <v-card-title>
        {{ $t('panels.energyPlanner.buildings.edit_building_header') }}
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="errors && errors.general" type="error" color="error">
          {{ errors.general }}
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('general.name')" v-model="item.name"
                :rules="[rules.required, rules.maxLength(255)]" :error-messages="errorMessages('name')" />
            </v-col>
            <!-- <v-col cols="12" sm="6">
              <v-select :items="buildingTypes" v-model="item.type"
                :label="$t('panels.energyPlanner.attributes.building.type')"></v-select>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.roofarea')"
                v-model.number="item.roofarea" type="number" :rules="[rules.validOptionalFloat]"
                :error-messages="errorMessages('roofarea')" suffix="m²" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('panels.energyPlanner.attributes.building.area')" v-model.number="item.area"
                type="number" :rules="[rules.validOptionalFloat]" suffix="m²" />
            </v-col>
          </v-row>
          <PvModuleForm :item="item" :errors="errors" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="accent" @click="save">{{ $t('general.save') }}</v-btn>
        <v-btn @click="close">{{ $t('general.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'
import { EnergyBuildingType } from '@/util/structs'
import PvModuleForm from '../common/PvModuleForm.vue'

export default {
  name: "BuildingEditDialog",
  components: {
    PvModuleForm
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          name: '',
          roofarea: 0,
          area: 0,
          type: EnergyBuildingType.MIX,
          pv_module: undefined
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      errors: false,
      pvModuleFormVisible: false
    }
  },
  computed: {
    buildingTypes() {
      const items = []
      for (const key in EnergyBuildingType) {
        items.push({
          text: this.$t('panels.energyPlanner.building_type.' + key),
          value: EnergyBuildingType[key]
        })
      }

      return items
    },
    rules() {
      return RulesUtil
    }
  },
  methods: {
    pvModuleEdit() {
      if (!this.item.pv_module) {
        this.item.pv_module = {}
      }
      this.pvModuleFormVisible = true
    },
    pvModuleDelete() {
      this.pvModuleFormVisible = false
      this.item.pv_module = undefined
    },
    roundDownto2(value) {
      return Math.round(value * 100) / 100
    },
    close() {
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.errors = await this.$store.dispatch('energyPlanner/building/saveBuilding', this.item)
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages(field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field]
      }

      return ''
    }
  },
  watch: {
    item() {
      this.errors = false
    }
  }
}
</script>
