export const clickStates = {
  IDLE: 0,
  PLANT_PLACE_GEOM: 1,
  PLANT_CHOOSE_RETURN: 2,
  PLANT_CHOOSE_SUPPLY: 3,
  SUBSTATION_PLACE_GEOM: 4,
  SUBSTATION_CHOOSE_RETURN: 5,
  SUBSTATION_CHOOSE_SUPPLY: 6,
  PLACE_NODE: 7,
  EDGE_CHOOSE_NODE_UP: 8,
  EDGE_CHOOSE_NODE_DOWN: 9,
  STORAGE_PLACE_GEOM: 10,
  STORAGE_CHOOSE_RETURN: 11,
  STORAGE_CHOOSE_SUPPLY: 12,
  PLANT_QUERY_GEOM: 13,
  SUBSTATION_QUERY_GEOM: 14,
  BUILDING_QUERY: 15,
  BUILDING_DRAW: 16,
  FIELD_QUERY: 17,
  FIELD_DRAW: 18,
  COOLING_HOUSE_QUERY: 19,
  COOLING_HOUSE_DRAW: 20
}

export const featureTypes = {
  PLANT: 0,
  SUBSTATION: 1,
  NODE: 2,
  EDGE: 3,
  STORAGE: 4,
  BUILDING: 5,
  FIELD: 6,
  COOLING_HOUSE: 7
}

export const EnergyBuildingType = {
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
  MIX: 3
}

export const EnergyUnitType = {
  RESIDENTIAL: 1,
  COMMERCIAL: 2
}

export const EnergyProductionFuelType = {
  FOSSIL_FUEL_BOILER: 1,
  BIOMASS_BOILER: 2,
  HEAT_PUMP: 3,
  POWER: 4
}

export const EnergyStorageType = {
  STANDARD: 1,
  DYNASTRAT: 2
}

export const EnergyCropType = {
  ASPARAGUS: 'ASPARAGUS',
  CARROT: 'CARROT',
  CORN: 'CORN',
  GREENBEAN: 'GREENBEAN',
  ONION: 'ONION',
  POTATO: 'POTATO',
  SOY: 'SOY',
  SPINACH: 'SPINACH',
  SUGAR: 'SUGAR',
  WHEAT: 'WHEAT'
}
