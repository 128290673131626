<template>
  <div>
    <div v-if="itemLoaded">
      <div v-if="!itemLoadError">
        <v-row>
          <v-col offset-sm="3" sm="6" cols="12">
            <v-form ref="form" v-if="!reset_done" v-model="valid">
              <v-card>
                <v-card-text>
                  <v-text-field
                    disabled
                    :counter="128"
                    :rules="[rules.required, rules.validEmail]"
                    label="Email"
                    required
                    v-model="item.email"
                  />
                  <v-text-field
                    autofocus
                    :counter="128"
                    :rules="[rules.required, rules.validPassword(8)]"
                    @input="passwordsMatch()"
                    label="New password"
                    v-model="item.password"
                    type="Password"
                  />
                  <v-text-field
                    :counter="128"
                    :rules="[rules.required, rules.validPassword(8)]"
                    :error-messages="passwordConfirmErrors"
                    @input="passwordsMatch()"
                    label="confirm new password"
                    v-model="item.password_confirmation"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="submit" :disabled="!valid" color="error">
                    Neues Passwort setzen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
            <v-card v-else>
              <v-card-text>
                Das Passwort wurde erfolgreich neu gesetzt.
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'login'}" color="error">
                  Zum Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar
          :color="sb.color" :timeout="sb.timeout"
          bottom
          right
          v-model="sb.visible"
        >
          {{ sb.text }}
          <v-btn @click.native="sb.visible = false" dark text>Schliessen</v-btn>
        </v-snackbar>
      </div>
      <div v-else>
        <v-row>
          <v-col offset-sm="3" sm="6" cols="12">
            <v-card>
              <v-card-text>
                Der Passwort Reset Link ist ungültig.
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'forgotPassword'}" color="error" dark>
                  Neuen Link anfordern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <spinner />
    </div>
  </div>
</template>

<script>
import AccountService from '../../services/AccountService'
import RulesUtil from '../../util/RulesUtil'
import Spinner from '@/components/Spinner'

export default {
  name: 'ResetPassword',
  components: { Spinner },
  props: ['token', 'email'],
  data () {
    return {
      itemLoaded: false,
      itemLoadError: false,
      reset_done: false,
      item: {
        token: this.token,
        email: this.email
      },
      valid: true,
      rules: RulesUtil,
      passwordConfirmErrors: [],
      sb: {
        timeout: 2000,
        color: 'success',
        visible: false,
        text: 'Info'
      }
    }
  },
  created () {
    this.loadItem()
  },
  methods: {
    async loadItem () {
      try {
        await AccountService.verifyResetLink({
          token: this.token,
          email: this.email
        })
        this.itemLoaded = true
      } catch (e) {
        this.itemLoaded = true
        this.itemLoadError = true
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        if (!this.passwordsMatch()) {
          return
        }

        this.sb.color = 'warning'
        this.sb.text = 'requesting link'
        this.sb.visible = true

        try {
          const response = await AccountService.resetPassword(this.item)
          if (response.status === 200) {
            this.sb.color = 'success'
            this.sb.text = 'Password successfully set'
            this.sb.visible = true
            this.reset_done = true
          }
        } catch (e) {
          this.sb.color = 'error'
          this.sb.text = 'Password reset failed'
          this.sb.visible = true
        }
      }
    },
    passwordsMatch () {
      if (!this.item.password_confirmation || this.item.password_confirmation.length < 8) {
        this.passwordConfirmErrors = []
        return true
      }
      const passwordsMatching = this.rules.identicalValues(this.item.password, this.item.password_confirmation, 'Neues Passwort')
      if (passwordsMatching !== true) {
        this.passwordConfirmErrors = [passwordsMatching]
        return false
      } else {
        this.passwordConfirmErrors = []
        return true
      }
    }
  }
}
</script>
