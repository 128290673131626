import Api from "./Api";

export default {
  latestSimulationTimeRange() {
    return Api().get("/analysis/latest_simulation_time_range");
  },
  aggregatedSimulationData({ selectedStartDate, selectedEndDate, mode, threshold }) {
    return Api().get("/analysis/aggregated_simulation_data", {
      params: {
        selected_start_date: selectedStartDate,
        selected_end_date: selectedEndDate,
        mode,
        threshold
      },
    });
  },
};
