<template>
  <panel name="edge" :initial-active="active">
    <template v-slot:fixed-content>
      <v-card-text class="py-0">
        <div class="d-flex" style="gap: 24px">
          <v-btn color="secondary" @click="newEdge(true)">
            <v-icon left>mdi-map-marker-up</v-icon>{{ $t('panels.edge.new_supply') }}</v-btn>
          <v-btn color="primary" @click="newEdge(false)">
            <v-icon left>mdi-map-marker-down</v-icon>{{ $t('panels.edge.new_return') }}</v-btn>
        </div>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.search')" class="mt-3"/>
        <v-tabs v-model="tab">
          <v-tab tab-value="all">{{ $t('general.all') }}</v-tab>
          <v-tab tab-value="supply"><v-icon color="secondary" left>mdi-map-marker-up</v-icon>{{ $t('general.supply')
          }}</v-tab>
          <v-tab tab-value="return"><v-icon color="primary" left>mdi-map-marker-down</v-icon>{{ $t('general.return')
          }}</v-tab>
        </v-tabs>
      </v-card-text>
    </template>
    <v-card-text>
      <div class="pb-10" style="background-color: #FAFAFA;">
        <v-data-table :items="items" :headers="headers" v-model="selected" :options.sync="options" :search="search">
          <template v-slot:[`item.name`]="{ item }">
            <span :style="itemNameStyle(item)">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.actions.choose_node_up`]="{ item }">
            <v-btn icon @click="() => chooseNode(item, true)" :title="$t('panels.edge.choose_node_up')"
              :color="chooseNodeColor(item, true)"
              :outlined="clickState === clickStates.EDGE_CHOOSE_NODE_UP && clickOptions.id === item.id">
              <v-icon>mdi-map-marker-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions.choose_node_down`]="{ item }">
            <v-btn icon @click="() => chooseNode(item, false)" :title="$t('panels.edge.choose_node_down')"
              :color="chooseNodeColor(item, false)"
              :outlined="clickState === clickStates.EDGE_CHOOSE_NODE_DOWN && clickOptions.id === item.id">
              <v-icon>mdi-map-marker-down</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions.locate_on_map`]="{ item }">
            <v-btn icon @click="() => locateEdge(item)" :title="$t('general.locate_on_map')"
              :color="itemActionColor(item)"><v-icon>mdi-map-marker-circle</v-icon></v-btn>
          </template>
          <template v-slot:[`item.actions.show_data`]="{ item }">
            <v-btn icon @click="() => openMapPopup(item)" :title="$t('general.show_data')"
              :color="itemActionColor(item)"><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-btn>
          </template>
          <template v-slot:[`item.actions.rest`]="{ item }">
            <span class="d-inline-flex">
              <v-btn icon @click="() => editEdge(item)" :title="$t('general.edit')"
                color="green"><v-icon>mdi-pencil</v-icon></v-btn>
              <v-btn icon @click="() => deleteEdge(item)" :title="$t('general.delete')"
                color="error"><v-icon>mdi-delete</v-icon></v-btn>
            </span>
          </template>
        </v-data-table>
        <edge-edit-dialog v-if="editOpen" :item="editItem" :open="editOpen" @close="closeEdit" />
        <edge-delete-dialog v-if="deleteOpen" :item="deleteItem" :open="deleteOpen" @close="closeDelete" />
      </div>
    </v-card-text>
  </panel>
</template>

<script>
import Panel from '../Panel.vue'
import { clickStates, featureTypes } from '../../../util/structs'
import EdgeEditDialog from './EdgeEditDialog.vue'
import EdgeDeleteDialog from './EdgeDeleteDialog.vue'
import { colors } from "vuetify/lib";

const highlighColor =
  process.env.VUE_APP_EDGE_HIGHLIGHT_COLOR ?? colors.red.accent4;

export default {
  name: 'EdgePanel',
  props: {
    active: Boolean,
  },
  components: {
    Panel,
    EdgeEditDialog,
    EdgeDeleteDialog
  },
  data() {
    return {
      tab: 'all',
      selected: [],
      options: {},
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      search: ''
    }
  },
  computed: {
    clickStates() {
      return clickStates
    },
    clickState() {
      return this.$store.getters['map/clickState']
    },
    clickOptions() {
      return this.$store.getters['map/clickOptions']
    },
    itemsUnfiltered() {
      return this.$store.getters['edge/items']
    },
    items() {
      if (this.tab === 'all') {
        return this.itemsUnfiltered
      }

      const desiredSupply = this.tab === 'supply'
      return this.itemsUnfiltered.filter(_ => _.supply === desiredSupply)
    },
    headers() {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('panels.edge.choose_node_up'),
          value: 'actions.choose_node_up',
          align: 'center',
          filterable: false,
          sortable: false
        },
        {
          text: this.$t('panels.edge.choose_node_down'),
          value: 'actions.choose_node_down',
          align: 'center',
          filterable: false,
          sortable: false
        },
        {
          text: this.$t('general.locate_on_map'),
          value: 'actions.locate_on_map',
          align: 'center',
          filterable: false,
          sortable: false
        },
        {
          text: this.$t('general.show_data'),
          value: 'actions.show_data',
          align: 'center',
          filterable: false,
          sortable: false
        },
        {
          text: this.$t('general.actions'),
          value: 'actions.rest',
          align: 'center',
          filterable: false,
          sortable: false
        }
      ]
    },
    panelErrors() {
      return this.$store.getters['edge/panelErrors']
    },
    mapComponent() {
      return this.$store.getters['map/component']
    },
  },
  methods: {
    itemActionColor(item) {
      return item.supply ? 'secondary' : 'primary'
    },
    itemNameStyle(item) {
      let style = {};
      if (item.node_up_id === null || item.node_down_id === null) {
        style.color = highlighColor;
      }
      return style;
    },
    openMapPopup(item) {
      this.$store.dispatch('mapPopup/open', {
        item,
        type: featureTypes.EDGE
      })
    },
    highlightRow(item) {
      this.selected = [item]
      if (this.options.itemsPerPage === -1) return
      // find correct page
      const index = this.items.findIndex((t) => t.id === item.id)
      if (index !== -1) {
        this.options.page = Math.floor((index) / this.options.itemsPerPage) + 1
      }
    },
    chooseNodeColor(item, up) {
      const key = up ? 'node_up_id' : 'node_down_id'
      return (item[key] === null ? highlighColor : this.itemActionColor(item))
    },
    closeEdit() {
      this.editOpen = false
      this.editItem = undefined
    },
    newEdge(isSupply = false) {
      // console.log(isSupply)
      if (isSupply) {
        this.openEdit({ supply: true, fid: null, name: "" });
      } else {
        this.openEdit()
      }
    },
    editEdge(item) {
      this.openEdit(item)
    },
    openEdit(item) {
      // console.log(item)
      this.editItem = item ? { ...item } : undefined
      this.editOpen = true
    },
    deleteEdge(item) {
      this.deleteItem = item
      this.deleteOpen = true
    },
    closeDelete() {
      this.deleteOpen = false
      this.deleteItem = undefined
    },
    locateEdge(item) {
      this.mapComponent.locateEdge(item.id, item.supply)
    },
    chooseNode(item, up) {
      let payload = null

      const clickState = up ? clickStates.EDGE_CHOOSE_NODE_UP : clickStates.EDGE_CHOOSE_NODE_DOWN

      if (this.clickState === clickState) {
        payload = {
          clickState: clickStates.IDLE
        }
      } else {
        payload = {
          clickState: clickState,
          clickOptions: {
            id: item.id,
            supply: item.supply,
            nodeUpId: item.node_up_id,
            nodeDownId: item.node_down_id
          }
        }
      }

      this.$store.dispatch('map/setClickState', payload)
    }
  }
}
</script>
