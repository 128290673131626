import axios from 'axios'

/* eslint-disable */
function fakeResult() {
  return new Promise((resolve) => setTimeout(() => {
    resolve({
      data: {
        elements: [
          {
            type: "way",
            id: 165235282,
            bounds: {
              minlat: 47.0611385,
              minlon: 15.3991834,
              maxlat: 47.0612388,
              maxlon: 15.3998556
            },
            geometry: [
              {
                lat: 47.0612312,
                lon: 15.3991834
              },
              {
                lat: 47.0612388,
                lon: 15.3998533
              },
              {
                lat: 47.0611461,
                lon: 15.3998556
              },
              {
                lat: 47.0611385,
                lon: 15.3991857
              },
              {
                lat: 47.0612312,
                lon: 15.3991834
              }
            ],
            tags: {
              "addr:street": "Tyroltgasse",
              "addr:housenumber": "22",
              building: "yes"
            }
          }
        ]
      }
    })
  }, 2000))
}
/* eslint-enable */

const url = 'https://z.overpass-api.de/api/interpreter'
export default {
  queryPoint(lon, lat) {
    const data = `[timeout:10][out:json];
    is_in(${lat}, ${lon})->.a;way["building"](pivot.a);
    out tags geom;`
    // const data = `[timeout:10][out:json];
    // node["building"](around:10.0 ,${lat}, ${lon});
    // way["building"](around:10.0, ${lat}, ${lon});
    // relation["building"](around:10.0, ${lat}, ${lon});
    // out tags geom;`
    // const data = `[timeout:20][out:json];relation["building"](around:7.0, ${lat}, ${lon});out tags geom;`

    return axios({
      url,
      method: 'post',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'text/plain',
      }
    })
  }
}
