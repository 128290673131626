<template>
  <div>
    <v-tabs
      v-model="tab"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <component v-bind:is="currentTab.component"/>
  </div>
</template>

<script>
import NodeMapPopupGeneral from './components/NodeMapPopupGeneral.vue'
import NodeMapPopupTemperature from './components/NodeMapPopupTemperature.vue'
import NodeMapPopupPressure from './components/NodeMapPopupPressure.vue'

export default {
  name: 'NodeMapPopup',
  components: {
    NodeMapPopupGeneral,
    NodeMapPopupTemperature,
    NodeMapPopupPressure
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    tabs () {
      return [
        {text: this.$t('general.general'), component: NodeMapPopupGeneral},
        {text: this.$t('panels.substation.tab_temperature'), component: NodeMapPopupTemperature},
        {text: this.$t('panels.substation.tab_pressure'), component: NodeMapPopupPressure}
      ]
    },
    currentTab () {
      return this.tabs[this.tab]
    },
    date () {
      return this.$store.getters['mapPopup/date']
    }
  },
  watch: {
    date () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('mapPopup/loadData')
    }
  }
}
</script>

