import Vuex from 'vuex';
import Vue from 'vue';

import router from '../router';

/** modules */
import account from './modules/account';
import edge from './modules/edge';
import node from './modules/node';
import plant from './modules/plant';
import storage from './modules/storage';
import substation from './modules/substation';
import settings from './modules/settings';
import map from './modules/map';
import mapPopup from './modules/mapPopup';
import dataPopup from './modules/dataPopup';
import simulations from './modules/simulations';
import energyPlanner from './modules/energyPlanner';
import findGeometriesPopup from './modules/findGeometriesPopup';
import analysis from './modules/analysis';

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  state: {
    intendedRoute: undefined,
    activePanel: false,
    activePanelRef: null,
    navDrawer: true
  },
  mutations: {
    setIntendedRoute (state, route) {
      state.intendedRoute = route
    },
    unsetIntendedRoute (state) {
      state.intendedRoute = undefined
    },
    setActivePanel (state, panel) {
      state.activePanel = panel
    },
    setActivePanelRef (state, activePanelRef) {
      state.activePanelRef = activePanelRef
    },
    setNavDrawer (state, navDrawer) {
      state.navDrawer = navDrawer
    }
  },
  actions: {
    reset ({ dispatch }) {
      dispatch('node/resetState')
      dispatch('edge/resetState')
      dispatch('plant/resetState')
      dispatch('substation/resetState')
      dispatch('storage/resetState')
      dispatch('map/resetState')
      dispatch('mapPopup/resetState')
      dispatch('settings/resetState')
      dispatch('dataPopup/resetState')
      dispatch('simulations/resetState')
      dispatch('energyPlanner/resetState')
      dispatch('findGeometriesPopup/resetState')
      dispatch('analysis/resetState')
    },
    setNavDrawer ({ commit }, value) {
      commit('setNavDrawer', value)
    },
    setActivePanel ({ commit }, panel) {
      commit('setActivePanel', panel)
    },
    setActivePanelRef ({ state, commit }, payload) {
      // only set reference if none has been set, or if called from active panel
      if (state.activePanel === false || state.activePanel === payload.name) {
        commit('setActivePanelRef', payload.panel)
      }
    },
    intendedRoute ({ commit }, route) {
      commit('setIntendedRoute', route)
    },
    resetIntendedRoute ({ commit }) {
      commit('unsetIntendedRoute')
    },
    redirectToIntendedRoute ({ getters, commit }) {
      const intendedRoute = getters.intendedRoute
      if (intendedRoute) {
        commit('unsetIntendedRoute')
        if (router.currentRoute.name !== intendedRoute.name) {
          router.push(intendedRoute)
        }
      } else {
        if (router.currentRoute.name !== 'home') {
          router.push({ name: 'home' })
        }
      }
    },
  },
  getters: {
    intendedRoute (state) {
      return state.intendedRoute
    },
    activePanel (state) {
      return state.activePanel
    },
    activePanelRef (state) {
      return state.activePanelRef
    },
    navDrawer: (state) => state.navDrawer
  },
  modules: {
    account,
    edge,
    node,
    plant,
    substation,
    storage,
    settings,
    map,
    mapPopup,
    dataPopup,
    simulations,
    energyPlanner,
    findGeometriesPopup,
    analysis
  },
})
