import Api from "./Api";

export default {
  index () {
    return Api().get('/cooling_houses')
  },
  store (payload = {}) {
    return Api().post('/cooling_houses', { ...payload.item })
  },
  update (payload = {}) {
    return Api().post('/cooling_houses/' + payload.item.id, { ...payload.item })
  },
  updateGeometry (payload = {}) {
    return Api().post(`/cooling_houses/${payload.id}/geometry`, { geometry: payload.geometry, osm_id: payload.featureId, area: payload.area })
  },
  delete (payload = {}) {
    return Api().delete('/cooling_houses/' + payload.item.id)
  },
  storeUnit (payload = {}) {
    return Api().post(`/cooling_houses/${payload.coolingHouseId}/units`, { ...payload.item })
  },
  updateUnit (payload = {}) {
    return Api().post(`/cooling_houses/${payload.coolingHouseId}/units/${payload.item.id}`, { ...payload.item })
  },
  deleteUnit (payload = {}) {
    return Api().delete(`/cooling_houses/${payload.coolingHouseId}/units/${payload.item.id}`)
  },
}
