import EnergyPlannerService from '@/services/EnergyPlannerService'
import GeocodeService from "@/services/GeocodeService"

import i18n from '@/lang/lang'
import building from './building'
import field from './field'
import coolingHouse from './coolingHouse'

// initial state
const getDefaultState = () => {
  return {
    buildings: [],
    buildingQueryItem: undefined,
    buildingQueryPoint: undefined,
    loading: false,
    firstLoad: false,
    panelErrors: false,
    buildingId: undefined,
    benefitsOpen: false,
    benefitsData: undefined,
    addressQuery: undefined,
    addressLocation: undefined
  }
}

const state = getDefaultState()

// getters
const getters = {
  firstLoad: state => state.firstLoad,
  loading: state => state.loading,
  panelErrors: state => state.panelErrors,
  benefitsOpen: state => state.benefitsOpen,
  benefitsData: state => state.benefitsData,
  hasBenefitsData: state => state.benefitsData !== undefined,
  benefitsDataCommunity: (state, getters) => {
    if (!getters.hasBenefitsData) return {}

    return state.benefitsData.community
  },
  benefitsDataTimeSteps: (state, getters) => {
    if (!getters.hasBenefitsData) return []

    return state.benefitsData.timeSteps
  },
  addressQuery: (state) => state.addressQuery,
  addressLocation: (state) => state.addressLocation
}

// actions
const actions = {
  resetFlags({ commit, dispatch }) {
    commit('panelErrors', false)
    commit('loading', false)
    commit('benefitsOpen', false)
    commit('benefitsData', undefined)
    commit('addressQuery', undefined)
    commit('addressLocation', undefined)

    dispatch('energyPlanner/building/resetFlags', null, { root: true })
  },
  resetState({ commit, dispatch }) {
    commit('resetState')
    dispatch('energyPlanner/building/resetState', null, { root: true })
  },
  panelErrors({ commit }, error) {
    commit('panelErrors', error)
  },
  loading({ commit }, loading) {
    commit('loading', loading)
  },
  async queryAddressLocation({ commit, state }, addressQuery) {
    if (state.addressQuery === addressQuery) {
      if (state.addressLocation) {
        commit('addressLocation', { ...state.addressLocation })
      }
      return
    }
    commit('loading', true)
    let multipleQueryResults = false
    try {
      const response = await GeocodeService.geocode(addressQuery)
      commit('addressQuery', addressQuery)
      commit('addressLocation', response.data.location)
      multipleQueryResults = (response.data.count ?? 0) > 1
    } catch (e) {
      console.log(e)
    }

    if (multipleQueryResults) {
      commit('panelErrors', { addressQuery: i18n.t('panels.energyPlanner.errors.multiple_query_results') })
    }

    commit('loading', false)
  },
  async computeBenefits({ commit }, payload) {
    commit('loading', true)
    try {
      const response = await EnergyPlannerService.benefits(payload)
      commit('benefitsData', response.data)
    } catch (e) {
      commit('benefitsData', undefined)
    }
    commit('loading', false)
  },
  leaveBenefits({ commit }) {
    commit('benefitsOpen', false)
    commit('panelErrors', false)
  },
  viewBenefits({ commit, dispatch }) {
    commit('benefitsData', undefined)
    commit('panelErrors', false)

    // start loading data
    dispatch('computeBenefits', {
      date: '2021-01-01'
    })

    commit('benefitsOpen', true)
  },
  async loadData({ commit, dispatch }) {
    commit('loading', true)
    await Promise.all([
      dispatch('energyPlanner/building/loadData', null, { root: true }),
      dispatch('energyPlanner/field/loadData', null, { root: true }),
      dispatch('energyPlanner/coolingHouse/loadData', null, { root: true }),
    ])
    commit('firstLoad', true)
    commit('loading', false)
  }
}

// mutations
const mutations = {
  firstLoad(state, firstLoad) {
    state.firstLoad = firstLoad
  },
  addressQuery(state, addressQuery) {
    state.addressQuery = addressQuery
  },
  addressLocation(state, addressLocation) {
    state.addressLocation = addressLocation
  },
  benefitsData(state, benefitsData) {
    state.benefitsData = benefitsData
  },
  benefitsOpen(state, benefitsOpen) {
    state.benefitsOpen = benefitsOpen
  },
  loading(state, loading) {
    state.loading = loading
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  panelErrors(state, panelErrors) {
    state.panelErrors = panelErrors
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    building,
    field,
    coolingHouse
  }
}
