export default {
  methods: {
    prepareNormalizedData(seriesData, attributeName, cutoff) {
      var result = [];
      let startindex = 0;
      let endIndex = seriesData.length;
      for (let i = 0; i < seriesData.length; i++) {
        const dTime = new Date(seriesData[i].timestamp);

        if (startindex <= 0 && dTime >= this.date) {
          startindex = i;
        }
        if (dTime > this.dateEnd) {
          endIndex = i;
          break;
        }
      }
      const stepLength = endIndex - startindex;
      let increment = 1;

      while (stepLength / increment > process.env.VUE_APP_CHART_DISPLAYED_TIMESTEPS) {
        increment++;
      }

      for (let idx = startindex; idx < endIndex; idx = idx + increment) {
        const item = seriesData[idx];
        const x = item.timestamp;

        let y = item[attributeName]
        if (cutoff !== undefined && y < cutoff) {
          y = cutoff
        }


        result.push({
          x,
          y
        });
      }
      return result;
    }
  }
}
