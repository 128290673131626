<template>
  <div>
    <portal to="energy-planner-fixed">
      <v-btn color="accent" @click="() => openEdit()">{{ $t('panels.energyPlanner.cooling_houses.new_unit') }}</v-btn>
      <v-btn class="ml-3" color="accent" @click="() => leaveUnits()" :title="$t('general.back')"><v-icon>mdi-keyboard-return</v-icon></v-btn>
    </portal>
    <v-card-text>
      <h5>{{ coolingHouse.name }}</h5>
      <v-data-table
        :items="units"
        :headers="headers"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="() => openEdit(item)" :title="$t('general.edit')" color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon @click="() => openDelete(item)" :title="$t('general.delete')" color="error"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <unit-edit-dialog v-if="editOpen" :item="editItem" @close="closeEdit" />
    <unit-delete-dialog v-if="deleteOpen" :item="deleteItem" @close="closeDelete" />
  </div>
</template>

<script>
  import UnitDeleteDialog from './UnitDeleteDialog.vue'
  import UnitEditDialog from './UnitEditDialog.vue'

  export default {
    name: 'Units',
    components: {
      UnitDeleteDialog,
      UnitEditDialog
    },
    data () {
      return {
        editItem: undefined,
        editOpen: false,
        deleteItem: undefined,
        deleteOpen: false
      }
    },
    computed: {
      panelErrors () {
        return this.$store.getters['energyPlanner/panelErrors']
      },
      loading () {
        return this.$store.getters['energyPlanner/loading']
      },
      coolingHouse () {
        return this.$store.getters['energyPlanner/coolingHouse/coolingHouse']
      },
      units () {
        return this.coolingHouse ? this.coolingHouse.units : []
      },
      headers () {
        return [
          {
            text: this.$t('general.name'),
            value: 'name'
          },
          {
            text: this.$t('general.actions'),
            value: 'actions',
            align: 'end',
            sortable: false,
            filterable: false
          }
        ]
      }
    },
    methods: {
      closeEdit () {
        this.editOpen = false
        this.editItem = undefined
      },
      openEdit (item) {
        this.editItem = item ? { ...item } : undefined
        this.editOpen = true
      },
      openDelete (item) {
        this.deleteItem = item
        this.deleteOpen = true
      },
      closeDelete () {
        this.deleteOpen = false
        this.deleteItem = undefined
      },
      leaveUnits() {
        this.$store.dispatch('energyPlanner/coolingHouse/leaveUnits')
      }
    }
  }
</script>
