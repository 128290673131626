<template>
  <v-dialog
    :value="open"
    max-width="600"
    @input="close"
  >
    <v-card>
      <v-card-title>
        {{ $t('panels.storage.edit_storage_header') }}
        <v-spacer></v-spacer>
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            :label="$t('general.name')"
            v-model="item.name"
            :rules="[rules.required, rules.maxLength(255)]"
            :error-messages="errorMessages('name')"
          />
          <v-text-field
            :label="$t('panels.storage.capacity')"
            v-model.number="item.capacity"
            :rules="[rules.validOptionalInteger]"
            :error-messages="errorMessages('capacity')"
          />
          <v-text-field
            :label="$t('panels.storage.max_temp')"
            v-model.number="item.max_temp"
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('max_temp')"
          />
          <v-text-field
            :label="$t('panels.storage.max_charge')"
            v-model.number="item.max_charge"
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('max_charge')"
          />
          <v-text-field
            :label="$t('panels.storage.max_discharge')"
            v-model.number="item.max_discharge"
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('max_discharge')"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="green" @click="save">{{ $t('general.save') }}</v-btn>
        <v-btn @click="close">{{ $t('general.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'

export default {
  name: "StorageEditDialog",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () {
        return {
          name: '',
          capacity: 0,
          max_temp: 0,
          max_charge: 0,
          max_discharge: 0
        }
      }
    }
  },
  data () {
    return {
      valid: true,
      loading: false,
      errors: false
    }
  },
  computed: {
    rules () {
      return RulesUtil
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async save () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.errors = await this.$store.dispatch('storage/saveItem', this.item)
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field]
      }

      return ''
    }
  },
  watch: {
    item () {
      this.errors = false
    }
  }
}
</script>
