<template>
  <div>
    <v-row>
      <template v-for="item in kpi">
        <v-col :key="item.key" cols="12" sm="6" md="6" lg="3">
          <v-card color="secondary">
            <v-card-title class="justify-center white--text">{{ kpiValue(item.key) }}{{ item.unit }}</v-card-title>
            <v-card-subtitle class="text-center white--text">{{ $t('panels.energyPlanner.benefits.kpi.' + item.key) }}</v-card-subtitle>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'BenefitsTotalKpiBig',
    computed: {
      community () {
        return this.$store.getters['energyPlanner/benefitsDataCommunity']
      }
    },
    data () {
      return {
        kpi: [
          { key: "self_cons_quota_perc", unit: '%' },
          { key: "self_usage_pv_perc", unit: '%' },
          { key: "cost_saving_y", unit: '€' },
          { key: "co2_saving_y", unit: ' Tonnen' }
        ]
      }
    },
    methods: {
      kpiValue (key) {
        const value = this.community.kpis[key]
        if (value === undefined) return '-'

        return value.toFixed(2)
      }
    }
  }
</script>
