import Api from './Api'

export default {
  index () {
    return Api().get('/plants')
  },
  store (item) {
    return Api().post('/plants', { ...item })
  },
  update (item) {
    return Api().put('/plants/' + item.id, { ...item })
  },
  updateGeometry (id, geometry) {
    return Api().post(`/plants/${id}/geometry`, { geometry: geometry })
  },
  updateNode (id, nodeId, supply) {
    return Api().post(`/plants/${id}/node`, { node_id: nodeId, supply: supply })
  },
  delete (item) {
    return Api().delete('/plants/' + item.id)
  },
  popupData (item, date, date_end) {
    return Api().get(`/plants/${item.id}/popup_data`, { params: { date, date_end }})
  }
}
