<template>
  <div>
    <h5 v-if="item.address">{{item.address}}</h5>
    <!-- <h6 v-if="item.area">
      {{$t('panels.substation.area')}} : {{ item.area }} m²
    </h6> -->
    <!-- <h6 v-if="item.area">
      {{$t('panels.substation.rented')}} : {{ item.rented }} %
    </h6> -->
    <v-tabs
      v-model="tab"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <component v-bind:is="currentTab.component" />
  </div>
</template>

<script>
import SubstationMapPopupGeneral from './components/SubstationMapPopupGeneral.vue'
import SubstationMapPopupPressure from './components/SubstationMapPopupPressure.vue'
import SubstationMapPopupTemperature from './components/SubstationMapPopupTemperature.vue'
import SubstationMapPopupEnergy from './components/SubstationMapPopupEnergy.vue'
import SubstationMapPopupMass from './components/SubstationMapPopupMass.vue'

export default {
  name: 'SubstationMapPopup',
  components: {
    SubstationMapPopupGeneral,
    SubstationMapPopupPressure,
    SubstationMapPopupTemperature,
    SubstationMapPopupEnergy,
    SubstationMapPopupMass
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    item () {
      return this.$store.getters['mapPopup/item']
    },
    tabs () {
      return [
        {text: this.$t('general.general'), component: SubstationMapPopupGeneral},
        {text: this.$t('panels.substation.tab_mass'), component: SubstationMapPopupMass},
        {text: this.$t('panels.substation.tab_pressure'), component: SubstationMapPopupPressure},
        {text: this.$t('panels.substation.tab_temperature'), component: SubstationMapPopupTemperature},
        {text: this.$t('panels.substation.tab_energy'), component: SubstationMapPopupEnergy}
      ]
    },
    currentTab () {
      return this.tabs[this.tab]
    },
    date () {
      return this.$store.getters['mapPopup/date']
    },
    date_end() {
      return this.$store.getters['mapPopup/date_end']
    }
  },
  watch: {
    date () {
      this.loadData()
    },
    date_end () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('mapPopup/loadData')
    }
  }
}
</script>
