<template>
  <v-app>
    <v-app-bar app hide-on-scroll>
      <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="navDrawer = !navDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ projectName }}
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-center mx-3" style="gap: 40px">
        <portal-target name="app-bar-actions"></portal-target>
      </div>
      <v-spacer />
      <language-select v-if="isLoggedIn" />
      <user-info-head v-if="isLoggedIn"></user-info-head>
      <v-btn :to="{ name: 'login' }" text v-if="!isLoggedIn && $router.currentRoute.name !== 'login'">{{
        $t("general.login") }}</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="navDrawer" v-if="isLoggedIn" app>
      <div style="display: flex; flex-direction: column; height: 100%">
        <router-link to="/">
          <v-img contain src="/images/logo_v2/brand_primary.svg" class="ma-9"
            alt="Arteria Technologies"></v-img>
        </router-link>
        <navigation-list></navigation-list>
      </div>
    </v-navigation-drawer>
    <v-main>
      <template v-if="!loading">
        <router-view />
      </template>
      <template v-else>
        <spinner></spinner>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import UserInfoHead from "./misc/UserInfoHead";
import NavigationList from "./misc/NavigationList";
import LanguageSelect from "./misc/LanguageSelect";
import Spinner from "@/components/Spinner";
import AccountService from "../services/AccountService";

export default {
  name: "App",
  components: {
    UserInfoHead,
    NavigationList,
    LanguageSelect,
    Spinner,
  },
  metaInfo() {
    const pageTitle = process.env.VUE_APP_PAGE_TITLE ?? "Arteria Platform";
    const titleTemplate = `${pageTitle}`;
    return {
      title: "",
      titleTemplate: titleTemplate,
    };
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    projectName() {
      return this.isLoggedIn
        ? this.$store.getters["account/config"].project_name
        : process.env.VUE_APP_PAGE_TITLE;
    },
    navDrawer: {
      get() {
        return this.$store.getters.navDrawer;
      },
      set(value) {
        this.$store.dispatch("setNavDrawer", value);
      },
    },
    isLoggedIn() {
      return this.$store.getters["account/isLoggedIn"];
    },
    userLocale() {
      return this.$store.getters["account/userLocale"];
    },
    userConfig() {
      return this.$store.getters["account/config"];
    },
    eccEnabled() {
      return this.userConfig.ecc_enabled ?? false;
    },
    heatingNetworkEnabled() {
      return this.userConfig.heating_network_enabled ?? false;
    },
  },
  watch: {
    userLocale() {
      this.updateLocale();
    },
    isLoggedIn(value) {
      if (value) {
        this.redirectToIntended();
      }
    },
  },
  async mounted() {
    this.loading = true;
    await AccountService.initCSRF();
    await Promise.all([
      this.$store.dispatch("account/refresh"),
      this.$store.dispatch("settings/index"),
    ]);
    this.loading = false;
  },
  methods: {
    redirectToIntended() {
      if (this.isLoggedIn) {
        this.$store.dispatch("redirectToIntendedRoute");
      }
    },
    updateLocale() {
      if (this.userLocale === undefined) return

      this.$root.$i18n.locale = this.userLocale;
      window.Apex.chart.defaultLocale = this.userLocale;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
