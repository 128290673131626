// initial state
import AccountService from '../../services/AccountService'
import SettingsService from '../../services/SettingsService'

const state = {
  user: {},
  config: {},
  isLoggedIn: false
}

// getters
const getters = {
  isLoggedIn (state) {
    return state.isLoggedIn
  },
  user (state) {
    return state.user
  },
  getUserName: (state) => state.user.name,
  userGroup: (state) => state.user.user_group,
  userSettings: (state) => state.user.settings || {},
  userLocale: (state, getters) => getters.userSettings.locale,
  config: (state) => state.config
}

// actions
const actions = {
  async login ({ dispatch }, logindata) {
    const response = await AccountService.login(logindata)
    await dispatch('checkResponse', { response })
  },
  async refresh ({ dispatch }) {
    try {
      const response = await AccountService.refresh()
      await dispatch('checkResponse', { response })
    } catch {
      // todo
    }
  },
  async logout ({ commit, dispatch }) {
    await AccountService.logout()
    commit('logout')
    dispatch('reset', null, { root: true })
  },
  async checkResponse ({ commit, dispatch }, payload) {
    const response = payload.response
    if (response && response.data) {
      await dispatch('me')
      commit('setIsLoggedIn', true)
    } else {
      commit('logout')
    }
  },
  async me ({ commit }) {
    const response = await AccountService.me()
    if (response.data) {
      commit('setUser', response.data.user)
      commit('setConfig', response.data.config)
      // console.log(response.data.user.settings.locale);
      commit('setUserLocale', response.data.user.settings.locale)
    }
  },
  async updateUserSettings({ commit }, settings) {
    let errors = false
    try {
      const response = await SettingsService.save(settings)
      commit('setUserSettings', response.data.settings)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async updateUserLocale({ commit }, locale) {
    try {
      await SettingsService.saveLocale(locale)
      // console.log(locale);
      commit('setUserLocale', locale)
    } catch (e) {
      console.error(e)
    }
  }
}

// mutations
const mutations = {
  setIsLoggedIn (state, newstate) {
    state.isLoggedIn = newstate
  },
  logout (state) {
    state.isLoggedIn = false
    state.user = {}
  },
  setUser (state, user) {
    state.user = user
  },
  setConfig (state, config) {
    state.config = config
  },
  setUserSettings (state, settings) {
    state.user = {
      ...state.user,
      settings: {
        ...settings
      }
    }
  },
  setUserLocale (state, locale) {
    // console.log('setUserLocale: ' + locale);
    state.user.settings = {
      ...state.user.settings,
      locale
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
