import { clickStates } from "@/util/structs"
import OverpassApiService from '@/services/OverpassApiService'
import CoolingHouseService from "@/services/CoolingHouseService"
import GeocodeService from "@/services/GeocodeService"

import i18n from '@/lang/lang'
import turfArea from '@turf/area'
import { roundWithPrecision } from "@/util/NumberUtil"

// initial state
const getDefaultState = () => {
  return {
    queryDone: false,
    coolingHouses: [],
    coolingHouseQueryItem: undefined,
    coolingHouseQueryPoint: undefined,
    coolingHouseId: undefined
  }
}

const state = getDefaultState()

// getters
const getters = {
  queryDone: state => state.queryDone,
  coolingHouses: state => state.coolingHouses,
  coolingHouseId: state => state.coolingHouseId,
  coolingHouse(state) {
    return state.coolingHouses.find(item => item.id === state.coolingHouseId)
  },
  coolingHouseQueryItem: state => state.coolingHouseQueryItem,
  coolingHouseQueryPoint: state => state.coolingHouseQueryPoint
}

// actions
const actions = {
  resetFlags({ commit }) {
    commit('queryDone', false)
    commit('coolingHouseQueryItem', undefined)
    commit('coolingHouseQueryPoint', undefined)
    commit('coolingHouseId', undefined)
  },
  leaveUnits({ commit }) {
    commit('coolingHouseId', undefined)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  viewUnits({ commit }, id) {
    commit('coolingHouseId', id)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  async loadData({ commit }) {
    try {
      const response = await CoolingHouseService.index()
      commit('coolingHouses', response.data.cooling_houses)
    } catch (e) {
      console.error(e)
    }
  },
  resetState({ commit }) {
    commit('resetState')
  },
  initQueryCoolingHouse({ commit, dispatch }, payload) {
    commit('queryDone', false)
    commit('coolingHouseQueryItem', undefined)
    commit('coolingHouseQueryPoint', undefined)
    commit('energyPlanner/panelErrors', false, { root:true })

    dispatch('map/setClickState', {
      clickState: clickStates.COOLING_HOUSE_QUERY,
      clickOptions: payload
    }, { root: true })
  },
  async coolingHouseQueryPoint({ commit, rootGetters }, payload) {
    commit('energyPlanner/loading', true, { root:true })
    commit('queryDone', false)
    commit('energyPlanner/panelErrors', false, { root:true })

    const { lon, lat } = payload
    commit('coolingHouseQueryPoint', { lon, lat })
    commit('coolingHouseQueryItem', undefined)

    let coolingHouseQueryItem
    let multipleQueryResults = false
    try {
      const response = await OverpassApiService.queryPoint(lon, lat)
      coolingHouseQueryItem = response.data.elements[0]
      multipleQueryResults = response.data.elements.length > 1

      const mapComponent = rootGetters['map/component']
      mapComponent.createOverpassQueryFeature(coolingHouseQueryItem, { lon, lat })
    } catch (e) {
      console.error("overpass query error", e)
      commit('energyPlanner/panelErrors', { coolingHouseQueryPoint: i18n.t('panels.energyPlanner.errors.overpass_query_failed') }, { root:true })
    }

    if (multipleQueryResults) {
      commit('energyPlanner/panelErrors', { coolingHouseQueryPoint: i18n.t('panels.energyPlanner.errors.multiple_query_results') }, { root:true })
    }

    commit('coolingHouseQueryItem', coolingHouseQueryItem)
    commit('queryDone', true)
    commit('energyPlanner/loading', false, { root:true })
  },
  async coolingHouseQueryAddress({ commit, rootGetters }, payload) {
    commit('energyPlanner/loading', true, { root:true })
    commit('queryDone', false)
    commit('energyPlanner/panelErrors', false, { root:true })
    commit('coolingHouseQueryPoint', undefined)
    commit('coolingHouseQueryItem', undefined)
    let coolingHouseQueryItem
    let queryError = false
    let queryOverpass = false
    let multipleQueryResults = false
    let location
    // first geocode address query
    try {
      const geocodeResponse = await GeocodeService.geocode(payload.address)
      location = geocodeResponse.data.location
      multipleQueryResults = (geocodeResponse.data.count ?? 0) > 1
      if (location.lon !== null && location.lat !== null) {
        // create a point feature in the map
        commit('coolingHouseQueryPoint', location)
        queryOverpass = true
      } else {
        queryError = true
      }
    } catch (e) {
      console.error(e)
      queryError = true
    }

    // perform overpass query with geocoded coordinates
    if (queryOverpass) {
      try {
        const response = await OverpassApiService.queryPoint(location.lon, location.lat)
        coolingHouseQueryItem = response.data.elements[0]
        if (multipleQueryResults === false) {
          multipleQueryResults = response.data.elements.length > 1
        }
      } catch (e) {
        console.error(e)
        queryError = true
      }
    }

    const mapComponent = rootGetters['map/component']
    mapComponent.createOverpassQueryFeature(coolingHouseQueryItem, location)

    if (queryError) {
      commit('energyPlanner/panelErrors', { coolingHouseQueryAddress: i18n.t('panels.energyPlanner.errors.address_not_found') }, { root:true })
    } else if (multipleQueryResults) {
      commit('energyPlanner/panelErrors', { coolingHouseQueryAddress: i18n.t('panels.energyPlanner.errors.multiple_query_results') }, { root:true })
    }
    commit('coolingHouseQueryItem', coolingHouseQueryItem)
    commit('queryDone', true)
    commit('energyPlanner/loading', false, { root:true })
  },
  cancelQueryPoint({ commit, dispatch }) {
    commit('coolingHouseQueryItem', undefined)
    commit('queryDone', false)
    commit('energyPlanner/loading', false, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    dispatch('map/setClickState', {
      clickState: clickStates.IDLE
    }, { root: true })
  },
  async saveQueryPoint({ state, dispatch }, payload) {
    if (state.coolingHouseQueryPoint === undefined) {
      console.error("no query point")
      return
    }

    const geometry = {
      type: "Point",
      coordinates: [state.coolingHouseQueryPoint.lon, state.coolingHouseQueryPoint.lat]
    }

    const errors = await dispatch('saveQueryGeometry', {
      id: payload.id,
      geometry
    })

    if (!errors) {
      dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      }, { root: true })
    }
  },
  async saveQueryItem({ rootGetters, dispatch }, payload) {
    const component = rootGetters['map/component']
    if (!component) {
      console.error("no map component")
      return
    }
    const geometry = component.getDrawingFeatureGeometry(payload.featureId)

    if (!geometry) {
      console.error("no geometry")
      return
    }

    const errors = await dispatch('saveQueryGeometry', {
      id: payload.id,
      featureId: payload.featureId,
      geometry
    })

    if (!errors) {
      dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      }, { root: true })
    }
  },
  async saveDrawnGeometry({ commit, dispatch }, payload) {
    commit('queryDone', true)
    return await dispatch('saveQueryGeometry', payload)
  },
  async saveQueryGeometry({ commit, rootGetters }, payload) {
    commit('energyPlanner/panelErrors', false, { root:true })
    commit('energyPlanner/loading', true, { root:true })
    let errors = false

    const geometry = payload.geometry

    try {
      const response = await CoolingHouseService.updateGeometry({
        id: payload.id,
        featureId: payload.featureId,
        geometry: JSON.stringify(geometry),
        area: (geometry.type === "Point") ? undefined : roundWithPrecision(turfArea(geometry), 2)
      })
      const coolingHouse = response.data.cooling_house
      commit('saveCoolingHouseGeometry', coolingHouse)
      // update coolingHouse feature
      const mapComponent = rootGetters['map/component']
      mapComponent.refreshCoolingHouseFeature(coolingHouse)
      commit('queryDone', false)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }
    commit('energyPlanner/panelErrors', errors, { root:true })
    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async saveCoolingHouse({ commit }, coolingHouse) {
    commit('energyPlanner/loading', true, { root:true })
    const action = coolingHouse.id !== undefined ? 'update' : 'store'

    let errors = false
    try {
      const response = await CoolingHouseService[action]({
        item: coolingHouse
      })
      commit('saveCoolingHouse', response.data.cooling_house)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteCoolingHouse({ commit }, coolingHouse) {
    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await CoolingHouseService.delete({
        item: coolingHouse
      })
      commit('deleteCoolingHouse', coolingHouse)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
  async saveUnit({ commit }, payload) {
    const unit = payload.unit
    const coolingHouseId = payload.coolingHouseId
    if (!coolingHouseId) return

    commit('energyPlanner/loading', true, { root:true })
    const action = unit.id !== undefined ? 'updateUnit' : 'storeUnit'
    let errors = false
    try {
      const response = await CoolingHouseService[action]({
        coolingHouseId,
        item: unit
      })
      commit('saveUnit', {
        coolingHouseId,
        unit: response.data.unit
      })
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteUnit({ commit }, payload) {
    const coolingHouseId = payload.coolingHouseId
    if (!coolingHouseId) return
    const unit = payload.unit

    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await CoolingHouseService.deleteUnit({
        coolingHouseId,
        item: unit
      })
      commit('deleteUnit', payload)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
}

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  queryDone(state, queryDone) {
    state.queryDone = queryDone
  },
  coolingHouseId(state, coolingHouseId) {
    state.coolingHouseId = coolingHouseId
  },
  coolingHouses(state, coolingHouses) {
    state.coolingHouses = coolingHouses
  },
  coolingHouseQueryItem(state, coolingHouseQueryItem) {
    state.coolingHouseQueryItem = coolingHouseQueryItem
  },
  coolingHouseQueryPoint(state, coolingHouseQueryPoint) {
    state.coolingHouseQueryPoint = coolingHouseQueryPoint
  },
  saveCoolingHouse(state, coolingHouse) {
    const coolingHouses = state.coolingHouses

    const index = coolingHouses.findIndex(t => t.id === coolingHouse.id)
    if (index === -1) {
      coolingHouses.push(coolingHouse)
    } else {
      coolingHouses[index] = coolingHouse
    }

    state.coolingHouses = [
      ...coolingHouses
    ]
  },
  saveUnit(state, payload) {
    const coolingHouseIndex = state.coolingHouses.findIndex(t => t.id === payload.coolingHouseId)
    if (coolingHouseIndex === -1) {
      return // coolingHouse not found - cant save unit
    }

    const coolingHouse = state.coolingHouses[coolingHouseIndex]

    const unit = payload.unit
    const units = coolingHouse.units ?? []
    const unitIndex = units.findIndex(t => t.id === unit.id)
    if (unitIndex === -1) {
      units.push(unit)
    } else {
      units[unitIndex] = unit
    }

    coolingHouse.units = [...units]
  },
  saveCoolingHouseGeometry(state, coolingHouse) {
    const coolingHouses = state.coolingHouses
    const index = coolingHouses.findIndex(t => t.id === coolingHouse.id)
    if (index !== -1) {
      coolingHouses[index] = {
        ...state.coolingHouses[index],
        area: coolingHouse.area,
        roofarea: coolingHouse.roofarea,
        geom: coolingHouse.geom
      }
      state.coolingHouses = [...coolingHouses]
    }
  },
  deleteCoolingHouse(state, coolingHouse) {
    const coolingHouses = state.coolingHouses

    state.coolingHouses = coolingHouses.filter(t => t.id !== coolingHouse.id)
  },
  deleteUnit(state, payload) {
    const coolingHouseIndex = state.coolingHouses.findIndex(t => t.id === payload.coolingHouseId)
    if (coolingHouseIndex === -1) return

    const coolingHouse = state.coolingHouses[coolingHouseIndex]
    coolingHouse.units = coolingHouse.units.filter(t => t.id !== payload.unit.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
