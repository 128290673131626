<template>
  <div>
    <portal to="energy-planner-fixed">
      <div class="mb-4">
        <v-btn color="accent" @click="() => openEdit()">
          {{ $t("panels.energyPlanner.cooling_houses.new_cooling_house") }}
        </v-btn>
      </div>
      <SearchAddress />
    </portal>
    <v-divider />
    <v-card-text>
      <v-data-table :items="coolingHouses" :headers="headers" single-expand>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div class="d-flex justify-space-between">
                <div>
                  {{ item.name }}
                  <v-btn icon @click="() => openEdit(item)" :title="$t('general.edit')"
                    color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
                </div>
                <div class="d-flex justify-end">
                  <v-btn v-if="item.geom" icon @click="() => fitCoolingHouse(item.id)"
                    :title="$t('panels.energyPlanner.find_in_map')" color="accent">
                    <v-icon>mdi-map-search</v-icon>
                  </v-btn>
                  <v-btn icon :outlined="
                    clickStateQueryCoolingHouse && clickOptions.id === item.id
                  " @click="() => queryPoint(item)" :title="$t('panels.energyPlanner.cooling_houses.query_point')" color="accent">
                    <v-icon>mdi-map-marker-plus</v-icon>
                  </v-btn>
                  <v-btn icon :outlined="
                    clickStateDrawCoolingHouse && clickOptions.id === item.id
                  " @click="() => drawCoolingHouse(item)" :title="$t('panels.energyPlanner.cooling_houses.draw_cooling_house')"
                    color="accent">
                    <v-icon>mdi-draw</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => viewUnits(item)" color="accent">
                    <v-icon>mdi-home-group</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => openDelete(item)" :title="$t('general.delete')"
                    color="error"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </td>
            <td class="text-right" :style="{ width: '1%' }">
              {{ coolingHouseUnitsCount(item) }}
            </td>
          </tr>
          <tr v-if="coolingHouseQueryActive(item)">
            <td colspan="2">
              <p>{{ $t("panels.energyPlanner.cooling_houses.query_cooling_house_hint") }}</p>
              <v-text-field full-width dense :label="$t('general.address')" append-outer-icon="mdi-magnify"
                @click:append-outer="queryAddress" :loading="loading" :disabled="loading" v-model="address"
                persistent-hint :hint="$t('panels.energyPlanner.query_address_hint')" :error-messages="
                  panelErrors && panelErrors.coolingHouseQueryAddress
                    ? panelErrors.coolingHouseQueryAddress
                    : ''
                " />
              <template v-if="queryDone">
                <div class="my-3">
                  <p v-if="hasCoolingHouseQueryItem">
                    {{ $t("panels.energyPlanner.query_items_found") }}<br />{{
                      queryItemName(coolingHouseQueryItem)
                    }}
                  </p>
                  <p v-else-if="coolingHouseQueryPoint">
                    {{ $t("panels.energyPlanner.save_query_point") }}
                  </p>
                </div>
                <div class="mb-3">
                  <v-btn v-if="hasCoolingHouseQueryItem || coolingHouseQueryPoint" small @click="() => saveQueryResult()"
                    :title="$t('general.save')" color="success" class="mr-1" :disabled="loading">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn @click="cancelQueryPoint" small color="error">{{
                    $t("general.cancel")
                  }}</v-btn>
                </div>
                <v-alert class="mb-3" v-if="panelErrors && panelErrors.geometry" type="error">
                  {{ panelErrors.geometry[0] }}
                </v-alert>
                <v-alert class="mb-3" v-if="panelErrors && panelErrors.coolingHouseQueryPoint" type="error">
                  {{ panelErrors.coolingHouseQueryPoint }}
                </v-alert>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <CoolingHouseEditDialog v-if="editOpen" :item="editItem" @close="closeEdit" />
    <CoolingHouseDeleteDialog v-if="deleteOpen" :item="deleteItem" @close="closeDelete" />
  </div>
</template>

<script>
import { clickStates } from '@/util/structs'
import CoolingHouseDeleteDialog from './CoolingHouseDeleteDialog.vue'
import CoolingHouseEditDialog from './CoolingHouseEditDialog.vue'
import SearchAddress from '../common/SearchAddress.vue'

export default {
  name: 'CoolingHouses',
  components: {
    CoolingHouseEditDialog,
    CoolingHouseDeleteDialog,
    SearchAddress
},
  data() {
    return {
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      address: ''
    }
  },
  computed: {
    clickStates() {
      return clickStates
    },
    clickState() {
      return this.$store.getters['map/clickState']
    },
    clickOptions() {
      return this.$store.getters['map/clickOptions']
    },
    clickStateQueryCoolingHouse() {
      return this.clickState === clickStates.COOLING_HOUSE_QUERY
    },
    clickStateDrawCoolingHouse() {
      return this.clickState === clickStates.COOLING_HOUSE_DRAW
    },
    coolingHouseQueryItem() {
      return this.$store.getters['energyPlanner/coolingHouse/coolingHouseQueryItem']
    },
    hasCoolingHouseQueryItem() {
      return this.coolingHouseQueryItem !== undefined
    },
    coolingHouseQueryPoint() {
      return this.$store.getters['energyPlanner/coolingHouse/coolingHouseQueryPoint']
    },
    coolingHouses() {
      return this.$store.getters['energyPlanner/coolingHouse/coolingHouses']
    },
    loading() {
      return this.$store.getters['energyPlanner/loading']
    },
    queryDone() {
      return this.$store.getters['energyPlanner/coolingHouse/queryDone']
    },
    panelErrors() {
      return this.$store.getters['energyPlanner/panelErrors']
    },
    mapComponent() {
      return this.$store.getters['map/component']
    },
    headers() {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('panels.energyPlanner.cooling_houses.units'),
          value: 'units.length',
          align: 'end'
        }
      ]
    }
  },
  watch: {
    coolingHouseQueryItem(value) {
      if (value !== undefined && this.overpassItemHasAddress(value)) {
        this.address = this.queryItemName(value)
      }
    },
    clickState(value) {
      if (value === clickStates.IDLE) {
        this.address = ''
      }
    },
  },
  methods: {
    overpassItemHasAddress(item) {
      return !(!item.tags['addr:street'] || !item.tags['addr:housenumber'])
    },
    coolingHouseUnitsCount(coolingHouse) {
      return coolingHouse.units.length ?? 0
    },
    refreshCoolingHouses() {
      this.$store.dispatch('energyPlanner/coolingHouse/loadData')
    },
    viewUnits(coolingHouse) {
      this.$store.dispatch('energyPlanner/coolingHouse/viewUnits', coolingHouse.id)
    },
    queryItemName(queryItem) {
      if (!this.overpassItemHasAddress(queryItem)) {
        return this.$t('panels.energyPlanner.no_address_data')
      }

      return `${queryItem.tags['addr:street']} ${queryItem.tags['addr:housenumber']}`
    },
    closeEdit() {
      this.editOpen = false
      this.editItem = undefined
    },
    openEdit(item) {
      this.editItem = item ? { ...item } : undefined
      this.editOpen = true
    },
    openDelete(item) {
      this.deleteItem = item
      this.deleteOpen = true
    },
    closeDelete() {
      this.deleteOpen = false
      this.deleteItem = undefined
    },
    resetClickState() {
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      })
    },
    coolingHouseQueryActive(item) {
      return this.clickStateQueryCoolingHouse && this.clickOptions.id === item.id
    },
    drawCoolingHouseActive(item) {
      return this.clickStateDrawCoolingHouse && this.clickOptions.id === item.id
    },
    queryPoint(item) {
      if (this.coolingHouseQueryActive(item)) {
        this.cancelQueryPoint()
        return
      }

      this.$store.dispatch('energyPlanner/coolingHouse/initQueryCoolingHouse', {
        id: item.id
      })
    },
    drawCoolingHouse(item) {
      if (this.drawCoolingHouseActive(item)) {
        this.resetClickState()
        return
      }

      if (this.coolingHouseQueryActive(item)) {
        this.cancelQueryPoint()
      }

      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.COOLING_HOUSE_DRAW,
        clickOptions: { id: item.id }
      })
    },
    saveQueryResult() {
      if (this.hasCoolingHouseQueryItem) {
        this.$store.dispatch('energyPlanner/coolingHouse/saveQueryItem', {
          id: this.clickOptions.id,
          featureId: this.coolingHouseQueryItem.id
        })
      } else if (this.coolingHouseQueryPoint) {
        this.$store.dispatch('energyPlanner/coolingHouse/saveQueryPoint', {
          id: this.clickOptions.id
        })
      }
    },
    fitCoolingHouse(id) {
      this.fitFeature(id, 'coolingHouses')
    },
    fitQueryItem(id) {
      this.fitFeature(id ?? 'QueryPoint', 'drawing')
    },
    fitFeature(id, layer) {
      if (!this.mapComponent) return

      this.mapComponent.locateFeature(layer, id)
    },
    cancelQueryPoint() {
      this.$store.dispatch('energyPlanner/coolingHouse/cancelQueryPoint')
    },
    queryAddress() {
      this.$store.dispatch('energyPlanner/coolingHouse/coolingHouseQueryAddress', { address: this.address })
    }
  }
}
</script>
