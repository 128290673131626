<template>
  <panel name="analysis" :initial-active="active">
    <v-progress-linear v-if="!initialized" indeterminate />
    <v-card-text v-else>
      <v-form @submit.prevent="loadData">
        <span class="text-caption">{{ $t('panels.analysis.date_time_range') }}</span>
        <div class="d-flex" style="gap: 8px">
          <DateTimePicker v-model="selectedStartDate" :min="minStartDate" :max="selectedEndDate" dense readonly />
          <DateTimePicker v-model="selectedEndDate" :min="selectedStartDate" :max="maxEndDate" dense />
        </div>
        <v-select v-model="visualizationMode" :items="visualizationModes"
          :label="$t('panels.analysis.visualization_mode.label')" :item-text="visualizationModeText"></v-select>
        <v-text-field v-model.number="visualizationThreshold" type="number"
          :label="$t('panels.analysis.visualization_threshold')" :suffix="visualizationModeUnit" />
        <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">{{
    $t('panels.analysis.load_data') }}</v-btn>
        <template v-if="hasData">
          <v-checkbox v-model="enabled" :label="$t('panels.analysis.enabled')" hide-details />
          <v-checkbox v-model="overwriteLabels" :label="$t('panels.analysis.overwrite_labels')" hide-details />
        </template>
        <ul class="pl-0 my-3" style="list-style: none;">
          <li v-for="(item, index) in legendItems" :key="index">
            <v-icon :color="item.color">mdi-square</v-icon>
            {{ item.text }}
          </li>
        </ul>
      </v-form>
    </v-card-text>
  </panel>
</template>

<script>
import { VISUALIZATION_MODE, visualizationModeUnit } from "@/store/modules/analysis";
import Panel from "../Panel.vue";
import RulesUtil from "@/util/RulesUtil";
import DateTimePicker from "@/components/DateTimePicker.vue";
import { colors } from "vuetify/lib";

export default {
  name: "AnalysisPanel",
  props: {
    active: Boolean,
  },
  components: {
    Panel,
    DateTimePicker
  },
  data() {
    return {
    };
  },
  computed: {
    maxEndDate() {
      return this.$store.getters["analysis/maxEndDate"]
    },
    minStartDate() {
      return this.$store.getters["analysis/minStartDate"]
    },
    selectedStartDate: {
      get() {
        return this.$store.getters["analysis/selectedStartDate"]
      },
      set(value) {
        this.$store.dispatch("analysis/selectedStartDate", value)
      }
    },
    selectedEndDate: {
      get() {
        return this.$store.getters["analysis/selectedEndDate"]
      },
      set(value) {
        this.$store.dispatch("analysis/selectedEndDate", value)
      }
    },
    legendItems() {
      return [
        {
          color: colors.red.base,
          text: this.$t('panels.analysis.legend.threshold_max')
        },
        // {
        //   color: colors.purple.base,
        //   text: this.$t('panels.analysis.legend.threshold_avg')
        // }
      ]
    },
    visualizationModes() {
      const modes = []

      for (const key in VISUALIZATION_MODE) {
        modes.push({
          value: VISUALIZATION_MODE[key],
          text: key
        })
      }
      return modes
    },
    rules() {
      return RulesUtil
    },
    visualizationMode: {
      get() {
        return this.$store.getters["analysis/visualizationMode"]
      },
      set(value) {
        this.$store.dispatch("analysis/visualizationMode", value)
      }
    },
    visualizationModeUnit() {
      return visualizationModeUnit(this.visualizationMode)
    },
    visualizationThreshold: {
      get() {
        return this.$store.getters["analysis/visualizationThreshold"]
      },
      set(value) {
        let parsedValue = Number.parseFloat(value)

        if (Number.isNaN(parsedValue) || parsedValue < 0) {
          parsedValue = 0
        }

        this.$store.dispatch("analysis/visualizationThreshold", parsedValue)
      }
    },
    thresholds() {
      return this.$store.getters["analysis/visualizationThresholds"]
    },
    mapComponent() {
      return this.$store.getters["map/component"];
    },
    loading() {
      return this.$store.getters["analysis/loading"]
    },
    initialized() {
      return this.$store.getters["analysis/initialized"]
    },
    hasData() {
      return this.$store.getters["analysis/hasData"]
    },
    enabled: {
      get() {
        return this.$store.getters["analysis/enabled"]
      },
      set(value) {
        this.$store.dispatch("analysis/enabled", value)
      }
    },
    overwriteLabels: {
      get() {
        return this.$store.getters["analysis/overwriteLabels"]
      },
      set(value) {
        this.$store.dispatch("analysis/overwriteLabels", value)
      }
    }
  },
  mounted() {
    this.$store.dispatch('analysis/initialize')
  },
  beforeDestroy() {
    this.$store.dispatch('analysis/enabled', false)
  },
  methods: {
    loadData() {
      this.$store.dispatch('analysis/loadData')
    },
    visualizationModeText(item) {
      const text = this.$t(`panels.analysis.visualization_mode.${item.text}`)
      const unit = visualizationModeUnit(item.value)
      return text + (unit ? ` [${unit}]` : '')
    }
  },
};
</script>
