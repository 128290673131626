<template>
  <panel name="substation" :initial-active="active">
    <template v-slot:fixed-content>
      <v-card-text class="py-0">
        <v-btn color="primary" @click="newSubstation">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </template>
    <v-card-text>
      <div class="pb-10" style="background-color: #FAFAFA;">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
        />
        <v-checkbox
          v-model="filterMissingNodes"
          :label="$t('panels.substation.filter_missing_nodes')"
          dense
          hide-details
        />
      </div>
      <v-divider></v-divider>
      <v-data-table
        :items="filteredItems"
        :headers="headers"
        single-expand
        show-expand
        :expanded.sync="expanded"
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span :style="itemNameStyle(item)">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :disabled="!item.geom"
            icon
            @click="() => locateSubstation(item)"
            :title="$t('general.locate_on_map')"
            color="primary"
            ><v-icon>mdi-map-marker-circle</v-icon></v-btn
          >
          <v-btn
            icon
            @click="() => openMapPopup(item)"
            :title="$t('general.show_data')"
            color="primary"
            ><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-btn
          >
          <v-btn
            icon
            @click="() => editSubstation(item)"
            :title="$t('general.edit')"
            color="green"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            icon
            @click="() => deleteSubstation(item)"
            :title="$t('general.delete')"
            color="error"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length + 1">
            <div class="text-center ma-1">
              <v-btn style="width: 40%"
                :outlined="
                  clickState === clickStates.SUBSTATION_QUERY_GEOM &&
                  clickOptions.id === item.id
                "
                class="mr-1"
                @click="() => queryGeom(item)"
                ><v-icon>mdi-home-search-outline </v-icon
                >{{ $t("panels.substation.btn_query_geom") }}</v-btn
              >
              <v-btn style="width: 40%"
                :outlined="
                  clickState === clickStates.SUBSTATION_PLACE_GEOM &&
                  clickOptions.id === item.id
                "

                @click="() => placeGeom(item)"
                ><v-icon>mdi-draw</v-icon
                >{{ $t("panels.substation.btn_place_geom") }}</v-btn
              >
            </div>
            <div class="text-center ma-1">
              <v-btn style="width: 40%"
                :outlined="
                  clickState === clickStates.SUBSTATION_CHOOSE_SUPPLY &&
                  clickOptions.id === item.id
                "
                class="mr-1"
                @click="() => chooseSupplyNode(item)"
                color="secondary"
                ><v-icon>mdi-map-marker-up</v-icon
                >{{ $t("panels.substation.btn_place_supply") }}</v-btn
              >
              <v-btn style="width: 40%"
                :outlined="
                  clickState === clickStates.SUBSTATION_CHOOSE_RETURN &&
                  clickOptions.id === item.id
                "
                @click="() => chooseReturnNode(item)"
                color="primary"
                ><v-icon>mdi-map-marker-down</v-icon
                >{{ $t("panels.substation.btn_choose_return") }}</v-btn
              >
            </div>
            <v-alert
              color="error"
              v-if="panelErrors && panelErrors.geometry"
              type="error"
              class="mt-3"
            >
              {{ panelErrors.geometry[0] }}
            </v-alert>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <substation-edit-dialog
      v-if="editOpen"
      :item="editItem"
      :open="editOpen"
      @close="closeEdit"
    />
    <substation-delete-dialog
      v-if="deleteOpen"
      :item="deleteItem"
      :open="deleteOpen"
      @close="closeDelete"
    />
  </panel>
</template>

<script>
import Panel from "../Panel.vue";
import SubstationEditDialog from "./SubstationEditDialog.vue";
import SubstationDeleteDialog from "./SubstationDeleteDialog.vue";
import { clickStates, featureTypes } from "../../../util/structs";
import { colors } from "vuetify/lib";

const highlighColor =
  process.env.VUE_APP_SUBSTATION_HIGHLIGHT_COLOR ?? colors.red.accent4;

export default {
  name: "SubstationPanel",
  props: {
    active: Boolean,
  },
  components: {
    Panel,
    SubstationEditDialog,
    SubstationDeleteDialog,
  },
  data() {
    return {
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      expanded: [],
      search: "",
      filterMissingNodes: false,
    };
  },
  computed: {
    clickStates() {
      return clickStates;
    },
    panelErrors() {
      return this.$store.getters["substation/panelErrors"];
    },
    items() {
      return this.$store.getters["substation/items"];
    },
    filteredItems() {
      let items = this.items;

      if (this.filterMissingNodes) {
        items = items.filter(
          (item) => item.return_node_id === null || item.supply_node_id === null
        );
      }
      return items;
    },
    mapComponent() {
      return this.$store.getters["map/component"];
    },
    clickState() {
      return this.$store.getters["map/clickState"];
    },
    clickOptions() {
      return this.$store.getters["map/clickOptions"];
    },
    headers() {
      return [
        {
          text: this.$t("general.name"),
          value: "name",
        },
        {
          text: this.$t("panels.plant.fid"),
          value: "fid",
        },
        {
          text: this.$t("panels.substation.power"),
          value: "power",
        },
        {
          text: this.$t("general.actions"),
          value: "actions",
          align: "end",
          filterable: false,
          sortable: false,
        },
      ];
    },
  },
  watch: {
    expanded(newValue, oldValue) {
      if (newValue.length === 0 || newValue[0] !== oldValue[0]) {
        this.resetClickState();
      }
    },
  },
  methods: {
    itemNameStyle(item) {
      let style = {};
      if (item.return_node_id === null || item.supply_node_id === null) {
        style.color = highlighColor;
      }
      return style;
    },
    openMapPopup(item) {
      this.$store.dispatch("mapPopup/open", {
        item,
        type: featureTypes.SUBSTATION,
      });
    },
    closeEdit() {
      this.editOpen = false;
      this.editItem = undefined;
    },
    newSubstation() {
      this.openEdit();
    },
    editSubstation(item) {
      this.openEdit(item);
    },
    openEdit(item) {
      this.editItem = item ? { ...item } : undefined;
      this.editOpen = true;
    },
    deleteSubstation(item) {
      this.deleteItem = item;
      this.deleteOpen = true;
    },
    closeDelete() {
      this.deleteOpen = false;
      this.deleteItem = undefined;
    },
    locateSubstation(item) {
      this.mapComponent.locateSubstation(item.id);
    },
    placeGeom(item) {
      if (this.clickState === clickStates.SUBSTATION_PLACE_GEOM) {
        this.resetClickState();
        return;
      }
      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.SUBSTATION_PLACE_GEOM,
        clickOptions: {
          id: item.id,
        },
      });
    },
    queryGeom(item) {
      if (this.clickState === clickStates.SUBSTATION_QUERY_GEOM) {
        this.resetClickState();
        return;
      }
      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.SUBSTATION_QUERY_GEOM,
        clickOptions: {
          id: item.id,
        },
      });
    },
    chooseReturnNode(item) {
      if (this.clickState === clickStates.SUBSTATION_CHOOSE_RETURN) {
        this.resetClickState();
        return;
      }

      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.SUBSTATION_CHOOSE_RETURN,
        clickOptions: {
          id: item.id,
          oldId: item.return_node_id,
          supply: false,
        },
      });
    },
    chooseSupplyNode(item) {
      if (this.clickState === clickStates.SUBSTATION_CHOOSE_SUPPLY) {
        this.resetClickState();
        return;
      }
      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.SUBSTATION_CHOOSE_SUPPLY,
        clickOptions: {
          id: item.id,
          oldId: item.supply_node_id,
          supply: true,
        },
      });
    },
    resetClickState() {
      this.$store.dispatch("substation/resetPanelError");
      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.IDLE,
      });
    },
  },
};
</script>
