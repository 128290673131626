<template>
  <div>
    <v-dialog persistent ref="dialog" v-model="modal" width="unset">
      <template v-slot:activator="{ on }">
        <div class="horizontal">
          <v-text-field
            :clearable="clearable"
            :label="labelFrom"
            :readonly="readonly"
            :value="formattedDateStart"
            :disabled="disabled"
            @change="handleTextInputDateStart"
            @click:clear="clear"
            @click:append-outer="on.click"
            :rules="[validateTextInput].concat(rules)"
            :error-messages="errormessages"
          ></v-text-field>
          <v-text-field
            :clearable="clearable"
            :label="labelTo"
            :readonly="readonly"
            :value="formattedDateEnd"
            :disabled="disabled"
            append-outer-icon="mdi-calendar"
            @change="handleTextInputDateEnd"
            @click:clear="clear"
            @click:append-outer="on.click"
            :rules="[validateTextInput].concat(rules)"
            :error-messages="errormessages"
          ></v-text-field>
        </div>
      </template>
      <v-card class="date-time-picker-card">
        <v-card-text>
          <div style="display: flex; padding: 5px">
            <v-date-picker
              style="padding: 5px"
              :max="maxDate"
              v-model="dateModifier"
              locale="de"
              first-day-of-week="1"
              show-adjacent-months
            ></v-date-picker>
            <v-date-picker
              style="padding: 5px"
              :max="maxDate"
              :min="minEndDate"
              v-model="date_endModifier"
              locale="de"
              first-day-of-week="1"
              show-adjacent-months
            ></v-date-picker>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Abbrechen</v-btn>
          <v-btn @click="save" :disabled="!valid">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mb-5">
      <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header ripple>{{this.$t("general.filter")}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex justify-space-around my-2">
            <v-btn small color="primary" outlined @click="setToday()">{{this.$t("general.today")}}</v-btn>
            <v-btn small color="primary" outlined @click="setThisWeek()">{{this.$t("general.thisweek")}}</v-btn>
          </div>
          <div class="d-flex justify-space-around my-2">
            <v-btn small color="primary" outlined @click="setChartDates('2024-01-01', '2024-01-31')">{{this.$t("general.january")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-02-01', '2024-02-28')">{{this.$t("general.february")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-03-01', '2024-03-31')">{{this.$t("general.march")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-04-01', '2024-04-30')">{{this.$t("general.april")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-05-01', '2024-05-31')">{{this.$t("general.may")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-06-01', '2024-06-30')">{{this.$t("general.june")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-07-01', '2024-07-31')">{{this.$t("general.july")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-08-01', '2024-08-31')">{{this.$t("general.august")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-09-01', '2024-09-30')">{{this.$t("general.september")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-10-01', '2024-10-31')">{{this.$t("general.october")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-11-01', '2024-11-30')">{{this.$t("general.november")}}</v-btn>
            <v-btn small color="primary" outlined @click="setChartDates('2024-12-01', '2024-12-31')">{{this.$t("general.december")}}</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: String,
    label: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    max: String,
    errormessages: {
      type: Array,
      default: function () {
        return [];
      },
    },
    rules: {
      type: Array,
      default: function () {
        return [];
      },
    },
    date: {},
    date_end: {},
  },
  data() {
    return {
      modal: false,
      localDate: this.date,
      localDate_end: this.date_end,
      endDateSelected: false,
    };
  },
  computed: {
    labelFrom() {
      return this.$t("general.from");
    },
    labelTo() {
      return this.$t("general.to");
    },
    valid() {
      return this.date !== undefined;
    },
    maxDate() {
      let maxDT = this.$moment(this.max);
      if (this.max == undefined) {
        maxDT.add(1, "year");
      }
      return maxDT.isValid() ? maxDT.format("YYYY") : undefined;
    },
    minEndDate() {
      // // let Date = this.$moment(this.date);
      // console.log(thisweek);
      // console.log(this.checkDate);
      // return this.checkDate > this.localDate
      //   ? this.localDate_end
      //   : this.$moment(this.localDate).add(1, "day").format("YYYY-MM-DD");
      return this.$moment(this.localDate).add(1, "day").format("YYYY-MM-DD");
    },
    dateModifier: {
      get: function () {
        return this.localDate;
      },
      set: function (newValue) {
        if (
          !this.endDateSelected ||
          this.$moment(newValue).isAfter(this.localDate_end)
        ) {
          let inaweek = this.$moment(newValue)
            .add(1, "week")
            .format("YYYY-MM-DD");
          // console.log(inaweek)
          this.localDate_end = inaweek;
        }
        this.localDate = newValue;
      },
    },
    date_endModifier: {
      get: function () {
        return this.localDate_end;
      },
      set: function (newValue) {
        this.endDateSelected = true;
        // console.log(newValue);
        this.localDate_end = newValue;
      },
    },
    formattedDateStart() {
      return this.date ? this.$moment(this.date).format("L") : "";
    },
    formattedDateEnd() {
      return this.date_end ? this.$moment(this.date_end).format("L") : "";
    },
  },
  methods: {
    validateTextInput(value) {
      if (this.max !== undefined) {
        if (this.$moment(this.max).isBefore(value)) {
          return `Ungültiges Datum. Datum muss vor ${this.max} liegen.`;
        }
      }
      return true;
    },
    handleTextInputDateStart(value) {
      const dt = this.$moment(value, 'DD.MM.YYYY');
      if (dt.isValid()) {
        this.localDate = dt.format("YYYY-MM-DD");
        // this.$emit('input', dt.format('YYYY-MM-DD'))
        this.$emit("update:date", `${this.localDate}`);
      }
    },
    handleTextInputDateEnd(value) {
      const dt = this.$moment(value, 'DD.MM.YYYY');
      if (dt.isValid()) {
        this.localDate_end = dt.format("YYYY-MM-DD");
        // this.$emit('input', dt.format('YYYY-MM-DD'))
        this.$emit("update:date_end", `${this.localDate_end}`);
      }
    },
    reset() {
      // console.log("reset");
      this.endDateSelected = false;
      // if (this.date) {
      //   const dt = this.$moment(this.date);
      //   this.date = dt.format("YYYY-MM-DD");
      // } else {
      //   this.date = undefined;
      // }
      // if (this.date_end) {
      //   const dt = this.$moment(this.date_end);
      //   this.date_end = dt.format("YYYY-MM-DD");
      // } else if (this.date) {
      //   const dt = this.$moment(this.date);
      //   this.date_end = dt.add(1, "day").format("YYYY-MM-DD");
      // } else {
      //   this.date_end = undefined;
      // }
    },
    cancel() {
      this.modal = false;
    },
    clear() {
      // console.log("clear");
      this.$emit("input", undefined);
    },
    save() {
      if (this.valid) {
        // console.log(this.localDate);
        // console.log(this.localDate_end);
        this.$emit("update:date", `${this.localDate}`);
        this.$emit("update:date_end", `${this.localDate_end}`);
      }

      this.modal = false;
    },
    setThisWeek(){
      this.localDate = this.$moment().day(0).format("YYYY-MM-DD");
      this.localDate_end = this.$moment().day(7).format("YYYY-MM-DD");
      this.save();
    },
    setToday(){
      this.localDate = this.$moment().startOf('day').format("YYYY-MM-DD");
      this.localDate_end = this.$moment().endOf('day').format("YYYY-MM-DD");
      this.save();
    },
    setChartDates(start, end) {
      if(start && end) {
        const s = this.$moment(start);
        const e = this.$moment(end);
        this.localDate = s.format("YYYY-MM-DD");
        this.localDate_end = e.format("YYYY-MM-DD");
      }
      this.save();
    },
  },
  // watch: {
  //   modal(open) {
  //     if (open) {
  //       this.reset();
  //     }
  //   },
  // },
};
</script>
