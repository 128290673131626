<template>
  <div>
    <portal to="app-bar-actions">
      <v-btn v-if="buildings.length || fields.length || coolingHouses.lenth" color="accent" @click="() => computeBenefits()">
        {{ $t("panels.energyPlanner.view_compute_benefits") }}
      </v-btn>
    </portal>
    <v-card-text v-if="hasInvalidItems">
      <template v-if="invalidBuildings.length">
        <v-alert type="error" color="error">
          {{ $t("panels.energyPlanner.buildings.invalid_buildings") }}
          <ul>
            <li v-for="building in invalidBuildings" :key="building.id">
              {{ building.name }}
            </li>
          </ul>
        </v-alert>
      </template>
      <template v-if="invalidFields.length">
        <v-alert type="error" color="error">
          {{ $t("panels.energyPlanner.fields.invalid_fields") }}
          <ul>
            <li v-for="field in invalidFields" :key="field.id">
              {{ field.name }}
            </li>
          </ul>
        </v-alert>
      </template>
      <template v-if="invalidCoolingHouses.length">
        <v-alert type="error" color="error">
          {{ $t("panels.energyPlanner.cooling_houses.invalid_cooling_houses") }}
          <ul>
            <li v-for="coolingHouse in invalidCoolingHouses" :key="coolingHouse.id">
              {{ coolingHouse.name }}
            </li>
          </ul>
        </v-alert>
      </template>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'ComputeBenefitsButtonWrapper',
  data() {
    return {
      invalidBuildings: [],
      invalidFields: [],
      invalidCoolingHouses: []
    }
  },
  computed: {
    canCompute() {
      return this.buildings.length // Todo: fields and coolingHouses?
    },
    buildings() {
      return this.$store.getters['energyPlanner/building/buildings']
    },
    fields() {
      return this.$store.getters['energyPlanner/field/fields']
    },
    coolingHouses() {
      return this.$store.getters['energyPlanner/coolingHouse/coolingHouses']
    },
    hasInvalidItems() {
      return this.invalidBuildings.length || this.invalidFields.length || this.invalidCoolingHouses.length
    }
  },
  methods: {
    computeBenefits() {
      // verify all buildings, fields, and cooling houses
      this.verifyBuildingUnitsExists()
      this.verifyFieldCropsExists()
      this.verifyCoolingHouseUnitsExists()


      if (this.invalidBuildings.length === 0 && this.invalidFields.length === 0 && this.invalidCoolingHouses.length === 0) {
        this.$store.dispatch('energyPlanner/viewBenefits')
      }
    },
    verifyBuildingUnitsExists() {
      const invalidBuildings = []
      for (const building of this.buildings) {
        const units = building.units ?? []
        if (units.length === 0) {
          invalidBuildings.push(building)
        }
      }

      this.invalidBuildings = invalidBuildings
    },
    verifyCoolingHouseUnitsExists() {
      const invalidCoolingHouses = []
      for (const coolingHouse of this.coolingHouses) {
        const units = coolingHouse.units ?? []
        if (units.length === 0) {
          invalidCoolingHouses.push(coolingHouse)
        }
      }

      this.invalidCoolingHouses = invalidCoolingHouses
    },
    verifyFieldCropsExists() {
      const invalidFields = []
      for (const field of this.fields) {
        const crops = field.crops ?? []
        if (crops.length === 0) {
          invalidFields.push(field)
        }
      }

      this.invalidFields = invalidFields
    },
  }
}
</script>
