<template>
  <v-dialog :value="true" max-width="800" @input="close">
    <v-card>
      <v-card-title>
        {{ $t('panels.energyPlanner.fields.edit_crop_header') }}
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="errors && errors.general" type="error" color="error">
          {{ errors.general }}
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-select :label="$t('panels.energyPlanner.attributes.field.crop.type') + '*'" v-model="item.croptype"
            :items="cropTypes" :rules="[rules.required]" :error-messages="errorMessages('croptype')" />
          <div class="mt-8"><v-slider dense :label="$t('panels.energyPlanner.attributes.field.crop.percentage') + '*'"
              v-model="item.croppercentage" suffix="%" min="0" :max="maxPercentage" thumb-label="always"
              :rules="[rules.required, rules.validFloat]" :error-messages="errorMessages('croppercentage')" />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="accent" @click="save">{{ $t('general.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'
import { EnergyCropType } from '@/util/structs'
import { get } from 'lodash'

export default {
  name: "CropEditDialog",
  components: {
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      errors: false
    }
  },
  computed: {
    rules() {
      return RulesUtil
    },
    fieldId() {
      return this.$store.getters['energyPlanner/field/fieldId']
    },
    field() {
      return this.$store.getters['energyPlanner/field/field']
    },
    crops() {
      return this.field ? this.field.crops : []
    },
    maxPercentage() {
      let cropPercentageSum = 100
      for (const crop of this.crops) {
        if (crop.id === this.item.id) continue

        cropPercentageSum -= crop.croppercentage
      }

      return Math.max(cropPercentageSum, 0)
    },
    cropTypes() {
      const types = []
      for (const key in EnergyCropType) {
        types.push({ value: EnergyCropType[key], text: this.$t('panels.energyPlanner.crop_type.' + key) })
      }

      return types
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true

      this.errors = await this.$store.dispatch('energyPlanner/field/saveCrop', {
        fieldId: this.fieldId,
        crop: { // temp: fix values for certain fields
          ...this.item
        }
      })
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages(field) {
      if (this.errors) {
        return get(this.errors, field)
      }

      return ''
    }
  },
  watch: {
    item() {
      this.errors = false
    }
  }
}
</script>
