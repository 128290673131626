<template>
  <div>
    <Units v-if="buildingId"></Units>
    <Buildings v-else ref="buildings"></Buildings>
  </div>
</template>

<script>
import Units from './units/Units.vue';
import Buildings from './Buildings.vue';

export default {
  name: 'BuildingsWrapper',
  components: {
    Buildings,
    Units
  },
  data () {
    return {
    }
  },
  computed: {
    buildingId() {
      return this.$store.getters['energyPlanner/building/buildingId']
    },
  },
  mounted() {
    this.$store.dispatch('energyPlanner/building/resetFlags')
  },
  beforeDestroy() {
    this.$store.dispatch('energyPlanner/building/resetFlags')
  },
  methods: {
    onMapEnergyPlannerItemClick (building) {
      this.$store.dispatch('energyPlanner/building/leaveUnits')
      this.$nextTick(() => {
        this.$refs.buildings.openEdit(building)
      })
    }
  }
}
</script>
