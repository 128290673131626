// initial state
const getDefaultState = () => {
    return {
        loading: false,
        open: false
    }
}

const state = getDefaultState()

// getters
const getters = {
    loading: state => state.loading,
    open: state => state.open
}

const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    open({ commit }, payload) {
        commit('open', payload);
    },
    close({ commit }) {
        commit('resetState');
    },
    async loadGeometries({ commit }) {
        try {
            commit('loading', true);
        } catch (e) {
            console.error(e);
            commit('data', {});
        } finally {
            commit('loading', false);
        }
    }
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    loading(state, loading) {
        state.loading = loading;
    },
    open(state) {
        state.open = true;
        
    },
    networkData(state, data) {
        state.networkData = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}