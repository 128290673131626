<template>
  <div>
    <div :class="mapContainerClasses">
      <map-component></map-component>
    </div>
    <open-simulation-dialog-button />
    <div class="map-panels">
      <template v-for="panel in panels">
        <component :key="panel.name" v-if="previousPanel === panel.name || activePanel === panel.name" :is="panel.component" :active="panel.name === activePanel" />
      </template>
    </div>
  </div>
</template>

<script>
import MapComponent from './MapComponent.vue'
import EdgePanel from './edge/EdgePanel.vue'
import NodePanel from './node/NodePanel.vue'
import PlantPanel from './plant/PlantPanel.vue'
import SubstationPanel from './substation/SubstationPanel.vue'
import StoragePanel from './storage/StoragePanel.vue'
import SettingsPanel from './settings/SettingsPanel.vue'
import EnergyPlannerPanel from './energy_planner/EnergyPlannerPanel.vue'
import OpenSimulationDialogButton from '../misc/OpenSimulationDialogButton.vue'
import AnalysisPanel from './analysis/AnalysisPanel.vue'

export default {
  components: {
    MapComponent,
    EdgePanel,
    NodePanel,
    PlantPanel,
    SubstationPanel,
    StoragePanel,
    SettingsPanel,
    EnergyPlannerPanel,
    AnalysisPanel,
    OpenSimulationDialogButton
},
  name: 'MapContainer',
  data () {
    return {
      previousPanel: false
    }
  },
  computed: {
    mapContainerClasses () {
      return [
        'map-container',
        { 'panel-open': this.activePanel !== false },
        { 'panel-plant': this.activePanel === 'plant' },
        { 'panel-substation': this.activePanel === 'substation' },
        { 'panel-storage': this.activePanel === 'storage' },
        { 'panel-settings': this.activePanel === 'settings' },
        { 'panel-energyPlanner': this.activePanel === 'energyPlanner'},
        { 'panel-analysis': this.activePanel === 'analysis'}
      ]
    },
    activePanel () {
      return this.$store.getters.activePanel
    },
    panels () {
      return [
        { name: 'edge', component: EdgePanel },
        { name: 'node', component: NodePanel },
        { name: 'plant', component: PlantPanel },
        { name: 'substation', component: SubstationPanel },
        { name: 'storage', component: StoragePanel },
        { name: 'settings', component: SettingsPanel },
        { name: 'energyPlanner', component: EnergyPlannerPanel },
        { name: 'analysis', component: AnalysisPanel }
      ]
    }
  },
  watch: {
    activePanel (newPanel, oldPanel) {
      this.previousPanel = oldPanel
      window.setTimeout(() => {
        this.previousPanel = false
      }, 500)
    }
  }
}
</script>
