<template>
  <div class="py-10" style="height: 654px;">
    <general-info-table :item="item" />
  </div>
</template>
<script>

import GeneralInfoTable from "../../../../components/GeneralInfoTable.vue";
export default {
  name: "EdgeMapPopupGeneral",
  components: { GeneralInfoTable },
  data() {
    return {};
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
  },
};
</script>
