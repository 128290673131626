import Api from './Api'

export default {
  index () {
    return Api().get('settings')
  },
  save (data) {
    return Api().post('settings', {...data})
  },
  saveLocale (locale) {
    return Api().post('settings/locale', { locale })
  }
}
