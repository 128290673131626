<template>
  <div>
    <map-container />
  </div>
</template>

<script>
  import MapContainer from './map/MapContainer.vue'
  export default {
    name: 'Home',
    components: {
      MapContainer
    },
    data () {
      return {

      }
    }
  }
</script>
