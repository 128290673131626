<template>
  <v-dialog :value="open" max-width="600" @input="close">
    <v-card>
      <v-card-title>
        {{ $t("panels.plant.edit_plant_header") }}
        <v-spacer></v-spacer>
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field :label="$t('general.name') + (editMode ? '*' : '')" v-model="item.name" :rules="nameRules"
            :error-messages="errorMessages('name')" :hint="$t('panels.plant.name_hint')" persistent-hint />
          <v-text-field :label="$t('panels.plant.fid')" v-model.number="item.fid" />
          <v-text-field :label="$t('general.temperature')" v-model.number="item.temperature"
            :rules="[rules.validOptionalInteger]" placeholder="100" persistent-placeholder suffix="°C"
            :error-messages="errorMessages('temperature')" />
          <v-text-field :label="$t('general.pressure')" v-model.number="item.pressure"
            :rules="[rules.validOptionalFloat]" placeholder="8" persistent-placeholder suffix="bar"
            :error-messages="errorMessages('pressure')" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="green" @click="save">{{ $t("general.save") }}</v-btn>
        <!-- <v-btn @click="close">{{ $t("general.close") }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from "@/util/RulesUtil";

export default {
  name: "PlantEditDialog",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          name: "",
          fid: null
        };
      },
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      errors: false,
    };
  },
  computed: {
    rules() {
      return RulesUtil;
    },
    editMode() {
      return !!this.item.id
    },
    nameRules() {
      if (this.editMode) {
        return [this.rules.maxLength(255)];
      } else {
        return [this.rules.maxOptionalLength(255)];
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.errors = await this.$store.dispatch("plant/saveItem", this.item);
      this.loading = false;
      if (!this.errors) {
        this.close();
      }
    },
    errorMessages(field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }

      return "";
    },
  },
  watch: {
    item() {
      this.errors = false;
    },
  },
};
</script>
