import SubstationService from '@/services/SubstationService'

// initial state
const getDefaultState = () => {
  return {
    items: [],
    panelErrors: false
  }
}
const state = getDefaultState()

// getters
const getters = {
  items: state => state.items,
  panelErrors: state => state.panelErrors
}

// actions
const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  panelErrors({ commit }, panelErrors) {
    commit('panelErrors', panelErrors)
  },
  async index ({ commit }) {
    try {
      const response = await SubstationService.index()
      commit('items', response.data.substations)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  async saveItem ({ commit }, item) {
    const action = item.id !== undefined ? 'update' : 'store';
    const payload = {
      id: item.id,
      substationname: item.name,
      address: item.address,
      connectionpower: item.power,
      area: item.area,
      // customer_id: item.customer_id,
      fid: item.fid
    }
    let errors = false
    try {
      const response = await SubstationService[action](payload)
      commit('saveItem', response.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    return errors
  },
  async deleteItem ({ commit }, item) {
    try {
      await SubstationService.delete(item)
      commit('deleteItem', item)
    } catch (e) {
      console.error(e)
    }
  },
  async updateGeometry ({ commit }, payload) {
    let errors = false
    try {
      const result = await SubstationService.updateGeometry(payload.id, JSON.stringify(payload.geometry))

      commit('saveItem', result.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  async updateNode ({ commit }, payload) {
    let errors = false
    try {
      const result = await SubstationService.updateNode(payload.id, payload.nodeId, payload.isSupply)

      commit('saveItem', result.data.substation)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        console.error(e)
      }
    }

    commit('panelErrors', errors)
    return errors
  },
  resetPanelError ({ commit }) {
    commit('panelErrors', false)
  }
}

// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  items (state, items) {
    state.items = items
  },
  panelErrors (state, errors) {
    state.panelErrors = errors
  },
  saveItem (state, item) {
    const items = state.items.slice()

    const index = items.findIndex(t => t.id === item.id)
    if (index === -1) {
      items.unshift(item)
    } else {
      items[index] = item
    }

    state.items = items
  },
  deleteItem (state, item) {
    const items = state.items

    state.items = items.filter(t => t.id !== item.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
