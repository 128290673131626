import PlantService from '../../services/PlantService'
import SubstationService from '../../services/SubstationService'
import EdgeService from '../../services/EdgeService'
import NodeService from '../../services/NodeService'
import moment from "moment"
import { featureTypes } from '../../util/structs'

// initial state
const getDefaultState = (state) => {

  const nowdate = state?.date ?? moment().startOf('year').format('YYYY-MM-DD')
  const nowdate_end = state?.date_end ?? moment().endOf('year').format('YYYY-MM-DD')
  // let nowdate = moment().format('YYYY-MM-DD');

  // if(state?.date) {
  //   nowdate = state.date;
  // }

  // let nowdate_end = moment().format('YYYY-MM-DD');
  // if(state?.date_end) {
  //   nowdate_end = state.date_end;
  // }
  return {
    loading: false,
    date: nowdate,
    date_end: nowdate_end,
    data: {},
    type: undefined,
    item: undefined,
    open: false
  }
}
const state = getDefaultState()

// getters
const getters = {
  loading: state => state.loading,
  date: state => state.date,
  date_end: state => state.date_end,
  data: state => state.data,
  open: state => state.open,
  item: state => state.item,
  type: state => state.type
}

// actions
const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  date({ commit }, date) {
    commit('date', date)
  },
  date_end({ commit }, date_end) {
    commit('date_end', date_end)
  },
  open({ commit }, payload) {
    commit('open', payload)
  },
  close({ commit }) {
    commit('resetState');
  },
  async loadData({ commit, state }) {
    commit('loading', true)
    try {
      let promise = null
      switch (state.type) {
        case featureTypes.PLANT:
          promise = PlantService.popupData(state.item, state.date, state.date_end)
          break
        case featureTypes.SUBSTATION:
          promise = SubstationService.popupData(state.item, state.date, state.date_end)
          break
        case featureTypes.NODE:
          promise = NodeService.popupData(state.item, state.date, state.date_end)
          break
        case featureTypes.EDGE:
          promise = EdgeService.popupData(state.item, state.date, state.date_end)
          break
        default:
          // do nothing
      }

      if (promise !== null) {
        const response = await promise;
        commit('data', response.data);
      }
    } catch (e) {
      console.error(e)
      commit('data', {})
    }
    commit('loading', false)
  }
}

// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState(state))
  },
  data (state, data) {
    state.data = data
  },
  loading (state, loading) {
    state.loading = loading
  },
  date (state, date) {
    state.date = date
  },
  date_end (state, date_end) {
    state.date_end = date_end
  },
  open (state, payload) {
    state.item = payload.item
    state.type = payload.type
    state.open = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
