import AnalysisService from "@/services/AnalysisService";
import moment from "moment"

export const VISUALIZATION_MODE = {
  EDGE_PRESSURE_DELTA: 1,
  EDGE_TEMPERATURE_DELTA: 2,
  EDGE_MASS: 3,
  EDGE_VELOCITY: 4
};

export const VISUALIZATION_MODE_UNIT = {
  [VISUALIZATION_MODE.EDGE_PRESSURE_DELTA]: "Pa/m",
  [VISUALIZATION_MODE.EDGE_TEMPERATURE_DELTA]: "C°/m",
  [VISUALIZATION_MODE.EDGE_MASS]: "kg/h",
  [VISUALIZATION_MODE.EDGE_VELOCITY]: "m/s"
};

export function visualizationModeUnit(mode) {
  return VISUALIZATION_MODE_UNIT[mode];
}

const getDefaultState = () => ({
  initialized: false,
  loading: false,
  data: undefined,
  enabled: false,
  overwriteLabels: false,
  selectedStartDate: undefined,
  selectedEndDate: undefined,
  maxEndDate: undefined,
  minStartDate: undefined,
  visualizationMode: VISUALIZATION_MODE.EDGE_PRESSURE_DELTA,
  visualizationThresholds: new Map([
    [VISUALIZATION_MODE.EDGE_PRESSURE_DELTA, 200], // TODO 200 correct?
    [VISUALIZATION_MODE.EDGE_TEMPERATURE_DELTA, 10], // TODO
    [VISUALIZATION_MODE.EDGE_MASS, 1.5],
    [VISUALIZATION_MODE.EDGE_VELOCITY, 1.5]
  ]),
});

const state = getDefaultState();

const getters = {
  initialized: (state) => state.initialized,
  minStartDate: state => state.minStartDate,
  maxEndDate: state => state.maxEndDate,
  selectedStartDate: (state) => state.selectedStartDate,
  selectedEndDate: (state) => state.selectedEndDate,
  loading: (state) => state.loading,
  enabled: (state) => state.enabled,
  overwriteLabels: (state) => state.overwriteLabels,
  hasData: (state) => state.data !== undefined,
  data: (state) => state.data ?? {},
  edgeDeltas: (state, getters) => {
    const deltas = getters.data?.edge_deltas ?? [];
    const map = new Map();

    for (const delta of deltas) {
      map.set(delta.edge_id, delta);
    }

    return map;
  },
  analysisVisualizationMode: (state, getters) => getters.data?.mode ?? getters.visualizationMode,
  analysisVisualizationModeUnit: (state, getters) => visualizationModeUnit(getters.analysisVisualizationMode),
  analysisVisualizationThreshold: (state, getters) => getters.data?.threshold ?? getters.visualizationThreshold,
  visualizationMode: (state) => state.visualizationMode,
  visualizationThresholds: (state) => state.visualizationThresholds,
  visualizationThreshold: (state, getters) => {
    const mode = getters.visualizationMode;
    const thresholds = getters.visualizationThresholds;

    return thresholds.get(mode) ?? 0;
  },
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  async initialize({ commit, dispatch }) {
    commit("initialized", false);
    try {
      const response = await AnalysisService.latestSimulationTimeRange()
      const startDate = moment(response.data.selected_start_date).format('YYYY-MM-DD HH:mm')
      const endDate = moment(response.data.selected_end_date).format('YYYY-MM-DD HH:mm')
      commit('selectedStartDate', startDate)
      commit('minStartDate', startDate)
      commit('selectedEndDate', endDate)
      commit('maxEndDate', endDate)
    } catch(e) {
      console.log(e)
    }
    commit("initialized", true);

    dispatch('loadData')
  },
  selectedStartDate({ commit }, selectedStartDate) {
    commit("selectedStartDate", selectedStartDate);
  },
  selectedEndDate({ commit }, selectedEndDate) {
    commit("selectedEndDate", selectedEndDate);
  },
  loading({ commit }, loading) {
    commit("loading", loading);
  },
  overwriteLabels({commit}, overwriteLabels) {
    commit("overwriteLabels",overwriteLabels)
  },
  enabled({ commit }, enabled) {
    commit("enabled", enabled);
  },
  visualizationMode({ commit }, visualizationMode) {
    commit("visualizationMode", visualizationMode);
  },
  visualizationThreshold({ commit, getters }, visualizationThreshold) {
    commit("visualizationThreshold", {
      visualizationMode: getters.visualizationMode,
      visualizationThreshold,
    });
  },
  async loadData({ commit, state, getters }) {
    commit("loading", true);

    try {
      const response = await AnalysisService.aggregatedSimulationData({
        selectedStartDate: state.selectedStartDate,
        selectedEndDate: state.selectedEndDate,
        mode: getters.visualizationMode,
        threshold: getters.visualizationThreshold
      });
      commit("data", response.data);
      commit("enabled", true);
      commit("overwriteLabels", true);
    } catch (e) {
      console.error(e);
      commit("data", undefined);
      commit("enabled", false);
      commit("overwriteLabels", false);
    }
    commit("loading", false);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  initialized(state, initialized) {
    state.initialized = initialized;
  },
  loading(state, loading) {
    state.loading = loading;
  },
  data(state, data) {
    state.data = data;
  },
  enabled(state, enabled) {
    state.enabled = enabled;
  },
  overwriteLabels(state, overwriteLabels) {
    state.overwriteLabels = overwriteLabels
  },
  visualizationMode(state, visualizationMode) {
    state.visualizationMode = visualizationMode;
  },
  selectedStartDate(state, selectedStartDate) {
    state.selectedStartDate = selectedStartDate;
  },
  selectedEndDate(state, selectedEndDate) {
    state.selectedEndDate = selectedEndDate;
  },
  minStartDate(state, minStartDate) {
    state.minStartDate = minStartDate;
  },
  maxEndDate(state, maxEndDate) {
    state.maxEndDate = maxEndDate;
  },
  visualizationThreshold(state, { visualizationMode, visualizationThreshold }) {
    const visualizationThresholds = new Map(state.visualizationThresholds);

    visualizationThresholds.set(visualizationMode, visualizationThreshold);

    state.visualizationThresholds = visualizationThresholds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
