<template>
  <v-dialog
    :value="open"
    :max-width="maxWidth"
    @input="close"
  >
    <v-card>
      <v-card-title>
        <template v-if="title">{{ title }}</template>
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <component
          v-if="component !== undefined"
          v-bind:is="component"
          v-on:width="onWidth"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SubstationMapPopup from '../substation/SubstationMapPopup.vue'
import PlantMapPopup from '../plant/PlantMapPopup.vue'
import EdgeMapPopup from '../edge/EdgeMapPopup.vue'
import NodeMapPopup from '../node/NodeMapPopup.vue'
import { featureTypes } from '../../../util/structs'

export default {
  name: "MapPopup",
  components: {
    SubstationMapPopup,
    PlantMapPopup,
    NodeMapPopup,
    EdgeMapPopup
  },
  data () {
    return {
      width: undefined
    }
  },
  computed: {
    open () {
      return this.$store.getters['mapPopup/open']
    },
    item () {
      return this.$store.getters['mapPopup/item']
    },
    type () {
      return this.$store.getters['mapPopup/type']
    },
    title () {
      return this.item?.name ?? undefined
    },
    maxWidth () {
      if (this.width !== undefined) {
        return this.width
      }

      switch (this.type) {
        case featureTypes.SUBSTATION:
        case featureTypes.PLANT:
        case featureTypes.EDGE:
        case featureTypes.NODE:
          return '70%';
        default:
          return 700;
      }
    },
    component () {
      switch (this.type) {
        case featureTypes.SUBSTATION:
          return 'SubstationMapPopup'
        case featureTypes.PLANT:
          return 'PlantMapPopup'
        case featureTypes.NODE:
          return 'NodeMapPopup'
        case featureTypes.EDGE:
          return 'EdgeMapPopup'
        default:
          return undefined
      }
    }
  },
  methods: {
    onWidth (value) {
      this.width = value
    },
    close () {
      this.$store.dispatch('mapPopup/close')
    }
  }
}
</script>
