<template>
  <div class="py-10" style="height: 654px">
    <general-info-table :item="substation" />
  </div>
</template>

<script>
import GeneralInfoTable from "../../../../components/GeneralInfoTable.vue";
export default {
  name: "SubstationMapPopupGeneral",
  components: { GeneralInfoTable },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    substation() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
  },
};
</script>
