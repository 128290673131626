<template>
  <div>
    <Crops v-if="fieldId"/>
    <Fields v-else ref="fields" />
  </div>
</template>

<script>
import Fields from './Fields.vue';
import Crops from './crops/Crops.vue';

export default {
  name: 'FieldsWrapper',
  components: {
    Fields,
    Crops
  },
  computed: {
    fieldId () {
      return this.$store.getters['energyPlanner/field/fieldId']
    }
  },
  mounted() {
    this.$store.dispatch('energyPlanner/field/resetFlags')
  },
  beforeDestroy () {
    this.$store.dispatch('energyPlanner/field/resetFlags')
  },
  methods: {
    onMapEnergyPlannerItemClick (field) {
      this.$store.dispatch('energyPlanner/field/leaveCrops')
      this.$nextTick(() => {
        this.$refs.fields.openEdit(field)
      })
    }
  }
}
</script>
