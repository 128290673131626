<template>
    <v-select v-model="value" :items="items" return-object item-text="value" :label="$t('panels.edge.pipe_type')"
        :hint="$t('panels.edge.pipe_type_hint')" persistent-hint />
</template>

<script>

const dinItems = [
    { value: "DN-15", diameter: 0.0173, htc: 0.000183 },
    { value: "DN-20", diameter: 0.0229, htc: 0.000183 },
    { value: "DN-25", diameter: 0.0297, htc: 0.0001981 },
    { value: "DN-32", diameter: 0.0378, htc: 0.0002154 },
    { value: "DN-40", diameter: 0.0437, htc: 0.0002573 },
    { value: "DN-50", diameter: 0.0553, htc: 0.0002495 },
    { value: "DN-65", diameter: 0.0709, htc: 0.0002923 },
    { value: "DN-80", diameter: 0.0831, htc: 0.0003348 },
    { value: "DN-100", diameter: 0.1079, htc: 0.0003348 },
    { value: "DN-125", diameter: 0.1325, htc: 0.00031 },
    { value: "DN-150", diameter: 0.1603, htc: 0.0003763 },
    { value: "DN-200", diameter: 0.2101, htc: 0.0004115 },
    { value: "DN-250", diameter: 0.263, htc: 0.0004115 },
    { value: "DN-300", diameter: 0.3127, htc: 0.0004115 },
];

export default {
    name: 'DinPipeTypeSelect',
    data() {
        return {
            value: undefined
        }
    },
    computed: {
        items() {
            return dinItems
        }
    },
    watch: {
        value(value) {
            this.$emit('change', {
                diameter: value.diameter * 100,
                htc: value.htc * 1000
            })
        }
    }
}
</script>
