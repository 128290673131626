<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <div class="d-flex align-center justify-center mb-2 flex-wrap" style="margin-top: -12px; gap: 4px;" v-if="dataAggregates">
      <v-chip v-for="field in ['min', 'max']" :key="field" :color="dataAggregatesSettings[field].color" label
        :style="{ color: dataAggregatesSettings[field].textColor }">
        {{ dataAggregatesSettings[field].label }}: {{ dataAggregates[field] }} {{ dataAggregatesSettings[field].unit }}
      </v-chip>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.simulation_data && data.simulation_data.length < 1">{{
      $t("general.no_data_yet") }}
    </v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import DatePicker from '../../../../components/DatePicker.vue'
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: 'NodeMapPopupPressure',
  data() {
    return {
      chartSeries: []
    }
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters['mapPopup/data']
    },
    simulationData() {
      return this.data?.simulation_data ?? []
    },
    dataAggregates() {
      const data = this.simulationData
      // no point in computing min/max
      if (data.length <= 1) {
        return
      }

      let min;
      let max;

      for (let i = 0; i < data.length; i++) {
        const y = Math.max(0, data[i].pressure)
        if (min === undefined || y < min) min = y
        if (max === undefined || y > max) max = y
      }

      return {
        min: min?.toFixed(2),
        max: max?.toFixed(2)
      }
    },
    dataAggregatesSettings() {
      return {
        min: {
          label: this.$t("panels.node.data_fields.pressure_min"),
          unit: 'bar',
          color: '#2566cb',
          textColor: 'white'
        },
        max: {
          label: this.$t("panels.node.data_fields.pressure_max"),
          unit: 'bar',
          color: "#2566cb",
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters['mapPopup/date']
      },
      set(value) {
        this.$store.dispatch('mapPopup/date', value)
      }
    },
    date_end: {
      get() {
        return this.$store.getters['mapPopup/date_end']
      },
      set(value) {
        this.$store.dispatch('mapPopup/date_end', value)
      }
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        stroke: {
          curve: "smooth",
        },
        colors: ['#2566cb'],
        xaxis: {
          type: "datetime",
          min: date.startOf('day').valueOf(),
          max: dateEnd.endOf('day').valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} bar`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    prepareData() {
      const simulationDataSets = this.prepareSimulationData()

      this.chartSeries = [
        {
          name: this.$t("panels.node.data_fields.pressure"),
          data: simulationDataSets.data,
        }
      ]
    },
    prepareSimulationData() {
      const data = this.prepareNormalizedData(this.simulationData, 'pressure', 0);

      return {
        data
      }
    },
    tooltipFormatY(value) {
      return `${value?.toFixed(2)} bar`
    },
  }
}
</script>
