<template>
  <v-dialog :value="open" :max-width="maxWidth" @input="close">
    <v-card width="100%">
      <v-card-title>
        <template>Find Geometries</template>
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle>Bulk insert Corrdinates in following format: 'lat, lon, name, kw, fid, node_id_supply, node_id_return' otherwise it won't work, if left empty then default values below are used</v-card-subtitle>
      <v-card-text>
        <div class="d-flex">
          <v-text-field class="flex-grow-1 mr-2" :label="$t('general.defaultname')" v-model="defaultName" />
          <v-text-field type="number" class="flex-grow-1 ml-2" :label="$t('general.defaultkw')" v-model="defaultKW" />
        </div>
        <div class="d-flex flex-row flex-grow-1">
          <div class="ma-g d-flex justify-center flex-wrap flex-grow-1 mr-2">
            <v-textarea auto-grow outlined dense :rules="[validateGeometryInput]"></v-textarea>
          </div>
          <div class="ma-g d-flex justify-center flex-wrap flex-grow-1 ml-2">
            <v-textarea auto-grow outlined dense disabled :value="validationMessageString"></v-textarea>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="primary" @click="findGeometries" :disabled="!hasBeenValidated">Find</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OverpassApiService from "@/services/OverpassApiService";
import SubstationService from "@/services/SubstationService";

export default {
  name: "GeometryFinderPopup",
  components: {},
  data() {
    return {
      //   networkFile: null
      isValidated: false,
      validationErrors: [],
      toCheck: [],
      validationMessages: "",
      INPUTPARAMS: 7,
      defaultName: "Substation",
      defaultKW: 15,
    };
  },
  computed: {
    open() {
      return this.$store.getters["findGeometriesPopup/open"];
    },
    maxWidth() {
      if (this.width !== undefined) {
        return this.width;
      }
      return "100%";
    },
    hasBeenValidated() {
      return this.isValidated == true;
    },
    validationMessageString() {
      return this.validationMessages;
    },
    substations() {
      return this.$store.getters["substation/items"];
    },
  },
  methods: {
    onWidth(value) {
      this.width = value;
    },
    close() {
      this.$store.dispatch("findGeometriesPopup/close");
    },
    async findGeometry(substationElement, index) {
      // console.log(coordinateLat, coordinateLon);
      try {
        //TODO CAVEAT EXTREME ... querypoint has switched params lon/lat vs lat/lon!!!
        const response = await OverpassApiService.queryPoint(substationElement[1], substationElement[0]);
        // console.log(response);
        const buildingQueryItem = response.data.elements[0];
        // console.log(buildingQueryItem);
        if (buildingQueryItem !== undefined) {
          // this.createOverpassQueryFeature(buildingQueryItem);
          return Promise.resolve({ item: buildingQueryItem, idx: index, sub: substationElement, msg: "ok" });
        } else {
          return Promise.reject({ msg: "no building found for: " + substationElement[2], idx: index, sub: substationElement });
        }
      } catch (error) {
        return Promise.reject({ msg: error, idx: index,sub: substationElement });
      }
    },
    async findGeometries() {
      // console.log(this.toCheck);
      this.isValidated = false;
      // this.validationErrors = [];
      let findPromises = [];
      let substationsToCreate = [];
      let substationsToCreateTasks = [];
      try {
        for (let index = 0; index < this.toCheck.length; index++) {
          const elem = this.toCheck[index];
          findPromises.push(this.findGeometry(elem, index));
        }
        await Promise.allSettled(findPromises).then((results) =>
          results.forEach((result) => {
            // console.log(resval);
            let elem = null;            
            if (result.status == "fulfilled") {
              elem = result.value.sub;
              elem.geometry = result.value.item.geometry;
              const tags = result.value.item.tags;
              
              if (!tags["addr:street"] || !tags["addr:housenumber"]) {
                elem.address = this.$t("map.query_geometry.no_address_data");
              } else {
                elem.address = `${tags["addr:street"]} ${tags["addr:housenumber"]}`;
              }
              this.validationErrors[result.value.idx] = "geometry found";
            } else {
              elem = result.reason.sub;
              this.validationErrors[result.reason.idx] = "rectangle created";
              //artificial square around original coordinates (oc)
              const olat = parseFloat(elem[0]);
              const olon = parseFloat(elem[1]);
              elem.geometry = [
                {lat:olat - 0.00010, lon:olon + 0.00015},
                {lat:olat - 0.00010, lon:olon - 0.00015},
                {lat:olat + 0.00010, lon:olon - 0.00015},
                {lat:olat + 0.00010, lon:olon + 0.00015},
                {lat:olat - 0.00010, lon:olon + 0.00015}
              ];
            }
            substationsToCreate.push(elem);
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        let valErrors = JSON.parse(JSON.stringify(this.validationErrors));
        // console.log(valErrors);
        let output = "";
        for (let i = 0; i < findPromises.length; i++) {
          if (valErrors[i]) {
            output += valErrors[i];
          }
          output += "\r";
        }
        this.validationMessages = output;
      }
      // console.log(substationsToCreate);
      try {
        for (let index = 0; index < substationsToCreate.length; index++) {
          const elem = substationsToCreate[index];
          substationsToCreateTasks.push(this.saveSubstation(elem, index));
        }
        await Promise.allSettled(substationsToCreateTasks).then((results) =>
          results.forEach((r) => {
            let points = [];
            // console.log(r.value.geometry);
            // for (geom in r.value.geometry) {
              console.log(r);
            if("fulfilled" == r.status) {
              r.value.geometry.forEach((geom) => {points.push([geom.lon, geom.lat]);});
              // console.log(points);
              const geoJson = { type: "Polygon", coordinates: [points] };
              let jsString = JSON.stringify(geoJson);
              SubstationService.updateGeometry(r.value.id, jsString);
            } else {
              this.validationErrors[r.reason.index] = r.reason?.error?.response?.data?.errors.toString().substring(0, 100);
            }
          })
        );
      } catch (error) {
        // console.log(error);
      } finally {
        let valErrors = JSON.parse(JSON.stringify(this.validationErrors));
        // console.log(valErrors);
        let output = "";
        for (let i = 0; i < findPromises.length; i++) {
          if (valErrors[i]) {
            output += valErrors[i];
          }
          output += "\r";
        }
        this.validationMessages = output;
        this.isValidated = true;
      }
    },
    async saveSubstation(item, idx) {
      let payload = {
        // id: item.id,
        substationname: item[2],
        address: item.address,
        connectionpower: item[3],
        // area: item.area,
        // customer_id: item.customer_id,
        fid: item[4],
        generateSeries: false,
      };
      if(item[5]) {
        payload.supply_node_id = item[5];
      }
      if(item[6]) {
        payload.return_node_id = item[6];
      }

      try {
        const response = await SubstationService.store(payload);
        if (response?.data?.substation) {
          item.id = response.data.substation.id;
          this.validationErrors[idx] = "substation created";
          return Promise.resolve(item);
        }
      } catch (error) {
        console.log(error);
        return Promise.reject({msg:"creation failed", error:error, index:idx});
      }
      // console.log(item);
    },
    validateGeometryInput(input) {
      let validationMsg = "";
      let toValidate = null;
      this.validationErrors = [];
      this.toCheck = [];
      this.isValidated = false;
      // console.log(input);
      try {
        toValidate = String(input);
      } catch (error) {
        validationMsg = "input is not a string";
      }
      let lines = [];
      if (!input) {
        validationMsg = "required";
      } else {
        try {
          lines = toValidate.split(/\r?\n|\r|\n/g);
          let tokens = [];
          // lines.forEach((l, lidx) => {
          for (let lidx = 0; lidx < lines.length; lidx++) {
            const l = lines[lidx];
            try {
              //0:lat, 1:lon,2:name, 3:kw
              //47.07702163308544, 15.449083740186623, lss,15
              tokens = String(l).split(",");
            } catch (error) {
              // console.log(error);
              this.validationErrors[lidx] = "not a string";
              continue;
            }
            if (tokens.length != this.INPUTPARAMS) {
              this.validationErrors[lidx] = "argument length";
              this.isValidated = false;
              continue;
            }
            try {
              parseFloat(tokens[0]);
            } catch (error) {
              this.isValidated = false;
              continue;
            }
            try {
              parseFloat(tokens[1]);
            } catch (error) {
              this.isValidated = false;
              continue;
            }
            if ("" === tokens[2].trim()) {
              tokens[2] = this.defaultName + "_" + lidx;
            }

            if ("" === tokens[3].trim()) {
              tokens[3] = this.defaultKW;
            }
            if ("" === tokens[4].trim()) {
              tokens[4] = null;
            }
            if ("" === tokens[5].trim()) {
              tokens[5] = null;
            }
            if ("" === tokens[6].trim()) {
              tokens[6] = null;
            }
            if (!this.check_lat_lon(tokens[0].trim(), tokens[1].trim())) {
              this.isValidated = false;
              this.validationErrors[lidx] = "not valid GPS coordinates";
              continue;
            } else {
              this.toCheck[lidx] = tokens;
              // console.log(this.toCheck);
            }
            this.isValidated = true;
          }
          // });
        } catch (error) {
          console.log(error);
        }
      }
      let valErrors = JSON.parse(JSON.stringify(this.validationErrors));
      // console.log(valErrors);
      let output = "";
      for (let i = 0; i < lines.length; i++) {
        if (valErrors[i]) {
          output += valErrors[i];
        }
        output += "\r";
      }
      this.validationMessages = output;

      // this.isValidated = true;
      if (this.isValidated) {
        return true;
      } else {
        return validationMsg;
      }
    },
    check_lat_lon(lat, lon) {
      const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
      const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
      let validLat = regexLat.test(lat);
      let validLon = regexLon.test(lon);
      return validLat && validLon;
    },
  },
};
</script>
        