import axios from 'axios'
import store from '../store/store'
import router from '../router'
import AccountService from './AccountService'

const locals = {
  refreshTokenRequest: null,
  logoutTimeout: null,
}

function redirectToLogin(sendLogout = false) {
  if (sendLogout) {
    AccountService.logout({ screen: screen.width + 'x' + screen.height }).catch(() => { })
  }
  store.commit('account/logout')
  if (!router.currentRoute || !(['resetPassword', 'login', 'register', 'forgotPassword'].includes(router.currentRoute.name))) {
    router.push({ name: 'login' })
  }
}

const timeoutDelay = 120 * 3600 * 1000

// start 2h timer
function clearLogoutTimeout() {
  if (locals.logoutTimeout) {
    clearTimeout(locals.logoutTimeout)
    locals.logoutTimeout = null
  }
}
function axiosRequestHandler(request) {
  clearLogoutTimeout()

  locals.logoutTimeout = setTimeout(() => {
    redirectToLogin(true)
  }, timeoutDelay)

  return request
}

const responseError = (error) => {
  const originalRequest = error.config
  if (error.response) {
    if (error.response.status === 403) {
      clearLogoutTimeout()
      redirectToLogin(true)
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      if (originalRequest.url === '/auth/refresh') {
        clearLogoutTimeout()
        redirectToLogin()
        return Promise.reject(error)
      }

      if (originalRequest.url === '/auth/logout') {
        clearLogoutTimeout()
        return Promise.reject(error)
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true
        return getRefreshTokenRequest()
          .then(res => {
            if (res.status === 200) {
              store.dispatch('account/checkResponse', { response: res })
              return axios(originalRequest)
            }
          })
      }
    }
  }

  return Promise.reject(error)
}

const resetRefreshTokenRequest = () => {
  locals.refreshTokenRequest = null
}

const getRefreshTokenRequest = () => {
  if (!locals.refreshTokenRequest) {
    locals.refreshTokenRequest = AccountService.refresh()
    locals.refreshTokenRequest.then(resetRefreshTokenRequest, resetRefreshTokenRequest)
  }

  return locals.refreshTokenRequest
}

const api = (contentType = "json") => {
  const baseURL = process.env.VUE_APP_API_URL + 'frontend'
  let instance = null;
  let myHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (contentType == 'zip') {
    myHeaders = {
      Accept: 'application/zip',
      'Content-Type': 'application/zip',
    };

  } else if (contentType == 'file') {
    myHeaders = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    };
  }
  if (contentType == 'zip') {
    instance = axios.create(
      {
        baseURL,
        withCredentials: true,
        headers: myHeaders,
        responseType: 'arraybuffer'
      });
  } else {
    instance = axios.create(
      {
        baseURL,
        withCredentials: true,
        headers: myHeaders,
      });
  }

  instance.interceptors.response.use((response) => { return response }, responseError)
  instance.interceptors.request.use(axiosRequestHandler, (error) => Promise.reject(error))
  return instance
}

export default api
