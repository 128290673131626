import SettingsService from '../../services/SettingsService'

// initial state
const getDefaultState = () => {
  return {
    locales: [],
    mapSources: [],
    edgeLabelData: [],
    nodeLabelData: []
  }
}

const state = getDefaultState()

// getters
const getters = {
  locales: state => state.locales,
  mapSources: state => state.mapSources,
  edgeLabelData: state => state.edgeLabelData,
  nodeLabelData: state => state.nodeLabelData,
}

// actions
const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async index ({ commit }) {
    try {
      const response = await SettingsService.index()
      commit('index', response.data)
    } catch (e) {
      console.error(e)
      commit('index', {})
    }
  }
}

// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  index (state, data) {
    state.locales = data.locales ?? [];
    state.mapSources = data.map_sources ?? [];
    state.edgeLabelData = data.edgeLabelData ?? [];
    state.nodeLabelData = data.nodeLabelData ?? [];

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
