<template>
  <div>
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
import { Chart } from "highcharts-vue";
export default {
  mixins: [NormalizeDataSeries],
  name: "BenefitsUnit",
  props: {
    unitId: String,
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return { internalUnit: Object };
  },
  computed: {
    timeSteps() {
      return this.$store.getters["energyPlanner/benefitsDataTimeSteps"];
    },
    unit() {
      let selectedUnit = null;
      const comm = this.$store.getters["energyPlanner/benefitsDataCommunity"];
      for (const p of comm.properties) {
        for (const b of p.buildings) {
          for (const u of b.units) {
            if (this.unitId == u.id) {
              return u;
            }
          }
        }
      }

      console.log("returning empty");
      return selectedUnit;
    },
    chartOptions() {
      return {
        ...this.options,
        series: this.series,
      };
    },
    options() {
      return {
        chart: {
          type: "area",
          zoomType: "x",
        },
        colors: [
          "#e43b16",
          "#132437",
          "#ee749f",
          "#99caa0"
        ],        
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: this.$t("panels.energyPlanner.benefits.x_axis"),
          },
          crosshair: true,
          type: "datetime",
        },
        yAxis: {
          title: {
            text: this.$t("panels.energyPlanner.benefits.y_axis"),
          },
        },
        tooltip: {
          shared: true,
          pointFormat: "{series.name}: {point.y} kW<br/>",
          valueDecimals: 2,
        },
        credits: { enabled: false },
        plotOptions: {
          area: { fillOpacity: 0.7, stacking: "normal", lineWidth: 1 },
          line: { lineWidth: 3 },
          series: { marker: { enabled: false } },
        },
      };
    },
    series() {
      const dataTotalPowerCons = [];
      const dataGridCons = [];
      const dataSumSelfCons1 = [];
      const dataSumSelfCons2Plus3 = [];

      // for every time step create a pair in each dataset
      // console.log(this.unit);

      let startindex = 0;
      let endIndex = this.timeSteps.length;

      for (let i = 0; i < this.timeSteps.length; i++) {
        const dTime = new Date(this.timeSteps[i]);

        if (startindex <= 0 && dTime >= this.date) {
          startindex = i;
        }
        if (dTime > this.dateEnd) {
          endIndex = i;
          break;
        }
      }
      const stepLength = endIndex - startindex;
      let increment = 1;

      while (stepLength / increment > process.env.VUE_APP_CHART_DISPLAYED_TIMESTEPS) {
        increment++;
      }
      for (let i = startindex; i < endIndex; i = i + increment) {
        const x = this.$moment(this.timeSteps[i]).valueOf();
        dataTotalPowerCons.push({ x, y: this.unit.total_power_cons[i] });
        dataGridCons.push({ x, y: this.unit.grid_cons[i] });
        dataSumSelfCons1.push({ x, y: this.unit.sum_self_cons1[i] });
        dataSumSelfCons2Plus3.push({ x, y: this.unit.sum_self_cons2plus3[i] });
      }

      return [
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.total_power_cons"),
          type: "line",
          data: dataTotalPowerCons,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.grid_cons"),
          type: "area",
          data: dataGridCons,
        },
        {
          name: this.$t(
            "panels.energyPlanner.benefits.kpi.sum_self_cons2plus3"
          ),
          type: "area",
          data: dataSumSelfCons2Plus3,
        },
        {
          name: this.$t("panels.energyPlanner.benefits.kpi.sum_self_cons1"),
          type: "area",
          data: dataSumSelfCons1,
        },
      ];
    },
  },
};
</script>
