<template>
  <v-text-field dense v-model="searchAddress" :label="$t('general.address')"
    :error-messages="panelErrors && panelErrors.addressQuery ? panelErrors.addressQuery : ''">
    <template v-slot:append-outer>
      <v-btn small @click="querySearchAddress" color="accent" :loading="loading" :disabled="searchAddressDisabled">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import OlUtil from '@/util/OlUtil'

export default {
  name: 'SearchAddress',
  data() {
    return {
      searchAddress: ''
    }
  },
  computed: {
    loading() {
      return this.$store.getters['energyPlanner/loading']
    },
    panelErrors() {
      return this.$store.getters['energyPlanner/panelErrors']
    },
    searchAddressDisabled() {
      return this.searchAddress === '' || this.loading
    },
    addressLocation() {
      return this.$store.getters['energyPlanner/addressLocation']
    },
    mapComponent() {
      return this.$store.getters['map/component']
    },
  },
  watch: {
    addressLocation() {
      this.centerOnAddressLocation()
    },
  },
  methods: {
    querySearchAddress() {
      this.$store.dispatch('energyPlanner/queryAddressLocation', this.searchAddress)
    },
    centerOnAddressLocation() {
      if (!this.addressLocation) {
        return
      }

      if (!this.mapComponent) {
        return
      }

      const { lon, lat } = this.addressLocation

      const position = OlUtil.transformCoordinates([lon, lat])
      this.mapComponent.centerOnLocation(position, 7)
    },
  }
}
</script>
