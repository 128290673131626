import Vue from 'vue'
import VueI18n from 'vue-i18n'

// vuetify locales
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'

Vue.use(VueI18n)

export const DEFAULT_LANG = process.env.VUE_APP_DEFAULT_LANG ?? 'en'

const locales = {
  de: {
    $vuetify: {
      ...de,
    },
    ...require('./i18n/de.json'),
  },
  en: {
    $vuetify: {
      ...en
    },
    ...require('./i18n/en.json')
  },
  fr: {
    $vuetify: {
      ...fr
    },
    ...require('./i18n/fr.json')
  }
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: 'de',
  messages: locales,
})

export default i18n
