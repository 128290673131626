<template>
  <panel name="energyPlanner" :initial-active="active">
    <template v-slot:fixed-content>
      <ComputeBenefitsButtonWrapper />
      <v-tabs v-model="tab" color="accent">
        <v-tab>{{ $t('panels.energyPlanner.tabs.buildings') }}</v-tab>
        <v-tab>{{ $t('panels.energyPlanner.tabs.fields') }}</v-tab>
        <v-tab>{{ $t('panels.energyPlanner.tabs.cooling_houses') }}</v-tab>
      </v-tabs>
      <v-card-text>
        <portal-target name="energy-planner-fixed"></portal-target>
      </v-card-text>
      <v-progress-linear :active="loading" indeterminate />
    </template>
    <v-alert v-if="panelErrors && panelErrors.general" type="error">
      {{ panelErrors.general }}
    </v-alert>
    <template>
      <BuildingsWrapper v-if="tab === 0" ref="buildingsWrapper" />
      <FieldsWrapper v-if="tab === 1" ref="fieldsWrapper" />
      <CoolingHousesWrapper v-if="tab === 2" ref="coolingHousesWrapper" />
    </template>
    <benefits v-if="benefitsOpen" @close="closeBenefits" />
  </panel>
</template>

<script>
import Panel from '../Panel.vue'
import Benefits from './benefits/Benefits.vue'
import BuildingsWrapper from './buildings/BuildingsWrapper.vue'
import FieldsWrapper from './fields/FieldsWrapper.vue'
import CoolingHousesWrapper from "./cooling_houses/CoolingHousesWrapper.vue"
import { clickStates, featureTypes } from '@/util/structs'
import ComputeBenefitsButtonWrapper from './ComputeBenefitsButtonWrapper.vue'

export default {
  name: 'EnergyPlannerPanel',
  props: {
    active: Boolean
  },
  components: {
    Panel,
    Benefits,
    FieldsWrapper,
    BuildingsWrapper,
    CoolingHousesWrapper,
    ComputeBenefitsButtonWrapper
  },
  data() {
    return {
      tab: null
    }
  },
  watch: {
    active() {
      this.$store.dispatch('energyPlanner/resetFlags')
    },
    tab () {
      this.$store.dispatch('map/setClickState', { clickState: clickStates.IDLE})
    }
  },
  computed: {
    benefitsOpen() {
      return this.$store.getters['energyPlanner/benefitsOpen']
    },
    panelErrors() {
      return this.$store.getters['energyPlanner/panelErrors']
    },
    loading() {
      return this.$store.getters['energyPlanner/loading']
    }
  },
  methods: {
    closeBenefits() {
      this.$store.dispatch('energyPlanner/leaveBenefits')
    },
    featureTypeTab(type) {
      switch (type) {
        case featureTypes.BUILDING:
          this.tab = 0
          break
        case featureTypes.FIELD:
          this.tab = 1
          break
        case featureTypes.COOLING_HOUSE:
          this.tab = 2
          break
      }
    },
    onMapEnergyPlannerItemClick(item, type) {
      this.featureTypeTab(type)

      this.$nextTick(() => {
        switch (type) {
          case featureTypes.BUILDING:
            this.$refs.buildingsWrapper.onMapEnergyPlannerItemClick(item)
            break
          case featureTypes.FIELD:
            this.$refs.fieldsWrapper.onMapEnergyPlannerItemClick(item)
            break
          case featureTypes.COOLING_HOUSE:
            this.$refs.coolingHousesWrapper.onMapEnergyPlannerItemClick(item)
            break
        }
      })
    }
  }
}
</script>
