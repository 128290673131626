<template>
  <div class="py-10" style="height: 654px;">   
    <general-info-table :item="plant"/>
  </div>
</template>

<script>
import GeneralInfoTable from "../../../../components/GeneralInfoTable.vue";
export default {
  name: "PlantMapPopupGeneral",
  components: { GeneralInfoTable },
  data() {
    return {};
  },
  computed: {
    plant() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
  },
};
</script>

