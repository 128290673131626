<template>
  <div>
    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <div class="d-flex align-center mb-2" style="margin-top: -12px;">
      <div>
        <mass-flow-switch v-model="showCubicMeters" />
      </div>
      <div class="d-flex flex-grow-1 align-center justify-center flex-wrap" style="gap: 4px;" v-if="dataAggregates">
        <v-chip v-for="field in ['min', 'max']" :key="field" :color="dataAggregatesSettings[field].color" label
          :style="{ color: dataAggregatesSettings[field].textColor }">
          {{ dataAggregatesSettings[field].label }}: {{ cubicMetersOrKilos(dataAggregates[field])?.toFixed(2) }} {{
            dataAggregatesSettings[field].unit }}
        </v-chip>
      </div>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.base_data && data.base_data.length < 1
      ">{{ $t("general.no_data") }}</v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" ref="chart" />
  </div>
</template>

<script>
import MassFlowSwitch from "@/views/misc/MassFlowSwitch.vue";
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker, MassFlowSwitch },
  name: "SubstationMapPopupMass",
  data() {
    return {
      chartSeries: [],
      showCubicMeters: true
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    unitTranslationSuffix() {
      return this.showCubicMeters ? 'm3' : 'kg'
    },
    displayedUnit() {
      return this.showCubicMeters ? 'm³/h' : 'kg/s'
    },
    dataAggregates() {
      const data = this.baseData

      // no point in computing min/max
      if (data.length <= 1) {
        return undefined
      }

      let min;
      let max;

      for (let i = 0; i < data.length; i++) {
        const yPrimary = data[i].m_P

        if (min === undefined || yPrimary < min) min = yPrimary
        if (max === undefined || yPrimary > max) max = yPrimary
      }

      return {
        min,
        max
      }
    },
    dataAggregatesSettings() {
      return {
        min: {
          label: this.$t(`panels.substation.data_fields.m_P_${this.unitTranslationSuffix}_min`),
          unit: this.displayedUnit,
          color: '#2566cb',
          textColor: 'white'
        },
        max: {
          label: this.$t(`panels.substation.data_fields.m_P_${this.unitTranslationSuffix}_max`),
          unit: this.displayedUnit,
          color: "#2566cb",
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        colors: ["#2566cb"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: this.tooltipFormatY,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
    showCubicMeters() {
      this.changeUnits()
    }
  },
  methods: {
    changeUnits() {
      // update chart series names
      this.chartSeries[0].name = this.$t(`panels.substation.data_fields.m_P_${this.unitTranslationSuffix}`)
      // this.chartSeries[1].name = this.$t(`panels.substation.data_fields.m_S_${this.unitTranslationSuffix}`)

      this.$refs.chart.refresh()
    },
    prepareData() {
      const baseDataSets = this.prepareBaseData();
      // const simulationDataSets = this.prepareSimulationData();
      // const prefixSimulation = "";

      this.chartSeries = [
        {
          name: this.$t(`panels.substation.data_fields.m_P_${this.unitTranslationSuffix}`),
          data: baseDataSets.m_P,
        },
        // {
        //   name: this.$t(`panels.substation.data_fields.m_S_${this.unitTranslationSuffix}`),
        //   data: baseDataSets.m_S,
        // },
        // {
        //   name:
        //     prefixSimulation +
        //     this.$t("panels.substation.data_fields.P_supply"),
        //   data: simulationDataSets.PSupply,
        // },
      ];
    },
    prepareBaseData() {
      const m_P = this.prepareNormalizedData(this.baseData, 'm_P');
      const m_S = this.prepareNormalizedData(this.baseData, 'm_S');

      // for (const item of this.baseData) {
      //   const x = item.timestamp;
      //   m_P.push({
      //     x,
      //     y: item.m_P,
      //   });
      //   m_S.push({
      //     x,
      //     y: item.m_S,
      //   });
      //   // dataSupply.push({
      //   //   x,
      //   //   y: item.power_supply
      //   // })
      // }

      return {
        m_P,
        m_S,
      };
    },
    cubicMetersOrKilos(value) {
      if (!value) return value

      return this.showCubicMeters ? value * 3.6 : value
    },
    tooltipFormatY(value) {
      const unit = this.displayedUnit
      return `${this.cubicMetersOrKilos(value)?.toFixed(2)} ${unit}`;
    },
  },
};
</script>
