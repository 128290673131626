<template>
  <div>
    <v-tabs
      v-model="tab"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <component v-bind:is="currentTab.component"/>
  </div>
</template>

<script>
import EdgeMapPopupGeneral from './components/EdgeMapPopupGeneral.vue'
import EdgeMapPopupMass from './components/EdgeMapPopupMass.vue'
import EdgeMapPopupEnergy from './components/EdgeMapPopupEnergy.vue'
import EdgeMapPopupExergy from './components/EdgeMapPopupExergy.vue'

export default {
  name: 'EdgeMapPopup',
  components: {
    EdgeMapPopupGeneral,
    EdgeMapPopupMass
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    tabs () {
      return [
        {text: this.$t('general.general'), component: EdgeMapPopupGeneral},
        {text: this.$t('panels.edge.tab_mass'), component: EdgeMapPopupMass},
        {text: this.$t('panels.edge.tab_energy'), component: EdgeMapPopupEnergy},
        {text: this.$t('panels.edge.tab_exergy'), component: EdgeMapPopupExergy}
      ]
    },
    currentTab () {
      return this.tabs[this.tab]
    },
    date () {
      return this.$store.getters['mapPopup/date']
    }
  },
  watch: {
    date () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('mapPopup/loadData')
    }
  }
}
</script>

