<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <date-picker
      :date.sync="date"
      :date_end.sync="date_end"
      :disabled="loading"
    />
    <v-divider />
    <v-alert
      type="warning"
      color="secondary"
      v-if="data && data.simulation_data && data.simulation_data.length < 1"
      >{{ $t("general.no_data_yet") }}
    </v-alert>
    <apexchart
      v-if="data !== undefined"
      type="line"
      height="500"
      :options="chartOptions"
      :series="chartSeries"
    />
    <v-alert type="warning" color="primary" v-else>{{
      $t("general.no_data")
    }}</v-alert>
  </div>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: "EdgeMapPopupEnergy",
  data() {
    return {
      chartSeries: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    simulationData() {
      return this.data?.simulation_data ?? [];
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} kW`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    prepareData() {
      const simulationDataSets = this.prepareSimulationData();

      this.chartSeries = [
        {
          name: this.$t("panels.edge.energy"),
          data: simulationDataSets.data,
        },
      ];
    },
    prepareSimulationData() {
      const data = this.prepareNormalizedData(this.simulationData, 'energy');
      return {
        data,
      };
    },
    tooltipFormatY(value) {
      return `${value?.toFixed(2)} kW`;
    },
  },
};
</script>
