import Api from './Api'

export default {
  index() {
    return Api().get('/simulations')
  },
  start(start, end) {
    return Api().post('/simulations', { "date_time_start": start, "date_time_end": end });
  },
  updateSimulationState (simulationId) {
    return Api().post(`/simulations/${simulationId}/update_state`)
  },
  downloadSimulation(simulationId) {
    return Api('zip').get(`simulations/${simulationId}/download`)
  },
  downloadSimulationTimeframe(from, to) {
    return Api('zip').post(`simulations/downloadYear`, {'from':from, 'to':to})
  }
}
