<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <div class="d-flex align-center justify-center mb-2 flex-wrap" style="margin-top: -12px; gap: 4px;"
      v-if="dataAggregates">
      <v-chip v-for="field in kpiFields" :key="field" :color="dataAggregatesSettings[field].color" label
        :style="{ color: dataAggregatesSettings[field].textColor }">
        {{ dataAggregatesSettings[field].label }}: {{ dataAggregates[field] }} {{ dataAggregatesSettings[field].unit }}
      </v-chip>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.base_data && data.base_data.length < 1 && data.simulation_data && data.simulation_data.length < 1
      ">{{ $t("general.no_data") }}</v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: "PlantMapPopupTemperature",
  data() {
    return {
      chartSeries: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    simulationData() {
      return this.data?.simulation_data ?? [];
    },
    hasSupplyOpti() {
      return this.simulationData.some(item => (item.T_supply_opti ?? 0) > 0)
    },
    kpiFields() {
      const fields = ['diffMin', 'diffMax']

      if (this.hasSupplyOpti) {
        fields.push('diffSupplyMax')
      }

      return fields
    },
    dataAggregates() {
      const simulationData = this.simulationData
      const baseData = this.baseData
      if (baseData.length <= 1 || simulationData.length <= 1) {
        return undefined
      }

      let diffSupplyMax;
      let diffMin;
      let diffMax;

      for (let i = 0; i < simulationData.length; i++) {
        const tSupply = Math.max(10, baseData[i]?.T_supply)
        const tSupplyOpti = Math.max(10, simulationData[i]?.T_supply_opti)
        const tReturn = Math.max(10, simulationData[i]?.T_return)

        const diff = Math.abs(tSupply - tReturn)
        const diffSupply = tSupply - tSupplyOpti
        if (diffMin === undefined || diff < diffMin) diffMin = diff
        if (diffMax === undefined || diff > diffMax) diffMax = diff
        if (diffSupplyMax === undefined || diffSupply > diffSupplyMax) diffSupplyMax = diffSupply
      }

      return {
        diffMin: diffMin.toFixed(2),
        diffMax: diffMax.toFixed(2),
        diffSupplyMax: this.hasSupplyOpti ? diffSupplyMax.toFixed(2) : undefined
      }
    },
    dataAggregatesSettings() {
      return {
        diffMin: {
          label: this.$t("panels.plant.data_fields.T_diff_min"),
          unit: '°C',
          color: '#25b7ab',
          textColor: 'white'
        },
        diffMax: {
          label: this.$t("panels.plant.data_fields.T_diff_max"),
          unit: '°C',
          color: '#25b7ab',
          textColor: 'white'
        },
        diffSupplyMax: {
          label: this.$t("panels.plant.data_fields.T_diff_supply_max"),
          unit: '°C',
          color: '#2566cb',
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        legend: {
          showForSingleSeries: true,
        },
        stroke: {
          curve: "smooth",
          // dashArray: [0, 0, 15, 15],
        },
        // colors: [
        //   "#25b7ab",
        //   process.env.VUE_APP_SECONDARY_COLOR,
        //   "#25b789",
        //   "#ee4e31",
        // ],
        colors: [process.env.VUE_APP_SECONDARY_COLOR, process.env.VUE_APP_PRIMARY_COLOR, '#2566cb'],
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} °C`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    prepareData() {
      const baseDataSets = this.prepareBaseData();
      const simulationDataSets = this.prepareSimulationData();

      const prefixSimulation = "";
      // const prefixBase = this.$t('general.base_data') + ': '

      const chartSeries = [
        // {
        //   name: this.$t("panels.plant.data_fields.T_return"),
        //   data: baseDataSets.dataReturn,
        // },
        {
          name: this.$t("panels.plant.data_fields.T_supply"),
          data: baseDataSets.dataSupply,
        },
        {
          name: prefixSimulation + this.$t("panels.plant.data_fields.T_return"),
          data: simulationDataSets.dataReturn,
        },
        // {
        //   name: prefixSimulation + this.$t("panels.plant.data_fields.T_supply"),
        //   data: simulationDataSets.dataSupply,
        // },
      ];

      if (this.hasSupplyOpti) {
        chartSeries.push({
          name: this.$t("panels.plant.data_fields.T_supply_opti"),
          data: simulationDataSets.dataSupplyOpti,
        })
      }

      this.chartSeries = chartSeries
    },
    prepareBaseData() {
      const dataReturn = [];
      const dataSupply = this.prepareNormalizedData(this.baseData, 'T_supply', 10);

      // for (const item of this.baseData) {
      //   const x = item.timestamp;
      //   // dataReturn.push({
      //   //   x,
      //   //   y: item.T_return,
      //   // });
      //   dataSupply.push({
      //     x,
      //     y: item.T_supply,
      //   });
      // }

      return {
        dataReturn,
        dataSupply,
      };
    },
    prepareSimulationData() {
      const dataReturn = this.prepareNormalizedData(this.simulationData, 'T_return', 10);
      const dataSupplyOpti = this.hasSupplyOpti ? this.prepareNormalizedData(this.simulationData, 'T_supply_opti', 10) : undefined;
      const dataSupply = [];

      // for (const item of this.simulationData) {
      //   const x = item.timestamp;
      //   dataReturn.push({
      //     x,
      //     y: item.T_return,
      //   });
      //   // dataSupply.push({
      //   //   x,
      //   //   y: item.T_supply,
      //   // });
      // }

      return {
        dataReturn,
        dataSupplyOpti,
        dataSupply,
      };
    },
    tooltipFormatY(value) {
      return `${value?.toFixed(2)} °C`;
    },
  },
};
</script>
