import { transform, transformExtent } from "ol/proj";
import ScaleLine from "ol/control/ScaleLine";
import MousePosition from "ol/control/MousePosition";
import { defaults as DefaultControls, ZoomSlider } from "ol/control";
// import { createStringXY } from 'ol/coordinate'
import { format } from "ol/coordinate";

export default {
  transformCoordinates(
    coordinates,
    source = "EPSG:4326",
    destination = "EPSG:3857"
  ) {
    return transform(coordinates, source, destination);
  },
  transformExtent(extent, source = "EPSG:4326", destination = "EPSG:3857") {
    return transformExtent(extent, source, destination);
  },
  defaultControls() {
    const scaleLine = new ScaleLine();

    const controls = DefaultControls();

    const zoomSlider = new ZoomSlider();

    controls.extend([scaleLine, zoomSlider]);

    return controls;
  },
  displayCoordinates(coordinate, digits = 6) {
    return format(coordinate, "{y}, {x}", digits);
  },
  mousePositionControl(config = {}) {
    const mousePosition = new MousePosition({
      coordinateFormat: (coordinate) => {
        // return format(coordinate, "{y}, {x}", config?.digits ?? 6);
        return this.displayCoordinates(coordinate, config?.digits ?? 6);
      },
      projection: config?.projection ?? "EPSG:4326",
    });

    return mousePosition;
  },
};
