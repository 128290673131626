<template>
  <div>
    <v-tabs
      v-model="tab"
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <component v-bind:is="currentTab.component"/>
  </div>
</template>

<script>
import PlantMapPopupGeneral from './components/PlantMapPopupGeneral.vue'
import PlantMapPopupPressure from './components/PlantMapPopupPressure.vue'
import PlantMapPopupTemperature from './components/PlantMapPopupTemperature.vue'
import PlantMapPopupEnergy from './components/PlantMapPopupEnergy.vue'
import PlantMapPopupMass from './components/PlantMapPopupMass.vue'

export default {
  name: 'PlantMapPopup',
  components: {
    PlantMapPopupGeneral,
    PlantMapPopupPressure,
    PlantMapPopupTemperature,
    PlantMapPopupEnergy
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    tabs () {
      return [
        {text: this.$t('general.general'), component: PlantMapPopupGeneral},
        {text: this.$t('panels.substation.tab_mass'), component: PlantMapPopupMass},
        {text: this.$t('panels.substation.tab_pressure'), component: PlantMapPopupPressure},
        {text: this.$t('panels.substation.tab_temperature'), component: PlantMapPopupTemperature},
        {text: this.$t('panels.substation.tab_energy'), component: PlantMapPopupEnergy}
      ]
    },
    currentTab () {
      return this.tabs[this.tab]
    },
    date () {
      return this.$store.getters['mapPopup/date']
    },
    date_end () {
      return this.$store.getters['mapPopup/date_end']
    }
  },
  watch: {
    date () {
      this.loadData()
    },
    date_end () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('mapPopup/loadData')
    }
  }
}
</script>

