import DataService from '../../services/DataService'

// initial state
const getDefaultState = () => {
    return {
        loading: false,
        networkData: [],
        open: false
    }
}

const state = getDefaultState()

// getters
const getters = {
    loading: state => state.loading,
    networkData: state => state.networkData,
    open: state => state.open,
}
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    open({ commit }, payload) {
        commit('open', payload)
    },
    close({ commit }) {
        commit('resetState')
    },
    async loadNetworkData({ commit }) {
        commit('loading', true);
        try {
            const networkDataResponse = await DataService.index();
            // console.log(networkDataResponse);
            const nwData = networkDataResponse.data;
            // console.log(nwData);
            commit('networkData', nwData);
            // const opts = {
            //     suggestedName: "heatingrid.zip",
            //     types: [
            //       {
            //         description: "zip archive",
            //         accept: { "application/zip": [".zip"] },
            //       },
            //     ],
            //   };
            try {
                // const fileHandle = await window.showSaveFilePicker(opts);
                // const fileStream = await fileHandle.createWritable();
                // await fileStream.write(new Blob([nwData], {type: 'application/zip'}));
                // await fileStream.close();

                let exportName = "heatingrid.zip"
                let dataBlob = new Blob([(nwData)], { type: "application/zip" });

                var downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", window.URL.createObjectURL(dataBlob));
                downloadAnchorNode.setAttribute("download", exportName);
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            } catch (error) {
                // console.log("aborted");
                console.log(error);
            }

        } catch (e) {
            console.error(e);
            commit('data', {});
        } finally {
            commit('loading', false);
        }
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    loading(state, loading) {
        state.loading = loading;
    },
    open(state) {
        state.open = true;
    },
    networkData(state, data) {
        state.networkData = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}