import Api from './Api'

export default {
  benefits (payload = {}) {
    return Api().get('/benefits', {
      params: {
        date_from: payload.dateFrom,
        date_to: payload.dateTo
      }
    })
  }
}
