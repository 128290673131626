import Api from './Api'

/* eslint-disable */
function fakeResult () {
  return new Promise((resolve) => setTimeout(() => {
    resolve({
      data: {
        location: {
          lon: 15.3995 + (Math.random() * 0.0001),
          lat: 47.0611 + (Math.random() * 0.0001)
        },
        count: 1
      }
    })
  }, 2000))
}
/* eslint-enable */

export default {
  geocode (q) {
    return Api().get('geocode', {
      params: {
        q
      }
    })
  }
}
