import Api from './Api'

export default {
  index () {
    return Api('zip').get('network/download')
  },
  uploadNetwork (formData) {
    return Api('file').post('/network/upload', formData)
  },
  uploadTimeSeries (formData) {
    return Api('file').post('/timeseries/upload', formData)
  },
}
