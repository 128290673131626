<template>
  <div style="margin: 4px -4px; display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))">
    <template v-for="item in kpi">
      <v-chip label :key="item.key" class="ma-1 black--text" color="accent">
        <b>{{ $t('panels.energyPlanner.benefits.kpi.' + item.key) }}:</b><span class="mx-1">{{ kpiValue(item.key) }}</span>{{ item.unit }}
      </v-chip>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'BenefitsTotalKpiSmall',
    computed: {
      community () {
        return this.$store.getters['energyPlanner/benefitsDataCommunity']
      }
    },
    data () {
      return {
        kpi: [
          { key: "total_power_cons", unit: 'MWh p.a.' },
          { key: "total_prod", unit: 'MWh p.a.' },
          { key: "sum_self_cons", unit: 'MWh p.a.' },
          { key: "self_cons1_y", unit: 'MWh p.a.' },
          { key: "self_cons2plus3_y", unit: 'MWh p.a.' },
          { key: "grid_cons", unit: 'MWh p.a.' },
          { key: "pv_surplus", unit: 'MWh p.a.' },
        ]
      }
    },
    methods: {
      kpiValue (key) {
        const value = this.community.kpis[key]
        if (value === undefined) return '-'

        return value.toFixed(2)
      }
    }
  }
</script>
