<template>
  <div>
    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <div class="d-flex align-center mb-2" style="margin-top: -12px;">
      <div>
        <mass-flow-switch v-model="showCubicMeters" />
      </div>
      <div class="d-flex flex-grow-1 align-center justify-center flex-wrap" style="gap: 4px;" v-if="dataAggregates">
        <v-chip v-for="field in ['min', 'max']" :key="field" :color="dataAggregatesSettings[field].color" label
          :style="{ color: dataAggregatesSettings[field].textColor }">
          {{ dataAggregatesSettings[field].label }}: {{ cubicMetersOrKilos(dataAggregates[field])?.toFixed(2) }} {{
            dataAggregatesSettings[field].unit }}
        </v-chip>
      </div>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.base_data && data.base_data.length < 1
      && data.simulation_data && data.simulation_data.length < 1
      ">{{ $t("general.no_data") }}</v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" ref="chart" />
  </div>
</template>

<script>
import MassFlowSwitch from "@/views/misc/MassFlowSwitch.vue";
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker, MassFlowSwitch },
  name: "PlantMapPopupMass",
  data() {
    return {
      chartSeries: [],
      showCubicMeters: true
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    unitTranslationSuffix() {
      return this.showCubicMeters ? 'm3' : 'kg'
    },
    displayedUnit() {
      return this.showCubicMeters ? 'm³/h' : 'kg/s'
    },
    dataAggregates () {
      const data = this.baseData
      // no point in computing min/max
      if (data.length <= 1) {
        return undefined
      }

      let min;
      let max;

      for (let i = 0; i < data.length; i++) {
        const y = data[i].mass_flow
        if (min === undefined || y < min) min = y
        if (max === undefined || y > max) max = y
      }

      return {
        min,
        max
      }
    },
    dataAggregatesSettings() {
      return {
        min: {
          label: this.$t(`panels.plant.data_fields.mass_flow_${this.unitTranslationSuffix}_min`),
          unit: this.displayedUnit,
          color: '#8822CC',
          textColor: 'white'
        },
        max: {
          label: this.$t(`panels.plant.data_fields.mass_flow_${this.unitTranslationSuffix}_max`),
          unit: this.displayedUnit,
          color: "#8822CC",
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        legend: {
          showForSingleSeries: true,
        },
        colors: ["#8822CC"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: this.tooltipFormatY
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
    showCubicMeters() {
      this.changeUnits()
    }
  },
  methods: {
    changeUnits() {
      // update chart series names
      this.chartSeries[0].name = this.$t(`panels.plant.data_fields.mass_flow_${this.unitTranslationSuffix}`)

      this.$refs.chart.refresh()
    },
    prepareData() {
      const baseDataSets = this.prepareBaseData();

      this.chartSeries = [
        {
          name: this.$t(`panels.plant.data_fields.mass_flow_${this.unitTranslationSuffix}`),
          data: baseDataSets.dataMassFlow,
        },
        // {
        //   name: this.$t('panels.plant.data_fields.power_supply'),
        //   data: baseDataSets.dataSupply
        // },
      ];
    },
    prepareBaseData() {
      const dataReturn = [];
      const dataMassFlow = this.prepareNormalizedData(this.baseData, 'mass_flow');

      return {
        dataReturn,
        dataMassFlow,
      };
    },
    cubicMetersOrKilos(value) {
      if (!value) return value

      return (this.showCubicMeters ? value * 3.6 : value)
    },
    tooltipFormatY(value) {
      const unit = this.displayedUnit
      return `${this.cubicMetersOrKilos(value)?.toFixed(2)} ${unit}`;
    },
  },
};
</script>
