import SimulationService from '../../services/SimulationService'

// initial state
const getDefaultState = () => {
    return {
        loading: false,
        simulations: [],
        open: false,
        simulationData: null
    }
}

const state = getDefaultState()

// getters
const getters = {
    loading: state => state.loading,
    simulations: state => state.simulations,
    simulationData: state => state.simulationData,
    open: state => state.open,
}
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    open({ commit }, payload) {
        commit('open', payload)
    },
    close({ commit }) {
        // commit('resetState');
        commit('close');
    },
    async startSimulation({ commit }, simTimes) {
        commit('loading', true);
        // console.log(simTimes);
        try {
            await SimulationService.start(simTimes.start, simTimes.end);
            // const simulationsResponse = await SimulationService.start(simTimes.start, simTimes.end);
            // console.log(simulationsResponse);
            // const simulationData = simulationsResponse.data;
            // console.log(simulationData);
            // commit('simulations', simulationData);
        } catch (e) {
            console.error(e);
            // commit('data', {});
        }


        commit('loading', false);
    },
    async loadSimulations({ commit }) {
        commit('loading', true);
        try {
            const simulationsResponse = await SimulationService.index();
            const simulationData = simulationsResponse.data;
            // console.log(simulationData);
            commit('simulations', simulationData);
        } catch (e) {
            console.error(e);
            commit('data', {});
        }

        commit('loading', false);
    },
    async updateSimulationState({ commit }, simulationId) {
        // commit('loading', true)
        try {
            const response = await SimulationService.updateSimulationState(simulationId)
            commit('updateSimulation', response.data.simulation)
        } catch (e) {
            console.error(e)
        }
        // commit('loading', false)
    },
    async downloadSimulation({ commit }, simulationId) {
        try {
            commit('loading', true);
            const simulationDataResponse = await SimulationService.downloadSimulation(simulationId);
            commit('setSimulationData', simulationDataResponse.data);
        } finally {
            commit('loading', false);

        }
    },
    async saveSimulationData({ commit }, simulationId) {
        // let fileStream = null;
        try {
            const simData = state.simulationData;
            // console.log(simData);
            // const opts = {
            //     suggestedName: `simulation_${simulationId}_data.zip`,
            //     types: [
            //         {
            //             description: "zip archive",
            //             accept: { "application/zip": [".zip"] },
            //         },
            //     ],
            // };
            try {
                // const fileHandle = await window.showSaveFilePicker(opts);
                // fileStream = await fileHandle.createWritable();
                // await fileStream.write(
                //     new Blob([simData], { type: 'application/zip' })
                // );
                // await fileStream.close();
                const exportName = `simulation_${simulationId}_data.zip`
                const dataBlob = new Blob([(simData)], { type: "application/zip" });

                var downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", window.URL.createObjectURL(dataBlob));
                downloadAnchorNode.setAttribute("download", exportName);
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            } catch (error) {
                // console.log("aborted");
                console.log(error);
            }
        } finally {
            commit('loading', false);
            // fileStream.close();
        }
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    loading(state, loading) {
        state.loading = loading;
    },
    open(state) {
        state.open = true;
    },
    close(state) {
        state.open = false;
    },
    simulations(state, data) {
        state.simulations = data;
    },
    updateSimulation(state, simulation) {
        const simulations = state.simulations
        const index = simulations.findIndex(item => item.id === simulation.id)
        if (index !== -1) {
            simulations[index] = simulation
        }

        state.simulations = [...simulations]
    },
    setSimulationData(state, simData) {
        // console.log("saving to state");
        // console.log(simData);
        // console.log(state.simulationData);
        state.simulationData = simData;
        // console.log(state);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
