<template>
  <div>
    <portal to="energy-planner-fixed">
      <div class="mb-4">
        <v-btn color="accent" @click="() => openEdit()">
          {{ $t("panels.energyPlanner.buildings.new_building") }}
        </v-btn>
      </div>
      <SearchAddress />
    </portal>
    <v-divider />
    <v-card-text>
      <v-data-table :items="buildings" :headers="headers" single-expand>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div class="d-flex justify-space-between">
                <div>
                  {{ item.name }}
                  <v-btn icon @click="() => openEdit(item)" :title="$t('general.edit')"
                    color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
                </div>
                <div class="d-flex justify-end">
                  <v-btn v-if="item.geom" icon @click="() => fitBuilding(item.id)"
                    :title="$t('panels.energyPlanner.find_in_map')" color="accent">
                    <v-icon>mdi-map-search</v-icon>
                  </v-btn>
                  <v-btn icon :outlined="
                    clickStateQueryBuilding && clickOptions.id === item.id
                  " @click="() => queryPoint(item)" :title="$t('panels.energyPlanner.buildings.query_point')" color="accent">
                    <v-icon>mdi-map-marker-plus</v-icon>
                  </v-btn>
                  <v-btn icon :outlined="
                    clickStateDrawBuilding && clickOptions.id === item.id
                  " @click="() => drawBuilding(item)" :title="$t('panels.energyPlanner.buildings.draw_building')"
                    color="accent">
                    <v-icon>mdi-draw</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => viewUnits(item)" color="accent">
                    <v-icon>mdi-home-group</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => openDelete(item)" :title="$t('general.delete')"
                    color="error"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </td>
            <td class="text-right" :style="{ width: '1%' }">
              {{ buildingUnitsCount(item) }}
            </td>
          </tr>
          <tr v-if="buildingQueryActive(item)">
            <td colspan="2">
              <p>{{ $t("panels.energyPlanner.buildings.query_building_hint") }}</p>
              <v-text-field full-width dense :label="$t('general.address')" append-outer-icon="mdi-magnify"
                @click:append-outer="queryAddress" :loading="loading" :disabled="loading" v-model="address"
                persistent-hint :hint="$t('panels.energyPlanner.query_address_hint')" :error-messages="
                  panelErrors && panelErrors.buildingQueryAddress
                    ? panelErrors.buildingQueryAddress
                    : ''
                " />
              <template v-if="queryDone">
                <div class="my-3">
                  <p v-if="hasBuildingQueryItem">
                    {{ $t("panels.energyPlanner.query_items_found") }}<br />{{
                      queryItemName(buildingQueryItem)
                    }}
                  </p>
                  <p v-else-if="buildingQueryPoint">
                    {{ $t("panels.energyPlanner.save_query_point") }}
                  </p>
                </div>
                <div class="mb-3">
                  <v-btn v-if="hasBuildingQueryItem || buildingQueryPoint" small @click="() => saveQueryResult()"
                    :title="$t('general.save')" color="success" class="mr-1" :disabled="loading">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn @click="cancelQueryPoint" small color="error">{{
                    $t("general.cancel")
                  }}</v-btn>
                </div>
                <v-alert class="mb-3" v-if="panelErrors && panelErrors.geometry" type="error">
                  {{ panelErrors.geometry[0] }}
                </v-alert>
                <v-alert class="mb-3" v-if="panelErrors && panelErrors.buildingQueryPoint" type="error">
                  {{ panelErrors.buildingQueryPoint }}
                </v-alert>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <building-edit-dialog v-if="editOpen" :item="editItem" @close="closeEdit" />
    <building-delete-dialog v-if="deleteOpen" :item="deleteItem" @close="closeDelete" />
  </div>
</template>

<script>
import { clickStates } from '@/util/structs'
import BuildingDeleteDialog from './BuildingDeleteDialog.vue'
import BuildingEditDialog from './BuildingEditDialog.vue'
import SearchAddress from '../common/SearchAddress.vue'

export default {
  name: 'Buildings',
  components: {
    BuildingEditDialog,
    BuildingDeleteDialog,
    SearchAddress
},
  data() {
    return {
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      address: ''
    }
  },
  computed: {
    clickStates() {
      return clickStates
    },
    clickState() {
      return this.$store.getters['map/clickState']
    },
    clickOptions() {
      return this.$store.getters['map/clickOptions']
    },
    clickStateQueryBuilding() {
      return this.clickState === clickStates.BUILDING_QUERY
    },
    clickStateDrawBuilding() {
      return this.clickState === clickStates.BUILDING_DRAW
    },
    buildingQueryItem() {
      return this.$store.getters['energyPlanner/building/buildingQueryItem']
    },
    hasBuildingQueryItem() {
      return this.buildingQueryItem !== undefined
    },
    buildingQueryPoint() {
      return this.$store.getters['energyPlanner/building/buildingQueryPoint']
    },
    buildings() {
      return this.$store.getters['energyPlanner/building/buildings']
    },
    loading() {
      return this.$store.getters['energyPlanner/loading']
    },
    queryDone() {
      return this.$store.getters['energyPlanner/building/queryDone']
    },
    panelErrors() {
      return this.$store.getters['energyPlanner/panelErrors']
    },
    mapComponent() {
      return this.$store.getters['map/component']
    },
    headers() {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('panels.energyPlanner.buildings.units'),
          value: 'units.length',
          align: 'end'
        }
      ]
    }
  },
  watch: {
    buildingQueryItem(value) {
      if (value !== undefined && this.overpassItemHasAddress(value)) {
        this.address = this.queryItemName(value)
      }
    },
    clickState(value) {
      if (value === clickStates.IDLE) {
        this.address = ''
      }
    },
  },
  methods: {
    overpassItemHasAddress(item) {
      return !(!item.tags['addr:street'] || !item.tags['addr:housenumber'])
    },
    buildingUnitsCount(building) {
      return building.units.length ?? 0
    },
    refreshBuildings() {
      this.$store.dispatch('energyPlanner/building/loadData')
    },
    viewUnits(building) {
      this.$store.dispatch('energyPlanner/building/viewUnits', building.id)
    },
    queryItemName(queryItem) {
      if (!this.overpassItemHasAddress(queryItem)) {
        return this.$t('panels.energyPlanner.no_address_data')
      }

      return `${queryItem.tags['addr:street']} ${queryItem.tags['addr:housenumber']}`
    },
    closeEdit() {
      this.editOpen = false
      this.editItem = undefined
    },
    openEdit(item) {
      this.editItem = item ? { ...item } : undefined
      this.editOpen = true
    },
    openDelete(item) {
      this.deleteItem = item
      this.deleteOpen = true
    },
    closeDelete() {
      this.deleteOpen = false
      this.deleteItem = undefined
    },
    resetClickState() {
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      })
    },
    buildingQueryActive(item) {
      return this.clickStateQueryBuilding && this.clickOptions.id === item.id
    },
    drawBuildingActive(item) {
      return this.clickStateDrawBuilding && this.clickOptions.id === item.id
    },
    queryPoint(item) {
      if (this.buildingQueryActive(item)) {
        this.cancelQueryPoint()
        return
      }

      this.$store.dispatch('energyPlanner/building/initQueryBuilding', {
        id: item.id
      })
    },
    drawBuilding(item) {
      if (this.drawBuildingActive(item)) {
        this.resetClickState()
        return
      }

      if (this.buildingQueryActive(item)) {
        this.cancelQueryPoint()
      }

      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.BUILDING_DRAW,
        clickOptions: { id: item.id }
      })
    },
    saveQueryResult() {
      if (this.hasBuildingQueryItem) {
        this.$store.dispatch('energyPlanner/building/saveQueryItem', {
          id: this.clickOptions.id,
          featureId: this.buildingQueryItem.id
        })
      } else if (this.buildingQueryPoint) {
        this.$store.dispatch('energyPlanner/building/saveQueryPoint', {
          id: this.clickOptions.id
        })
      }
    },
    fitBuilding(id) {
      this.fitFeature(id, 'buildings')
    },
    fitQueryItem(id) {
      this.fitFeature(id ?? 'QueryPoint', 'drawing')
    },
    fitFeature(id, layer) {
      if (!this.mapComponent) return

      this.mapComponent.locateFeature(layer, id)
    },
    cancelQueryPoint() {
      this.$store.dispatch('energyPlanner/building/cancelQueryPoint')
    },
    queryAddress() {
      this.$store.dispatch('energyPlanner/building/buildingQueryAddress', { address: this.address })
    }
  }
}
</script>
