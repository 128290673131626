<template>
  <v-list expand dense nav class="d-flex flex-column justify-space-between" style="flex: auto">
    <div>
      <template>
        <v-list-group :value="true" prepend-icon="mdi-home-group">
          <template v-slot:activator>
            <v-list-item-title>{{
              $t("nav.infrastructure")
            }}</v-list-item-title>
          </template>
          <v-list-item @click="() => togglePanel('plant')" v-if="heatingNetworkEnabled">
            <v-list-item-title>{{ $t("nav.plant") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="() => togglePanel('substation')" v-if="heatingNetworkEnabled">
            <v-list-item-title>{{ $t("nav.substation") }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" prepend-icon="mdi-relation-one-to-one" v-if="heatingNetworkEnabled">
          <template v-slot:activator>
            <v-list-item-title>{{ $t("nav.network") }}</v-list-item-title>
          </template>
          <v-list-item @click="() => togglePanel('edge')">
            <v-list-item-title>{{ $t("nav.edge") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="() => togglePanel('node')">
            <v-list-item-title>{{ $t("nav.node") }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>

      <v-list-item @click="() => togglePanel('analysis')">
        <v-list-item-icon><v-icon class="primary--text" small>mdi-chart-line</v-icon></v-list-item-icon>
        <v-list-item-title class="primary--text">{{ $t("nav.analysis") }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="eccEnabled" @click="() => togglePanel('energyPlanner')">
        <v-list-item-icon><v-icon class="accent--text" small>mdi-home-city</v-icon></v-list-item-icon>
        <v-list-item-title class="accent--text">{{ $t("nav.energyPlanner") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="() => togglePanel('settings')">
        <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
        <v-list-item-title>{{ $t("nav.settings") }}</v-list-item-title>
      </v-list-item>
    </div>
    <div>
      <v-list-item @click="openData" style="background-color: #e66e31; width: 100%">
        <v-list-item-icon>
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("data.title") }}</v-list-item-title>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  name: "NavigationList",
  data: () => ({}),
  computed: {
    activePanel() {
      return this.$store.getters.activePanel;
    },
    userConfig() {
      return this.$store.getters["account/config"];
    },
    eccEnabled() {
      return this.userConfig.ecc_enabled ?? false;
    },
    heatingNetworkEnabled() {
      return this.userConfig.heating_network_enabled ?? false;
    },
  },
  methods: {
    togglePanel(panel) {
      this.$store.dispatch(
        "setActivePanel",
        panel !== this.activePanel ? panel : false
      );
    },
    openData() {
      this.$store.dispatch("dataPopup/open");
    },
  },
};
</script>
