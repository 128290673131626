<template>
  <div>
    <v-dialog
      persistent
      ref="dialog"
      v-model="modal"
      width="700px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :clearable="clearable"
          :label="label"
          :readonly="readonly"
          :value="formattedValue"
          :disabled="disabled"
          append-outer-icon="mdi-calendar-clock"
          @input="handleTextInput"
          @click:clear="clear"
          @click:append-outer="on.click"
          :rules="[validateTextInput].concat(rules)"
          :error-messages="errormessages"
          :dense="dense"
        ></v-text-field>
      </template>
      <v-card class="date-time-picker-card">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-date-picker :min="minDate" :max="maxDate" v-model="date" locale="de" first-day-of-week="1"></v-date-picker>
              </v-col>
              <v-col>
                <v-time-picker :use-seconds="useSeconds" :min="minTime" :max="maxTime" scrollable v-model="time" format="24hr"></v-time-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn @click="cancel">Abbrechen</v-btn>
            <v-btn @click="save" :disabled="!valid">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DateTimePicker',
  props: {
    value: String,
    label: String,
    useSeconds: Boolean,
    dense: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: String,
    max: String,
    errormessages: {
      type: Array,
      default: function () {
        return []
      }
    },
    rules: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      modal: false,
      date: undefined,
      time: undefined
    }
  },
  computed: {
    userLocale () {
      return this.$store.getters['account/userLocale']
    },
    formattedValue () {
      const parsedValue = this.$moment(this.value)

      return parsedValue.isValid() ? parsedValue.format(this.userLocale === 'de' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm') : this.value
    },
    valid () {
      return this.date !== undefined && this.time !== undefined
    },
    minDate() {
      const minDT = this.$moment(this.min)
      return minDT.isValid() ? minDT.format('YYYY-MM-DD') : undefined
    },
    minTime() {
      if (this.date === undefined || !this.$moment().isSame(this.date, 'day')) {
        return undefined
      }

      const minDT = this.$moment(this.min)

      return minDT.isValid() ? minDT.format('HH:mm') : undefined
    },
    maxDate () {
      const maxDT = this.$moment(this.max)
      return maxDT.isValid() ? maxDT.format('YYYY-MM-DD') : undefined
    },
    maxTime () {
      if (this.date === undefined || !this.$moment().isSame(this.date, 'day')) {
        return undefined
      }

      const maxDT = this.$moment(this.max)

      return maxDT.isValid() ? maxDT.format('HH:mm') : undefined
    }
  },
  methods: {
    validateTextInput (value) {
      if (this.max !== undefined) {
        if (this.$moment(this.max).isBefore(value)) {
          return `Ungültiges Datum. Datum/Zeit muss vor ${this.max} liegen.`
        }
      }
      return true
    },
    handleTextInput (value) {
      const dt = this.$moment(value, ['DD.MM.YYYY HH:mm','YYYY-MM-DD HH:mm'])
      if (dt.isValid()) {
        this.$emit('input', dt.format('YYYY-MM-DD HH:mm'))
        this.date = dt.format('YYYY-MM-DD')
        this.time = dt.format('HH:mm')
      }
    },
    reset () {
      if (this.value) {
        const dt = this.$moment(this.value)
        this.date = dt.format('YYYY-MM-DD')
        this.time = dt.format('HH:mm')
      } else {
        this.date = undefined
        this.time = undefined
      }
    },
    cancel () {
      this.modal = false
    },
    clear () {
      this.$emit('input', undefined)
    },
    save () {
      if (this.valid) {
        this.$emit('input', `${this.date} ${this.time}`)
      }

      this.modal = false
    }
  },
  watch: {
    modal (open) {
      if (open) {
        this.reset()
      }
    }
  }
}
</script>
