<template>
  <v-dialog :value="open" :max-width="maxWidth" @input="close">
    <v-card width="100%">
      <v-card-title>
        <template>Data Management</template>
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div class="d-flex justify-center flex-wrap width:100%">
        <v-card class="ma-2" width="45%">
          <v-card-title>{{ $t("popups.datapopup.help.title") }}</v-card-title>
          <v-card-text>{{ $t("popups.datapopup.help.description") }}</v-card-text>
          <v-divider class="mx-2"></v-divider>
          <v-card-actions class="d-flex flex-wrap justify-center">
            <v-btn color="primary" class="ma-5" @click="saveInstructions">
              {{ $t("popups.datapopup.help.btnInstructions") }}
            </v-btn>
            <v-btn color="primary" class="ma-5" @click="saveExampleDataNetwork">
              {{ $t("popups.datapopup.help.btnNetwork") }}
            </v-btn>
            <v-btn color="primary" class="ma-5" @click="saveExampleDataSeries">
              {{ $t("popups.datapopup.help.btnSeries") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" width="45%">
          <v-card-title>{{ $t("popups.datapopup.download.title") }}</v-card-title>
          <v-card-text>{{ $t("popups.datapopup.download.description") }}</v-card-text>
          <v-divider class="mx-2"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" class="ma-5" @click="loadNetworkData"
              ><v-icon>mdi-download-network-outline</v-icon>
              {{ $t("popups.datapopup.download.btnText") }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- <v-card class="ma-2" width="45%">
          <v-card-title>Download Network as JSON</v-card-title>
          <v-card-text>Description goes here</v-card-text>
          <v-divider class="mx-2"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="#25b7ab" class="ma-5" @click="saveLocalNetwork"> SAVE JSON </v-btn>
          </v-card-actions>
        </v-card> -->
        <v-card class="ma-2" width="45%">
          <v-card-title>{{ $t("popups.datapopup.upload.title") }}</v-card-title>
          <v-card-text>{{ $t("popups.datapopup.upload.description") }}</v-card-text>
          <v-divider class="mx-2"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <input type="file" @change="networkFileSelected($event)" />
            <v-btn color="primary" class="ma-5" @click="uploadNetwork" :disabled="!nwFileSelected" :loading="nwUploadRunning">
              {{ $t("popups.datapopup.upload.btnNetwork") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" width="45%">
          <v-card-title>{{ $t("popups.datapopup.upload.titleSeries") }}</v-card-title>
          <v-card-text>{{ $t("popups.datapopup.upload.descriptionSeries") }}</v-card-text>
          <v-divider class="mx-2"></v-divider>
          <v-card-actions class="d-flex justify-center">
            <input type="file" @change="timeseriesFileSelected($event)" />
            <v-btn color="primary" class="ma-5" @click="uploadTimeseries" :disabled="!tsFileSelected" :loading="tsUploadRunning">
              {{ $t("popups.datapopup.upload.btnSeries") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="ma-5" v-if="usergroup == 1">
          <v-btn @click="openFindGeometries">
            {{ $t("popups.datapopup.findGeometries") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DataService from "../../services/DataService";
export default {
  name: "DataPopup",
  components: {},
  data() {
    return {
      networkFile: null,
      timeseriesFile: null,
      nwUploading: false,
      tsUploading: false,
    };
  },
  computed: {
    open() {
      return this.$store.getters["dataPopup/open"];
    },
    usergroup() {
      const group = this.$store.getters["account/userGroup"];
      // console.log(group)
      return group;
    },
    maxWidth() {
      if (this.width !== undefined) {
        return this.width;
      }
      return "100%";
    },
    nwFileSelected() {
      return this.networkFile != null;
    },
    tsFileSelected() {
      return this.timeseriesFile != null;
    },
    nwUploadRunning() {
      return this.nwUploading;
    },
    tsUploadRunning() {
      return this.tsUploading;
    },
  },
  methods: {
    onWidth(value) {
      this.width = value;
    },
    close() {
      this.$store.dispatch("dataPopup/close");
    },
    loadNetworkData() {
      this.$store.dispatch("dataPopup/loadNetworkData");
    },
    saveInstructions() {
      window.open(process.env.VUE_APP_API_URL + "examples/arteria_guide.pdf", "_blank", "noreferrer");
    },
    saveExampleDataNetwork() {
      window.open(process.env.VUE_APP_API_URL + "examples/sample_network.zip", "_blank", "noreferrer");
    },
    saveExampleDataSeries() {
      window.open(process.env.VUE_APP_API_URL + "examples/sample_timeseries.zip", "_blank", "noreferrer");
    },
    openFindGeometries() {
      this.$store.dispatch("findGeometriesPopup/open");
    },
    async uploadNetwork() {
      this.nwUploading = true;
      let formData = new FormData();
      formData.append("file", this.networkFile);
      const result = await DataService.uploadNetwork(formData);
      console.log(result.data); //TODO display this in a global banner
      this.nwUploading = false;
    },
    async uploadTimeseries() {
      this.tsUploading = true;
      let formData = new FormData();
      formData.append("file", this.timeseriesFile);
      const result = await DataService.uploadTimeSeries(formData);
      console.log(result.data); //TODO display this in a global banner
      this.tsUploading = false;
    },
    networkFileSelected(event) {
      this.networkFile = event.target.files[0];
    },
    timeseriesFileSelected(event) {
      this.timeseriesFile = event.target.files[0];
    },
    async saveLocalNetwork() {
      const nodes = this.$store.getters["node/items"];
      const edges = this.$store.getters["edge/items"];
      const substations = this.$store.getters["substation/items"];
      const plants = this.$store.getters["plant/items"];

      const nwJson = {
        nodes: nodes,
        edges: edges,
        substations: substations,
        plants: plants,
      };

      // const opts = {
      //   suggestedName: "heatingrid.json",
      //   types: [
      //     {
      //       description: "json file",
      //       accept: { "application/json": [".json"] },
      //     },
      //   ],
      // };
      try {
        // const fileHandle = await window.showSaveFilePicker(opts);
        // const fileStream = await fileHandle.createWritable();
        // await fileStream.write(new Blob([JSON.stringify(nwJson)], { type: "application/json" }));
        // await fileStream.close();
        let exportName = "heatingrid.json"
        let dataStr = new Blob([JSON.stringify(nwJson)], { type: "application/json" });

        var downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      } catch (error) {
        // console.log("aborted");
        console.log(error);
      }
    },
  },
};
</script>
