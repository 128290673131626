<template>
  <div :style="mapContainerStyle">
    <v-progress-linear absolute indeterminate :active="loading" />
    <div id="map" style="height: 100%; width: 100%"></div>
    <map-popup />
    <data-popup />
    <simulation-popup />
    <geometry-finder-popup />
  </div>
</template>

<script>
import MapPopup from "./components/MapPopup.vue";
import DataPopup from "../networkdata/DataPopup.vue";
import SimulationPopup from "../simulation/SimulationPopup.vue";
import GeometryFinderPopup from "../tools/geometryfinder/GeometryFinderPopup.vue"
import OlMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import BingMaps from "ol/source/BingMaps";
import XYZSource from "ol/source/XYZ";
import OlUtil from "@/util/OlUtil";
import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { Fill, Stroke, Style, Circle, Text } from "ol/style";
import * as OlColor from "ol/color";
import colors from "vuetify/lib/util/colors";
import { clickStates, featureTypes } from "../../util/structs";
import { Control } from "ol/control";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { pointerMove, singleClick } from "ol/events/condition";
import { Draw, Select } from "ol/interaction";
import Polygon from 'ol/geom/Polygon';
import OverpassApiService from '../../services/OverpassApiService';
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4.js';

register(proj4);

const geojsonParser = new GeoJSON({
  featureProjection: "EPSG:3857",
});

const styleCache = {
  fontSizes: new Map(),
  colors: {
    plant: colors.blue.base,
    plantFill: (() => {
      const color = OlColor.fromString(colors.blue.base);
      color[3] = 0.3;
      return color;
    })(),
    substation: colors.orange.base,
    substationFill: (() => {
      const color = OlColor.fromString(colors.orange.base);
      color[3] = 0.3;
      return color;
    })(),
    storage: colors.cyan.base,
    storageFill: (() => {
      const color = OlColor.fromString(colors.cyan.base);
      color[3] = 0.3;
      return color;
    })(),
    building: colors.purple.base,
    buildingFill: (() => {
      const color = OlColor.fromString(colors.purple.base);
      color[3] = 0.3;
      return color;
    })(),
    coolingHouse: colors.brown.base,
    coolingHouseFill: (() => {
      const color = OlColor.fromString(colors.brown.base);
      color[3] = 0.3;
      return color;
    })(),
    field: colors.green.base,
    fieldFill: (() => {
      const color = OlColor.fromString(colors.green.base);
      color[3] = 0.3;
      return color;
    })(),
    drawing: colors.yellow.base,
    drawingFill: (() => {
      const color = OlColor.fromString(colors.yellow.base);
      color[3] = 0.3;
      return color;
    })(),
    supply: process.env.VUE_APP_SECONDARY_COLOR,
    return: process.env.VUE_APP_PRIMARY_COLOR,
    analysisMax: colors.red.base,
    analysisAvg: colors.purple.base,
    supplyLabel: "#aa0000",
    returnLabel: "#115555",
  },
};

const bingImagerySets = {
  bing_aerial: "Aerial",
  bing_aerial_w_labels: "AerialWithLabelsOnDemand",
  bing_road: "RoadOnDemand",
};

export default {
  name: "MapComponent",
  components: {
    MapPopup,
    DataPopup,
    SimulationPopup,
    GeometryFinderPopup,
  },
  data() {
    return {
      map: null,
      mousePositionControl: null,
      loading: false,
      initDone: false,
      hoverActive: false,
      drawing: false,
      saveBtn: null,
      saveCancelControl: null,
      chooseNodeInteraction: null,
      chooseNodeInteractionHover: null,
      idleInteractionHover: null,
      drawInteraction: null,
      mapPopupItem: undefined,
      mapPopupOpen: false,
      mapLayers: {
        ortho: new TileLayer({
          zIndex: 0,
          maxZoom: 19,
          name: "ortho",
        }),
        raw: new TileLayer({
          zIndex: 1,
          visible: false,
          name: "raw",
          opacity: 0.7,
        }),
        supplyEdges: new VectorLayer({
          zIndex: 20,
          source: new VectorSource(),
          style: this.styleEdges,
          maxResolution: 10.0,
          name: "supply_edges"
        }),
        returnEdges: new VectorLayer({
          zIndex: 20,
          source: new VectorSource(),
          style: this.styleEdges,
          maxResolution: 1.0,
          name: "return_edges"
        }),
        supplyNodes: new VectorLayer({
          zIndex: 30,
          source: new VectorSource(),
          style: this.styleNodes,
          maxResolution: 0.5,
          name: "supply_nodes"
        }),
        returnNodes: new VectorLayer({
          zIndex: 30,
          source: new VectorSource(),
          style: this.styleNodes,
          maxResolution: 0.5,
          name: "return_nodes"
        }),
        plants: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.stylePlants,
          name: "plants"
        }),
        substations: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.styleSubstations,
          // maxResolution: 3.0,
          name: "substations"
        }),
        storages: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.styleStorages,
          maxResolution: 1.0,
          name: "storages"
        }),
        buildings: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.styleBuildings,
          name: "buildings"
        }),
        coolingHouses: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.styleCoolingHouses,
          name: "coolingHouses"
        }),
        fields: new VectorLayer({
          zIndex: 10,
          source: new VectorSource(),
          style: this.styleFields,
          name: "fields"
        }),
        drawing: new VectorLayer({
          zIndex: 40,
          source: new VectorSource(),
          style: this.styleDrawing,
          name: "drawing",
        }),
      },
    };
  },
  computed: {
    mapContainerStyle() {
      const style = {
        height: "100%",
        width: "100%",
      };

      if (this.hoverActive || this.drawing) {
        style.cursor = "pointer";
      }

      return style;
    },
    userSettings() {
      return this.$store.getters["account/userSettings"];
    },
    userConfig() {
      return this.$store.getters["account/config"];
    },
    displayedProjection() {
      return this.userConfig?.displayed_projection
    },
    plants() {
      return this.$store.getters["plant/items"];
    },
    plantNodes() {
      const plantNodes = new Set()

      for (const plant of this.plants ?? []) {
        if (plant.supply_node_id) plantNodes.add(plant.supply_node_id)
        if (plant.return_node_id) plantNodes.add(plant.return_node_id)
      }

      return plantNodes
    },
    substations() {
      return this.$store.getters["substation/items"];
    },
    substationNodes() {
      const substationNodes = new Set()

      for (const substation of this.substations ?? []) {
        if (substation.supply_node_id) substationNodes.add(substation.supply_node_id)
        if (substation.return_node_id) substationNodes.add(substation.return_node_id)
      }

      return substationNodes
    },
    storages() {
      return this.$store.getters["storage/items"];
    },
    edges() {
      return this.$store.getters["edge/items"];
    },
    nodes() {
      return this.$store.getters["node/items"];
    },
    buildings() {
      return this.$store.getters["energyPlanner/building/buildings"]
    },
    coolingHouses() {
      return this.$store.getters["energyPlanner/coolingHouse/coolingHouses"]
    },
    fields() {
      return this.$store.getters["energyPlanner/field/fields"]
    },
    nodeMap() {
      return this.$store.getters["node/itemsMapped"];
    },
    activePanel() {
      return this.$store.getters.activePanel;
    },
    activePanelRef() {
      return this.$store.getters.activePanelRef;
    },
    clickState() {
      return this.$store.getters["map/clickState"];
    },
    clickOptions() {
      return this.$store.getters["map/clickOptions"];
    },
    navDrawer() {
      return this.$store.getters.navDrawer;
    },
    analysisEnabled() {
      return this.$store.getters["analysis/hasData"] && this.$store.getters["analysis/enabled"]
    },
    analysisOverwriteLabels() {
      return this.analysisEnabled && this.$store.getters["analysis/overwriteLabels"]
    },
    analysisEdgeDeltas() {
      return this.$store.getters["analysis/edgeDeltas"]
    },
    analysisVisualizationModeUnit() {
      return this.$store.getters["analysis/analysisVisualizationModeUnit"]
    },
    analysisVisualizationThreshold() {
      return this.$store.getters["analysis/analysisVisualizationThreshold"]
    }
  },
  watch: {
    'userSettings.map_source'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initMapSource();
      }
    },
    'userSettings.rawData.enabled'(enabled) {
      this.mapLayers.raw.setVisible(enabled);
    },
    'userSettings.rawData.opacity'(opacity) {
      this.mapLayers.raw.setOpacity(opacity)
    },
    plants() {
      if (!this.initDone) return;
      this.renderPlants();
    },
    storages() {
      if (!this.initDone) return;
      this.renderStorages();
    },
    nodes() {
      if (!this.initDone) return;
      this.renderNodes();
      this.renderEdges();
    },
    substations() {
      if (!this.initDone) return;
      this.renderSubstations();
    },
    edges() {
      if (!this.initDone) return;
      this.renderEdges();
    },
    buildings() {
      if (!this.initDone) return;
      this.renderBuildings();
    },
    coolingHouses() {
      if (!this.initDone) return;
      this.renderCoolingHouses();
    },
    fields() {
      if (!this.initDone) return;
      this.renderFields();
    },
    activePanel() {
      this.refreshMapSize();
    },
    navDrawer() {
      this.refreshMapSize();
    },
    clickState(newValue, oldValue) {
      this.removeClickInteraction(oldValue);
      this.addClickInteraction(newValue);
    },
    displayedProjection() {
      this.registerDisplayedProjection()
      this.changeDisplayedProjection()
    },
    analysisEnabled() {
      this.refreshEdgeLayers()
    },
    analysisOverwriteLabels() {
      this.refreshEdgeLayers()
    },
    analysisEdgeDeltas() {
      this.refreshEdgeLayers()
    },
    analysisVisualizationMode() {
      this.refreshEdgeLayers()
    },
    analysisVisualizationThreshold() {
      this.refreshEdgeLayers()
    }
  },
  mounted() {
    this.initMap();
    this.initCustomControls();
    this.initInteractions();
    this.loadData();
    this.addClickInteraction(this.clickState);
    this.$store.dispatch("map/setComponent", this);
  },
  beforeDestroy() {
    this.$store.dispatch("map/setComponent", undefined);
  },
  methods: {
    refreshEdgeLayers() {
      this.mapLayers.supplyEdges.changed()
      this.mapLayers.returnEdges.changed()
    },
    refreshMapSize() {
      window.setTimeout(() => {
        this.map.updateSize();
      }, 500);
    },
    initMapSource() {
      const mapSource = this.userSettings.map_source || "osm";
      let source = null;
      switch (mapSource) {
        case "bing_aerial":
        case "bing_aerial_w_labels":
        case "bing_road":
          source = new BingMaps({
            key: process.env.VUE_APP_BING_MAPS_KEY,
            imagerySet: bingImagerySets[mapSource],
          });
          break;
        case "stadia_tiles":
          source = new XYZSource({
            url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}@2x.png',
            tilePixelRatio: 2,
            maxZoom: 20
          });
          break;
        case "osm":
        default:
          source = new OSM();
      }

      this.mapLayers.ortho.setSource(source);
      this.mapLayers.ortho.changed();
    },
    async initRawDataLayer() {
      if (!this.userConfig.raw_data_enabled) {
        return;
      }

      const rawDataUrl = process.env.VUE_APP_RAW_DATA_URL;
      const url = `${rawDataUrl}${this.userConfig.project_id}/{z}/{x}/{-y}.png`;
      const source = new XYZSource({
        attributions: "",
        minZoom: 8,
        maxZoom: 21,
        url,
        tileSize: [256, 256],
      });

      this.mapLayers.raw.setSource(source);
      this.mapLayers.raw.setVisible(this.userSettings.rawData.enabled);
    },
    registerDisplayedProjection() {
      if (!this.displayedProjection) return

      const { name, proj4string } = this.displayedProjection

      if (proj4.defs(name) === undefined) {
        proj4.defs(name, proj4string)
        register(proj4);
      }
    },
    changeDisplayedProjection() {
      this.mousePositionControl.setProjection(this.displayedProjection?.name ?? 'EPSG:4326')
      this.mousePositionControl.setCoordinateFormat(
        (coordinate) => OlUtil.mousePositionControlFormat(
          coordinate,
          this.displayedProjection ? 0 : 6
        )
      )
    },
    initMap() {
      let center = [1425237.2263093141, 6042327.733110495];
      if (this.userConfig.entry_point_geom) {
        center = OlUtil.transformCoordinates(this.userConfig.entry_point_geom);
      }
      const zoom = this.userConfig.entry_point_zoom;
      let extent = false;
      if (this.userConfig.bounding_box) {
        extent = OlUtil.transformExtent(
          this.userConfig.bounding_box.coordinates.flat()
        );
      }

      if (this.displayedProjection) {
        this.registerDisplayedProjection()
      }

      this.initMapSource();
      this.initRawDataLayer();
      const controls = OlUtil.defaultControls();
      this.mousePositionControl = OlUtil.mousePositionControl({
        projection: this.displayedProjection?.name,
        digits: this.displayedProjection ? 0 : 6
      })
      controls.extend([this.mousePositionControl])

      const resolutions = process.env.VUE_APP_MAP_RESOLUTIONS.split(",").map(x => parseFloat(x));

      if (!this.userConfig.ecc_enabled) {
        this.mapLayers.buildings.setVisible(false)
        this.mapLayers.coolingHouses.setVisible(false)
        this.mapLayers.fields.setVisible(false)
      }

      if (!this.userConfig.heating_network_enabled) {
        const heatingNetworkLayers = [
          this.mapLayers.supplyEdges,
          this.mapLayers.returnEdges,
          this.mapLayers.supplyNodes,
          this.mapLayers.returnNodes,
          this.mapLayers.substations,
          this.mapLayers.plants,
          this.mapLayers.storages
        ];
        for (const layer of heatingNetworkLayers) {
          layer.setVisible(false)
        }
      }

      this.map = new OlMap({
        target: "map",
        layers: [
          this.mapLayers.ortho,
          this.mapLayers.raw,
          this.mapLayers.supplyEdges,
          this.mapLayers.returnEdges,
          this.mapLayers.supplyNodes,
          this.mapLayers.returnNodes,
          this.mapLayers.substations,
          this.mapLayers.plants,
          this.mapLayers.storages,
          this.mapLayers.buildings,
          this.mapLayers.coolingHouses,
          this.mapLayers.fields,
          this.mapLayers.drawing,
        ],
        view: new View({
          zoom,
          center,
          resolutions: resolutions,
          extent: extent !== false ? extent : undefined,
        }),
        controls,
      });
    },
    initCustomControls() {
      // save btn
      const saveBtn = document.createElement("button");
      saveBtn.innerText = this.$t("general.save");
      saveBtn.addEventListener("click", this.onSaveBtnClick);

      this.saveBtn = saveBtn;

      const cancelBtn = document.createElement("button");
      cancelBtn.innerText = this.$t("general.cancel");
      cancelBtn.addEventListener("click", this.onCancelBtnClick);

      const saveCancelElement = document.createElement("div");
      saveCancelElement.className = "save-cancel ol-control ol-unselectable";

      saveCancelElement.appendChild(saveBtn);
      saveCancelElement.appendChild(cancelBtn);

      this.saveCancelControl = new Control({
        element: saveCancelElement,
      });
    },
    initInteractions() {
      // draw interaction for polygons
      this.drawInteraction = new Draw({
        source: this.mapLayers.drawing.getSource(),
        type: "Polygon",
        style: this.styleDrawing,
      });

      this.drawInteraction.on("drawstart", this.onDrawStart);
      this.drawInteraction.on("drawend", this.onDrawEnd)

      // choose node interactions
      this.chooseNodeInteraction = new Select({
        condition: singleClick,
        layers: [this.mapLayers.supplyNodes, this.mapLayers.returnNodes],
        style: this.styleDrawing,
        filter: this.chooseNodeInteractionFilter,
      });
      this.chooseNodeInteraction.on(
        "select",
        this.chooseNodeInteractionOnSelect
      );
      this.chooseNodeInteractionHover = new Select({
        condition: pointerMove,
        layers: [this.mapLayers.supplyNodes, this.mapLayers.returnNodes],
        style: this.styleDrawing,
        filter: this.chooseNodeInteractionFilter,
      });
      this.chooseNodeInteractionHover.on("select", (event) => {
        this.hoverActive = event.selected.length > 0;
      });

      // default interactions
      this.idleInteractionHover = new Select({
        condition: pointerMove,
        style: this.styleIdleHover,
      });
      this.idleInteractionHover.on("select", (event) => {
        this.hoverActive = event.selected.length > 0;
      });
    },
    chooseNodeInteractionFilter(feature) {
      const item = feature.get("item");

      return item.supply === this.clickOptions.supply;
    },
    saveBtnEnabled(enabled) {
      this.saveBtn.style = enabled ? "" : "display: none";
    },
    addClickInteraction(clickState) {
      let saveBtnEnabled = false
      switch (clickState) {
        case clickStates.PLANT_PLACE_GEOM:
        case clickStates.SUBSTATION_PLACE_GEOM:
        case clickStates.STORAGE_PLACE_GEOM:
        case clickStates.BUILDING_DRAW:
        case clickStates.COOLING_HOUSE_DRAW:
        case clickStates.FIELD_DRAW:
          this.map.addControl(this.saveCancelControl);
          this.map.addInteraction(this.drawInteraction);
          this.drawing = true;
          break;
        case clickStates.PLANT_QUERY_GEOM:
        case clickStates.SUBSTATION_QUERY_GEOM:
        case clickStates.PLACE_NODE:
          saveBtnEnabled = true
          this.map.addControl(this.saveCancelControl);
          this.map.on("click", this.onMapClick);
          this.drawing = true;
          break;
        case clickStates.BUILDING_QUERY:
        case clickStates.COOLING_HOUSE_QUERY:
        case clickStates.FIELD_QUERY:
          this.map.on("click", this.onMapClick);
          this.drawing = true;
          break;
        case clickStates.PLANT_CHOOSE_RETURN:
        case clickStates.PLANT_CHOOSE_SUPPLY:
        case clickStates.STORAGE_CHOOSE_RETURN:
        case clickStates.STORAGE_CHOOSE_SUPPLY:
        case clickStates.SUBSTATION_CHOOSE_RETURN:
        case clickStates.SUBSTATION_CHOOSE_SUPPLY:
        case clickStates.EDGE_CHOOSE_NODE_UP:
        case clickStates.EDGE_CHOOSE_NODE_DOWN:
          this.drawing = false;
          saveBtnEnabled = true;
          this.map.addControl(this.saveCancelControl);
          this.map.addInteraction(this.chooseNodeInteraction);
          this.map.addInteraction(this.chooseNodeInteractionHover);
          this.drawCurrentFeature();
          break;
        case clickStates.IDLE:
        default:
          this.drawing = false;
          this.map.on("click", this.onMapClick);
          this.map.addInteraction(this.idleInteractionHover);
      }

      this.saveBtnEnabled(saveBtnEnabled)
    },
    removeClickInteraction(clickState) {
      switch (clickState) {
        case clickStates.PLANT_PLACE_GEOM:
        case clickStates.SUBSTATION_PLACE_GEOM:
        case clickStates.STORAGE_PLACE_GEOM:
        case clickStates.BUILDING_DRAW:
        case clickStates.COOLING_HOUSE_DRAW:
        case clickStates.FIELD_DRAW:
          this.mapLayers.drawing.getSource().clear();
          this.map.removeControl(this.saveCancelControl);
          this.map.removeInteraction(this.drawInteraction);
          break;
        case clickStates.PLANT_QUERY_GEOM:
        case clickStates.SUBSTATION_QUERY_GEOM:
        case clickStates.PLACE_NODE:
          this.mapLayers.drawing.getSource().clear();
          this.map.removeControl(this.saveCancelControl);
          this.map.un("click", this.onMapClick);
          break;
        case clickStates.BUILDING_QUERY:
        case clickStates.COOLING_HOUSE_QUERY:
        case clickStates.FIELD_QUERY:
          this.mapLayers.drawing.getSource().clear();
          this.map.un("click", this.onMapClick);
          break;
        case clickStates.PLANT_CHOOSE_RETURN:
        case clickStates.PLANT_CHOOSE_SUPPLY:
        case clickStates.STORAGE_CHOOSE_RETURN:
        case clickStates.STORAGE_CHOOSE_SUPPLY:
        case clickStates.SUBSTATION_CHOOSE_RETURN:
        case clickStates.SUBSTATION_CHOOSE_SUPPLY:
        case clickStates.EDGE_CHOOSE_NODE_UP:
        case clickStates.EDGE_CHOOSE_NODE_DOWN:
          this.mapLayers.drawing.getSource().clear();
          this.chooseNodeInteraction.getFeatures().clear();
          this.map.removeControl(this.saveCancelControl);
          this.map.removeInteraction(this.chooseNodeInteraction);
          this.map.removeInteraction(this.chooseNodeInteractionHover);
          break;
        case clickStates.IDLE:
        default:
          this.map.un("click", this.onMapClick);
          this.map.removeInteraction(this.idleInteractionHover);
      }
    },
    drawCurrentFeature() {
      this.mapLayers.drawing.getSource().clear();
      const id = this.clickOptions.id;
      const supply = this.clickOptions.supply;
      const isEdge = [
        clickStates.EDGE_CHOOSE_NODE_UP,
        clickStates.EDGE_CHOOSE_NODE_DOWN,
      ].includes(this.clickState);
      const nodeSrc =
        this.mapLayers[supply ? "supplyNodes" : "returnNodes"].getSource();
      const copiedFeatures = [];
      if (isEdge) {
        // edges need to draw both nodes and the edge itself
        // retrieve current edge
        const edgeSrc =
          this.mapLayers[supply ? "supplyEdges" : "returnEdges"].getSource();
        const edgeFeature = edgeSrc.getFeatureById(id);
        if (edgeFeature !== null) {
          copiedFeatures.push(edgeFeature.clone());
        }

        // retrieve current nodes
        if (this.clickOptions.nodeDownId) {
          const nodeDownFeature = nodeSrc.getFeatureById(
            this.clickOptions.nodeDownId
          );
          if (nodeDownFeature !== null) {
            copiedFeatures.push(nodeDownFeature.clone());
          }
        }
        if (this.clickOptions.nodeUpId) {
          const nodeUpFeature = nodeSrc.getFeatureById(
            this.clickOptions.nodeUpId
          );
          if (nodeUpFeature !== null) {
            copiedFeatures.push(nodeUpFeature.clone());
          }
        }
      } else {
        // for others just draw the old node
        if (!this.clickOptions.oldId) return;

        const feature = nodeSrc.getFeatureById(this.clickOptions.oldId);
        if (feature === null) return;

        copiedFeatures.push(feature.clone());
      }
      this.mapLayers.drawing.getSource().addFeatures(copiedFeatures);
    },
    onDrawStart(e) {
      this.mapLayers.drawing.getSource().clear();
      const id = this.clickOptions.id;
      let key = null;
      switch (this.clickState) {
        case clickStates.PLANT_PLACE_GEOM:
          key = "plants";
          break;
        case clickStates.STORAGE_PLACE_GEOM:
          key = "storages";
          break;
        case clickStates.SUBSTATION_PLACE_GEOM:
          key = "substations";
          break;
        case clickStates.BUILDING_DRAW:
          key = "buildings";
          break;
        case clickStates.COOLING_HOUSE_DRAW:
          key = "coolingHouses";
          break
        case clickStates.FIELD_DRAW:
          key = "fields";
          break
        default:
          return;
      }
      const item = this[key].find((item) => item.id === id);
      e.feature.set("item", item);
    },
    onDrawEnd(e) {
      switch (this.clickState) {
        case clickStates.PLANT_PLACE_GEOM:
        case clickStates.SUBSTATION_PLACE_GEOM:
        case clickStates.STORAGE_PLACE_GEOM:
        case clickStates.BUILDING_DRAW:
        case clickStates.COOLING_HOUSE_DRAW:
        case clickStates.FIELD_DRAW:
          this.saveDrawnPolygon(e.feature);
          break;
        case clickStates.IDLE:
        default:
      }
    },
    closeMapPopup() {
      this.$store.dispatch("mapPopup/close");
    },
    onMapClick(e) {
      switch (this.clickState) {
        case clickStates.PLANT_QUERY_GEOM:
        case clickStates.SUBSTATION_QUERY_GEOM:
          this.queryGeometry(e.coordinate)
          break;
        case clickStates.BUILDING_QUERY:
          this.queryBuilding(e.coordinate);
          break;
        case clickStates.COOLING_HOUSE_QUERY:
          this.queryCoolingHouse(e.coordinate)
          break
        case clickStates.FIELD_QUERY:
          this.queryField(e.coordinate)
          break
        case clickStates.PLACE_NODE:
          this.placeNode(e.coordinate);
          break;
        case clickStates.IDLE:
        default:
          this.clickFeatureAtPixel(e.pixel);
      }
    },
    clickFeatureAtPixel(pixel) {
      const features = this.map.getFeaturesAtPixel(pixel);

      this.closeMapPopup();

      if (features.length === 0) {
        return;
      }

      const item = features[0].get("item");
      const type = features[0].get("type");
      switch (type) {
        case featureTypes.SUBSTATION:
        case featureTypes.PLANT:
          this.$store.dispatch("mapPopup/open", {
            item,
            type,
          });
          break;
        case featureTypes.NODE:
          this.$store.dispatch("mapPopup/open", {
            item,
            type,
          });
          if (this.activePanel === "node") {
            this.activePanelRef.highlightRow(item);
          }
          break;
        case featureTypes.EDGE:
          this.$store.dispatch("mapPopup/open", {
            item,
            type,
          });
          if (this.activePanel === "edge") {
            this.activePanelRef.highlightRow(item);
          }
          break;
        case featureTypes.BUILDING:
        case featureTypes.COOLING_HOUSE:
        case featureTypes.FIELD:
          if (this.activePanel === "energyPlanner") {
            this.activePanelRef.onMapEnergyPlannerItemClick(item, type);
          }
          break;
        default:
        // nothing
      }
    },
    async queryGeometry(coordinate) {
      this.loading = true
      // place a point for query coorindate
      this.placeDrawingFeature('QueryPoint', undefined, new Point(coordinate))
      // transform to EPSG:4326
      const tmp = OlUtil.transformCoordinates(coordinate, 'EPSG:3857', 'EPSG:4326')

      try {
        const response = await OverpassApiService.queryPoint(tmp[0], tmp[1])
        const buildingQueryItem = response.data.elements[0];
        if (buildingQueryItem !== undefined) {
          this.createOverpassQueryFeature(buildingQueryItem)
        } else {
          throw Error();
        }
      } catch {
        const payload = { geometry: [this.$t('map.query_geometry.overpass_query_failed')] };
        switch (this.clickState) {
          case clickStates.PLANT_QUERY_GEOM:
            this.$store.dispatch('plant/panelErrors', payload);
            break;
          case clickStates.SUBSTATION_QUERY_GEOM:
            this.$store.dispatch('substation/panelErrors', payload);
            break;
        }
      }
      this.loading = false;
    },
    createQueryPoint(coordinate) {
      // place point in map
      const geometry = new Point(coordinate)
      this.placeDrawingFeature("QueryPoint", {}, geometry)

      // query the coordinates
      const tmp = OlUtil.transformCoordinates(coordinate, 'EPSG:3857', 'EPSG:4326')
      const lon = tmp[0]
      const lat = tmp[1]

      return { lon, lat }
    },
    async queryBuilding(coordinate) {
      const { lon, lat } = this.createQueryPoint(coordinate)

      await this.$store.dispatch('energyPlanner/building/buildingQueryPoint', { lon, lat })
    },
    async queryCoolingHouse(coordinate) {
      const { lon, lat } = this.createQueryPoint(coordinate)

      await this.$store.dispatch('energyPlanner/coolingHouse/coolingHouseQueryPoint', { lon, lat })
    },
    async queryField(coordinate) {
      // TODO?
      console.log(coordinate)
    },
    createOverpassQueryFeature(queryItem, queryPoint) {
      const source = this.mapLayers.drawing.getSource()
      source.clear()

      if (queryPoint !== undefined) {
        const coordinates = OlUtil.transformCoordinates([queryPoint.lon, queryPoint.lat])
        const geometry = new Point(coordinates)
        this.placeDrawingFeature("QueryPoint", {}, geometry)
      }

      let featureId = 'QueryPoint'

      const features = []
      if (queryItem !== undefined && queryItem.geometry) {

        const points = []
        for (const point of queryItem.geometry) {
          points.push(OlUtil.transformCoordinates([point.lon, point.lat]))
        }

        const tags = queryItem.tags;
        // console.log(queryItem);
        let text = ''
        if (!tags['addr:street'] || !tags['addr:housenumber']) {
          text = this.$t('map.query_geometry.no_address_data')
        } else {
          text = `${tags['addr:street']} ${tags['addr:housenumber']}`
        }

        const item = {
          id: queryItem.id,
          name: text
        }
        const feature = new Feature({
          geometry: new Polygon([points]),
          item
        })
        feature.setId(queryItem.id)
        features.push(feature)
        featureId = queryItem.id
      }

      source.addFeatures(features)
      source.changed()

      this.locateFeature("drawing", featureId)
    },
    placeNode(coordinate) {
      const id = this.clickOptions.id;
      const node = this.nodes.find((item) => item.id === id);
      if (!node) {
        return;
      }
      const geometry = new Point(coordinate);

      this.placeDrawingFeature(id, node, geometry);
    },
    placeDrawingFeature(id, item, geometry) {
      let feature = this.mapLayers.drawing.getSource().getFeatureById(id);
      if (feature === null) {
        feature = new Feature({
          geometry,
          item,
        });
        feature.setId(id);
        this.mapLayers.drawing.getSource().addFeature(feature);
      } else {
        feature.setGeometry(geometry);
      }
    },
    getDrawingFeatureGeometry(id) {
      const feature = this.mapLayers.drawing.getSource().getFeatureById(id)

      if (!feature) {
        return undefined
      }

      const geometry = geojsonParser.writeGeometryObject(feature.getGeometry())

      return geometry
    },
    saveOverpassQueryPolygon() {
      const features = this.mapLayers.drawing.getSource().getFeatures();
      if (features.length) {
        this.saveDrawnPolygon(features[0]);
      }
    },
    async saveDrawnPolygon(feature) {
      const geometry = geojsonParser.writeGeometryObject(feature.getGeometry());

      let action = "";
      switch (this.clickState) {
        case clickStates.PLANT_QUERY_GEOM:
        case clickStates.PLANT_PLACE_GEOM:
          action = "plant/updateGeometry";
          break;
        case clickStates.STORAGE_PLACE_GEOM:
          action = "storage/updateGeometry";
          break;
        case clickStates.SUBSTATION_QUERY_GEOM:
        case clickStates.SUBSTATION_PLACE_GEOM:
          action = "substation/updateGeometry";
          break;
        case clickStates.BUILDING_DRAW:
        case clickStates.BUILDING_QUERY:
          action = "energyPlanner/building/saveDrawnGeometry";
          break;
        case clickStates.COOLING_HOUSE_DRAW:
        case clickStates.COOLING_HOUSE_QUERY:
          action = "energyPlanner/coolingHouse/saveDrawnGeometry";
          break
        case clickStates.FIELD_DRAW:
        case clickStates.FIELD_QUERY:
          action = "energyPlanner/field/saveDrawnGeometry"
          break
        default:
          return;
      }

      const error = await this.$store.dispatch(action, {
        id: this.clickOptions.id,
        geometry
      });

      if (!error) {
        this.setClickStateIdle();
      }
    },
    async saveNode() {
      const features = this.mapLayers.drawing.getSource().getFeatures();
      if (features.length === 0) {
        return;
      }

      const feature = features[0];

      const geometry = geojsonParser.writeGeometryObject(feature.getGeometry());

      const error = await this.$store.dispatch("node/updateGeometry", {
        id: this.clickOptions.id,
        geometry,
      });

      if (!error) {
        this.setClickStateIdle();
      }
    },
    async chooseNodeInteractionOnSelect(event) {
      if (event.selected.length === 0) {
        return;
      }

      this.loading = true;

      const feature = event.selected[0];
      let action = "";
      const payload = {
        id: this.clickOptions.id,
        nodeId: feature.getId(),
        isSupply: this.clickOptions.supply,
      };
      switch (this.clickState) {
        case clickStates.PLANT_CHOOSE_RETURN:
        case clickStates.PLANT_CHOOSE_SUPPLY:
          action = "plant";
          break;
        case clickStates.STORAGE_CHOOSE_RETURN:
        case clickStates.STORAGE_CHOOSE_SUPPLY:
          action = "storage";
          break;
        case clickStates.SUBSTATION_CHOOSE_RETURN:
        case clickStates.SUBSTATION_CHOOSE_SUPPLY:
          action = "substation";
          break;
        case clickStates.EDGE_CHOOSE_NODE_UP:
          action = "edge";
          payload.up = true;
          break;
        case clickStates.EDGE_CHOOSE_NODE_DOWN:
          action = "edge";
          payload.up = false;
          break;
        default:
          return;
      }
      action += "/updateNode";
      const error = await this.$store.dispatch(action, payload);

      if (!error) {
        this.setClickStateIdle();
      }

      this.loading = false;
    },
    onSaveBtnClick() {
      switch (this.clickState) {
        case clickStates.PLANT_QUERY_GEOM:
        case clickStates.SUBSTATION_QUERY_GEOM:
          this.saveOverpassQueryPolygon()
          break;
        case clickStates.PLACE_NODE:
          this.saveNode();
          break;
        case clickStates.EDGE_CHOOSE_NODE_UP:
          this.saveEdgeNode(true);
          break;
        case clickStates.EDGE_CHOOSE_NODE_DOWN:
          this.saveEdgeNode(false);
          break;
        case clickStates.IDLE:
        default:
      }
    },
    onCancelBtnClick() {
      this.setClickStateIdle();
    },
    setClickStateIdle() {
      this.$store.dispatch("map/setClickState", {
        clickState: clickStates.IDLE,
      });
    },
    async loadData() {
      this.loading = true;

      const eccPromises = this.userConfig.ecc_enabled ? [this.$store.dispatch("energyPlanner/loadData")] : []
      const heatingNetworkPromises = this.userConfig.heating_network_enabled ? [
        this.$store.dispatch("edge/index"),
        this.$store.dispatch("node/index"),
        this.$store.dispatch("plant/index"),
        this.$store.dispatch("substation/index"),
        // this.$store.dispatch("storage/index"),
      ] : []

      await Promise.all([
        ...heatingNetworkPromises,
        ...eccPromises
      ]);

      this.renderGeometry();
      this.loading = false;
    },
    renderGeometry() {
      this.renderEdges();
      this.renderNodes();
      this.renderPlants();
      this.renderSubstations();
      // this.renderStorages();
      this.renderBuildings();
      this.renderCoolingHouses()
      this.renderFields()
      this.initDone = true;
    },
    renderBuildings() {
      const features = [];
      const source = this.mapLayers.buildings.getSource();

      for (const building of this.buildings) {
        const geom = building.geom;
        if (!geom) continue

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(geom),
          type: featureTypes.BUILDING,
          item: building
        });
        feature.setId(building.id);
        features.push(feature);
      }

      source.clear();
      source.addFeatures(features);
    },
    refreshBuildingFeature(building) {
      const source = this.mapLayers.buildings.getSource();
      let feature = source.getFeatureById(building.id);
      const geometry = geojsonParser.readGeometry(building.geom);
      if (feature) {
        feature.setGeometry(geometry);
        feature.changed();
      } else {
        feature = new Feature({
          geometry,
          type: featureTypes.BUILDING,
          item: building
        });
        feature.setId(building.id);
        source.addFeature(feature);
      }
    },
    renderCoolingHouses() {
      const features = [];
      const source = this.mapLayers.coolingHouses.getSource();

      for (const coolingHouse of this.coolingHouses) {
        const geom = coolingHouse.geom;
        if (!geom) continue

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(geom),
          type: featureTypes.COOLING_HOUSE,
          item: coolingHouse
        });
        feature.setId(coolingHouse.id);
        features.push(feature);
      }

      source.clear();
      source.addFeatures(features);
    },
    refreshCoolingHouseFeature(coolingHouse) {
      const source = this.mapLayers.coolingHouses.getSource();
      let feature = source.getFeatureById(coolingHouse.id);
      const geometry = geojsonParser.readGeometry(coolingHouse.geom);
      if (feature) {
        feature.setGeometry(geometry);
        feature.changed();
      } else {
        feature = new Feature({
          geometry,
          type: featureTypes.COOLING_HOUSE,
          item: coolingHouse
        });
        feature.setId(coolingHouse.id);
        source.addFeature(feature);
      }
    },
    renderFields() {
      const features = [];
      const source = this.mapLayers.fields.getSource();

      for (const field of this.fields) {
        const geom = field.geom;
        if (!geom) continue

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(geom),
          type: featureTypes.FIELD,
          item: field
        });
        feature.setId(field.id);
        features.push(feature);
      }

      source.clear();
      source.addFeatures(features);
    },
    refreshFieldFeature(field) {
      const source = this.mapLayers.fields.getSource();
      let feature = source.getFeatureById(field.id);
      const geometry = geojsonParser.readGeometry(field.geom);
      if (feature) {
        feature.setGeometry(geometry);
        feature.changed();
      } else {
        feature = new Feature({
          geometry,
          type: featureTypes.FIELD,
          item: field
        });
        feature.setId(field.id);
        source.addFeature(feature);
      }
    },
    renderPlants() {
      const features = [];
      const src = this.mapLayers.plants.getSource();
      for (const item of this.plants) {
        if (!item.geom) continue;

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(item.geom),
          type: featureTypes.PLANT,
          item: item,
        });

        feature.setId(item.id);
        features.push(feature);
      }
      src.clear();
      src.addFeatures(features);
    },
    renderSubstations() {
      const features = [];
      const src = this.mapLayers.substations.getSource();
      for (const item of this.substations) {
        if (!item.geom) continue;

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(item.geom),
          type: featureTypes.SUBSTATION,
          item: item,
        });

        feature.setId(item.id);
        features.push(feature);
      }
      src.clear();
      src.addFeatures(features);
    },
    renderStorages() {
      const features = [];
      const src = this.mapLayers.storages.getSource();
      for (const item of this.storages) {
        if (!item.geom) continue;

        const feature = new Feature({
          geometry: geojsonParser.readGeometry(item.geom),
          type: featureTypes.STORAGE,
          item: item,
        });

        feature.setId(item.id);
        features.push(feature);
      }
      src.clear();
      src.addFeatures(features);
    },
    renderEdges() {
      const returnFeatures = [];
      const supplyFeatures = [];

      for (const item of this.edges) {
        if (!item.node_up_id || !item.node_down_id) {
          continue;
        }

        const nodeUp = this.nodeMap.get(item.node_up_id);
        const nodeDown = this.nodeMap.get(item.node_down_id);
        if (
          nodeUp === undefined ||
          nodeDown === undefined ||
          !nodeUp.geom ||
          !nodeDown.geom
        ) {
          continue;
        }

        const geometry = new LineString([
          OlUtil.transformCoordinates(nodeUp.geom),
          OlUtil.transformCoordinates(nodeDown.geom),
        ]);
        const feature = new Feature({
          geometry: geometry,
          type: featureTypes.EDGE,
          item: item,
        });

        feature.setId(item.id);
        if (item.supply) {
          supplyFeatures.push(feature);
        } else {
          returnFeatures.push(feature);
        }
      }
      const returnSrc = this.mapLayers.returnEdges.getSource();
      returnSrc.clear();
      returnSrc.addFeatures(returnFeatures);

      const supplySrc = this.mapLayers.supplyEdges.getSource();
      supplySrc.clear();
      supplySrc.addFeatures(supplyFeatures);
    },
    renderNodes() {
      const returnFeatures = [];
      const supplyFeatures = [];
      for (const item of this.nodes) {
        if (!item.geom) continue;

        const geometry = new Point(OlUtil.transformCoordinates(item.geom));
        const feature = new Feature({
          geometry: geometry,
          type: featureTypes.NODE,
          item: item,
        });

        feature.setId(item.id);
        if (item.supply) {
          supplyFeatures.push(feature);
        } else {
          returnFeatures.push(feature);
        }
      }
      const returnSrc = this.mapLayers.returnNodes.getSource();
      returnSrc.clear();
      returnSrc.addFeatures(returnFeatures);

      const supplySrc = this.mapLayers.supplyNodes.getSource();
      supplySrc.clear();
      supplySrc.addFeatures(supplyFeatures);
    },
    getFontSize(resolution) {
      if (!styleCache.fontSizes.has(resolution)) {
        let fontSize = Math.round(10 / resolution);
        if (fontSize > 11) fontSize = 11;
        styleCache.fontSizes.set(resolution, fontSize);
      }

      return styleCache.fontSizes.get(resolution);
    },
    setFeatureScaledText(
      style,
      resolution,
      textContent,
      color,
      isPoint,
      placement = "point",
      colorStroke = '#ffffff'
    ) {
      if (resolution >= 0.3) {
        return;
      }

      const fontSize = this.getFontSize(resolution);
      const text = new Text({
        fill: new Fill({
          color,
        }),
        placement,
        stroke: new Stroke({
          color: colorStroke,
        }),
        text: textContent,
        font: `${fontSize}px sans-serif`,
        ...(isPoint ? {offsetY: -13} : { overflow: true })
      });


      style.setText(text);
    },
    edgeColorAndWidth(edge) {
      let color = styleCache.colors[edge.supply ? "supply" : "return"];
      let width = 3
      if (this.analysisEnabled) {
        const aggregateData = this.analysisEdgeDeltas.get(edge.id)

        if (aggregateData !== undefined) {
          const max = aggregateData?.delta_max
          // const avg = aggregateData?.delta_avg

          const threshold = this.analysisVisualizationThreshold
          // if (avg >= threshold) {
          //   color = styleCache.colors.analysisAvg
          //   width = 5
          // } else if (max >= threshold) {
          //   color = styleCache.colors.analysisMax
          //   width = 5
          // }
          if (max >= threshold) {
            color = styleCache.colors.analysisMax
            width = 5
          }
        }
      }

      return { color, width }
    },
    edgeLabel(edge) {
      if (this.analysisOverwriteLabels) {
        const aggregateData = this.analysisEdgeDeltas.get(edge.id)

        if (aggregateData !== undefined) {
          const max = aggregateData?.delta_max
          // const avg = aggregateData?.delta_avg

          const threshold = this.analysisVisualizationThreshold
          const unit = this.analysisVisualizationModeUnit
          // if (avg >= threshold) {
          //   return `${avg.toFixed(2)} [${unit}]`
          // } else if (max >= threshold) {
          //   return `${max.toFixed(2)} [${unit}]`
          // }
          if (max >= threshold) {
            return `${max.toFixed(2)} [${unit}]`
          }
        } else {
          return ''
        }
      }

      const edgeAttributeName = this.userSettings.edgeLabelData;
      if (edgeAttributeName === "none") {
        return ''
      }

      const label =
        this.getAttributeValue(edge, edgeAttributeName) +
        " " +
        this.getAttributeUnit(edgeAttributeName);

      return label
    },
    styleEdges(feature, resolution) {
      const item = feature.get("item")

      // const color = styleCache.colors[supply ? "supply" : "return"];
      const { color, width } = this.edgeColorAndWidth(item)
      const style = new Style({
        stroke: new Stroke({
          color,
          width: width,
        }),
        fill: new Fill({
          color,
        }),
      });

      const edgeLabel = this.edgeLabel(item)

      // const labelColor = styleCache.colors[item.supply ? "supplyLabel" : "returnLabel"];
      this.setFeatureScaledText(
        style,
        resolution,
        edgeLabel,
        '#000000',
        false,
        "line"
      );

      return style;
    },
    styleNodes(feature, resolution) {
      const item = feature.get("item")
      const supply = item.supply;
      const isPlantNode = this.plantNodes.has(item.id)
      const isSubstationNode = this.substationNodes.has(item.id)
      const color = styleCache.colors[supply ? "supply" : "return"];
      const strokeColor = isPlantNode || isSubstationNode ? '#000' : color

      const style = new Style({
        image: new Circle({
          radius: 5,
          stroke: new Stroke({
            color: strokeColor,
            width: 2
          }),
          fill: new Fill({
            color,
          }),
        }),
      });
      const nodeAttributeName = this.userSettings.nodeLabelData;
      const showLabel = nodeAttributeName != "none";
      if (showLabel) {
        const labelColor =
          styleCache.colors[supply ? "supplyLabel" : "returnLabel"];
        const nodeLabel = feature.get("item")[nodeAttributeName].toString();
        this.setFeatureScaledText(
          style,
          resolution,
          nodeLabel,
          labelColor,
          true
        );
      } else {
        this.setFeatureScaledText(
          style,
          resolution,
          "",
          "",
          true
        );
      }
      return style;
    },
    stylePlants(feature, resolution) {
      const color = styleCache.colors.plant;
      const style = new Style({
        stroke: new Stroke({
          color,
        }),
        fill: new Fill({
          color: styleCache.colors.plantFill,
        }),
      });

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );

      return style;
    },
    styleBuildings(feature, resolution) {
      const geometry = feature.getGeometry()
      const color = styleCache.colors.building;
      const stroke = new Stroke({
        color
      })
      const fill = new Fill({
        color: styleCache.colors.buildingFill
      })

      let style = null

      if (geometry instanceof Point) {
        style = new Style({
          image: new Circle({
            radius: 5,
            fill,
            stroke
          })
        })
      } else {
        style = new Style({
          stroke,
          fill
        });
      }

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );
      return style;
    },
    styleCoolingHouses(feature, resolution) {
      const geometry = feature.getGeometry()
      const color = styleCache.colors.coolingHouse;
      const stroke = new Stroke({
        color
      })
      const fill = new Fill({
        color: styleCache.colors.coolingHouseFill
      })

      let style = null

      if (geometry instanceof Point) {
        style = new Style({
          image: new Circle({
            radius: 5,
            fill,
            stroke
          })
        })
      } else {
        style = new Style({
          stroke,
          fill
        });
      }

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );
      return style;
    },
    styleFields(feature, resolution) {
      const geometry = feature.getGeometry()
      const color = styleCache.colors.field;
      const stroke = new Stroke({
        color
      })
      const fill = new Fill({
        color: styleCache.colors.fieldFill
      })

      let style = null

      if (geometry instanceof Point) {
        style = new Style({
          image: new Circle({
            radius: 5,
            fill,
            stroke
          })
        })
      } else {
        style = new Style({
          stroke,
          fill
        });
      }

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );
      return style;
    },
    styleSubstations(feature, resolution) {
      const color = styleCache.colors.substation;
      const style = new Style({
        stroke: new Stroke({
          color,
        }),
        fill: new Fill({
          color: styleCache.colors.substationFill,
        }),
      });

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );

      return style;
    },
    styleStorages(feature, resolution) {
      const color = styleCache.colors.storage;
      const style = new Style({
        stroke: new Stroke({
          color,
        }),
        fill: new Fill({
          color: styleCache.colors.storageFill,
        }),
      });

      this.setFeatureScaledText(
        style,
        resolution,
        feature.get("item").name,
        "#000000",
        false
      );

      return style;
    },
    styleDrawing(feature, resolution) {
      const color = styleCache.colors.drawing;
      const geometry = feature.getGeometry();
      let style = null;
      let isPoint = false;
      if (geometry instanceof Point) {
        style = new Style({
          image: new Circle({
            radius: 6,
            stroke: new Stroke({
              color: "#000000",
            }),
            fill: new Fill({
              color,
            }),
          }),
        });
        isPoint = true;
      } else {
        style = new Style({
          stroke: new Stroke({
            color,
          }),
          fill: new Fill({
            color: styleCache.colors.drawingFill,
          }),
        });
      }

      const item = feature.get("item");
      const text = item !== undefined ? item.name : false;
      if (text) {
        this.setFeatureScaledText(style, resolution, text, '#000000', isPoint);
      }
      style.setZIndex(4);
      return style;
    },
    styleIdleHover(feature, resolution) {
      const type = feature.get("type");

      let style = null;
      switch (type) {
        case featureTypes.PLANT:
          style = this.stylePlants(feature, resolution);
          style.getStroke().setWidth(3);
          break;
        case featureTypes.STORAGE:
          style = this.styleStorages(feature, resolution);
          style.getStroke().setWidth(3);
          break;
        case featureTypes.SUBSTATION:
          style = this.styleSubstations(feature, resolution);
          style.getStroke().setWidth(3);
          break;
        case featureTypes.EDGE:
          style = this.styleEdges(feature, resolution);
          style.getStroke().setWidth(5);
          break;
        case featureTypes.NODE:
          style = this.styleNodes(feature, resolution);
          style.getImage().setRadius(style.getImage().getRadius() + 2);
          break;
        case featureTypes.BUILDING:
          style = this.styleBuildings(feature, resolution);
          if (feature.getGeometry() instanceof Point) {
            style.getImage().setRadius(style.getImage().getRadius() + 2);
          } else {
            style.getStroke().setWidth(5);
          }
          break
        case featureTypes.COOLING_HOUSE:
          style = this.styleCoolingHouses(feature, resolution);
          if (feature.getGeometry() instanceof Point) {
            style.getImage().setRadius(style.getImage().getRadius() + 2);
          } else {
            style.getStroke().setWidth(5);
          }
          break
        case featureTypes.FIELD:
          style = this.styleFields(feature, resolution);
          if (feature.getGeometry() instanceof Point) {
            style.getImage().setRadius(style.getImage().getRadius() + 2);
          } else {
            style.getStroke().setWidth(5);
          }
          break
      }
      return style;
    },
    locateStorage(id) {
      this.locateFeature("storages", id);
    },
    locateSubstation(id) {
      this.locateFeature("substations", id);
    },
    locatePlant(id) {
      this.locateFeature("plants", id);
    },
    locateNode(id, supply) {
      this.locateFeature(supply ? "supplyNodes" : "returnNodes", id);
    },
    locateEdge(id, supply) {
      this.locateFeature(supply ? "supplyEdges" : "returnEdges", id);
    },
    centerOnLocation(center, zoom) {
      const view = this.map.getView()
      view.setCenter(center)
      view.setZoom(zoom)
    },
    locateFeature(layer, id) {
      if (this.mapLayers[layer] === undefined) {
        console.error("Layer " + layer + " doesnt exist");
        return;
      }

      const source = this.mapLayers[layer].getSource();
      if (!(source instanceof VectorSource)) {
        console.error("can only find featuers in vector source");
        return;
      }

      const feature = source.getFeatureById(id);
      if (!feature) {
        return;
      }

      this.map
        .getView()
        .fit(feature.getGeometry(), { padding: [1, 1, 1, 1], maxZoom: 22 });
      this.styleIdleHover(feature, this.resolution);
    },
    getAttributeValue(item, attributeName) {
      let value = item[attributeName]

      // do some transformation depending on attributeName
      switch (attributeName) {
        case "edgelength":
        case "diameter":
        case "htc":
          value = ((value ?? 0)).toFixed(2)
          break;
        case "roughness":
          value = ((value ?? 0) * 1000).toFixed(2)
          break;
      }

      return value
    },
    getAttributeUnit(attributeName) {
      let unit;
      switch (attributeName) {
        case "edgelength":
          unit = "[m]";
          break;
        case "diameter":
          unit = "[m]";
          break;
        case "roughness":
          unit = "[mm]";
          break;
        case "htc":
          unit = "[kW/mK]";
          break;

        default:
          unit = "";
          break;
      }
      return unit;
    },
  },
};
</script>
