import Api from './Api'

export default {
  index () {
    return Api().get('/substations')
  },
  store (item) {
    return Api().post('/substations', { ...item })
  },
  update (item) {
    return Api().put('/substations/' + item.id, { ...item })
  },
  updateGeometry (id, geometry) {
    return Api().post(`/substations/${id}/geometry`, { geometry: geometry })
  },
  updateNode (id, nodeId, supply) {
    return Api().post(`/substations/${id}/node`, { node_id: nodeId, supply: supply })
  },
  delete (item) {
    return Api().delete('/substations/' + item.id)
  },
  data (id, date) {
    return Api().get(`/substations/${id}/data`, {
      params: { date }
    })
  },
  costs (id, year) {
    return Api().get(`/substations/${id}/costs`, { params: { year }})
  },
  power (id, year) {
    return Api().get(`/substations/${id}/power`, { params: { year }})
  },
  azureProxy (id, year) {
    return Api().get(`/substations/${id}/azure_proxy`, { params: { year }})
  },
  azureDetailsProxy (id, params) {
    return Api().get(`/substations/${id}/azure_details_proxy`, { params })
  },
  popupData (item, date, date_end) {
    return Api().get(`/substations/${item.id}/popup_data`, { params: { date, date_end }})
  }
}
