<template>
    <portal to="app-bar-actions">
        <v-btn @click="openSimulation" color="primary">{{
            $t("general.top_bar_start_simulation")
        }}</v-btn>
    </portal>
</template>

<script>
export default {
    name: 'OpenSimulationDialogButton',
    computed: {
        activePanel() {
            return this.$store.getters.activePanel
        },
        userConfig() {
            return this.$store.getters["account/config"];
        },
        heatingNetworkEnabled() {
            return this.userConfig.heating_network_enabled ?? false;
        },
        showOpenSimulation() {
            return this.heatingNetworkEnabled && this.activePanel !== 'energyPlanner'
        }
    },
    methods: {
        openSimulation() {
            this.$store.dispatch("simulations/open");
        }
    }
}
</script>
