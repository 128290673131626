<template>
  <v-dialog
    :value="true"
    max-width="400"
    @input="close"
  >
    <v-card>
      <v-card-title>
        {{ $t('panels.energyPlanner.fields.delete_field_header') }}
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('panels.energyPlanner.fields.delete_field_text', { name: item.name }) }}
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="error" @click="save">{{ $t('general.delete') }}</v-btn>
        <v-btn @click="close">{{ $t('general.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "FieldDeleteDialog",
    props: {
      item: {
        type: Object,
        default () {
          return {
            name: ''
          }
        }
      }
    },
    data () {
      return {
        loading: false
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      async save () {
        this.loading = true
        await this.$store.dispatch('energyPlanner/field/deleteField', this.item)
        this.loading = false
        this.close()
      }
    }
  }
</script>
