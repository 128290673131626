<template>
  <v-dialog :value="open" max-width="600" @input="close">
    <v-card>
      <v-card-title>
        {{ $t("panels.edge.edit_header") }}
        <v-spacer></v-spacer>
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            autofocus
            @keydown.enter.exact.prevent="save()"
            :label="$t('general.name') + (editMode ? '*' : '')"
            v-model="item.name"
            :rules="nameRules"
            :error-messages="errorMessages('name')"
            :hint="$t('panels.edge.name_hint')"
            persistent-hint
          />

          <v-text-field
            :label="$t('panels.edge.length')"
            v-model.number="lengthValue"
            type="number"
            :rules="[rules.validOptionalFloat]"
            placeholder="0"
            persistent-placeholder
            :error-messages="errorMessages('length')"
          />
          <DinPipeTypeSelect
            @change="onDinPipeTypeSelectChange"
          />
          <v-text-field
            :label="$t('panels.edge.diameter')"
            v-model.number="diameterValue"
            type="number"
            placeholder="13.25"
            persistent-placeholder
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('diameter')"
          />
          <v-text-field
            :label="$t('panels.edge.roughness')"
            v-model.number="roughnessValue"
            type="number"
            placeholder="0.02"
            persistent-placeholder
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('roughness')"
          />
          <v-text-field
            :label="$t('panels.edge.htc')"
            v-model.number="htcValue"
            type="number"
            placeholder="0.41"
            persistent-placeholder
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('htc')"
          />
          <!-- <v-text-field
            :label="$t('panels.edge.singleloss')"
            v-model.number="item.singleloss"
            type="number"
            placeholder="0"
            persistent-placeholder
            :rules="[rules.validOptionalFloat]"
            :error-messages="errorMessages('singleloss')"
          /> -->

          <!-- <v-text-field
            :label="$t('panels.edge.fid')"
            v-model.number="item.fid"
            type="number"
            placeholder="0"
            persistent-placeholder
            :rules="[rules.validOptionalInteger]"
            :error-messages="errorMessages('fid')"
          /> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="save"
          >{{ $t("general.save") }}</v-btn
        >
        <!-- <v-btn @click="close">{{ $t('general.close') }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from "@/util/RulesUtil";
import DinPipeTypeSelect from "./DinPipeTypeSelect.vue";

export default {
    name: "EdgeEditDialog",
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default() {
                return {
                    name: "",
                    supply: false,
                    fid: null,
                };
            },
        },
    },
    data() {
        return {
            valid: true,
            loading: false,
            errors: false,
            lengthValue: this.item.edgelength ? (this.item.edgelength).toFixed(2) : undefined,
            diameterValue: this.item.diameter ? (this.item.diameter * 100).toFixed(2) : undefined,
            roughnessValue: this.item.roughness ? (this.item.roughness * 1000).toFixed(4) : undefined,
            htcValue: this.item.htc ? (this.item.htc * 1000).toFixed(4) : undefined
        };
    },
    computed: {
        rules() {
            return RulesUtil;
        },
        editMode() {
          return !!this.item.id
        },
        nameRules() {
            if (this.editMode) {
                return [this.rules.maxLength(255)];
            }
            else {
                return [this.rules.maxOptionalLength(255)];
            }
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onDinPipeTypeSelectChange({diameter, htc}) {
          this.diameterValue = diameter.toFixed(2)
          this.htcValue = htc.toFixed(2)
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            let itemToSave = { ...this.item };
            if (this.lengthValue)
                itemToSave.edgelength = this.lengthValue;
            if (this.diameterValue)
                itemToSave.diameter = this.diameterValue / 100;
            if (this.roughnessValue)
                itemToSave.roughness = this.roughnessValue / 1000;
            if (this.htcValue)
                itemToSave.htc = this.htcValue / 1000;
            this.errors = await this.$store.dispatch("edge/saveItem", itemToSave);
            // this.errors = await this.$store.dispatch("edge/saveItem",this.item);
            this.loading = false;
            if (!this.errors) {
                this.close();
            }
        },
        errorMessages(field) {
            if (this.errors && this.errors[field]) {
                return this.errors[field];
            }
            return "";
        },
    },
    watch: {
        item() {
            this.errors = false;
        },
    },
    components: { DinPipeTypeSelect }
};
</script>
