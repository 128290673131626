<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->

    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <div class="d-flex align-center justify-center mb-2 flex-wrap" style="margin-top: -12px; gap: 4px;"
      v-if="dataAggregates">
      <v-chip v-for="field in ['supplyMin', 'supplyMax', 'returnMin', 'returnMax', 'diffMin', 'diffMax']" :key="field"
        :color="dataAggregatesSettings[field].color" label :style="{ color: dataAggregatesSettings[field].textColor }">
        {{ dataAggregatesSettings[field].label }}: {{ dataAggregates[field] }} {{ dataAggregatesSettings[field].unit }}
      </v-chip>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.base_data && data.base_data.length < 1 && data.simulation_data && data.simulation_data.length < 1
      ">{{ $t("general.no_data") }}</v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" />
  </div>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: "PlantMapPopupPressure",
  data() {
    return {
      chartSeries: []
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    simulationData() {
      return this.data?.simulation_data ?? [];
    },
    dataAggregates() {
      const baseData = this.baseData
      const simulationData = this.simulationData

      if (baseData.length <= 1 || simulationData.length <= 1) {
        return undefined
      }

      let supplyMin;
      let supplyMax;
      let returnMin;
      let returnMax;
      let diffMin;
      let diffMax;

      for (let i = 0; i < simulationData.length; i++) {
        const ySupply = baseData[i]?.P_supply
        const yReturn = simulationData[i]?.P_return

        if (ySupply < 0 || yReturn < 0) continue;

        if (supplyMin === undefined || ySupply < supplyMin) supplyMin = ySupply
        if (supplyMax === undefined || ySupply > supplyMax) supplyMax = ySupply

        if (returnMin === undefined || yReturn < returnMin) returnMin = yReturn
        if (returnMax === undefined || yReturn > returnMax) returnMax = yReturn

        const diff = Math.abs(ySupply - yReturn)
        if (diffMin === undefined || diff < diffMin) diffMin = diff
        if (diffMax === undefined || diff > diffMax) diffMax = diff
      }

      return {
        supplyMin: supplyMin?.toFixed(2),
        supplyMax: supplyMax?.toFixed(2),
        returnMin: returnMin?.toFixed(2),
        returnMax: returnMax?.toFixed(2),
        diffMin: diffMin.toFixed(2),
        diffMax: diffMax.toFixed(2)
      }
    },
    dataAggregatesSettings() {
      return {
        supplyMin: {
          label: this.$t("panels.plant.data_fields.P_supply_min"),
          unit: 'bar',
          color: '#2566cb',
          textColor: 'white'
        },
        supplyMax: {
          label: this.$t("panels.plant.data_fields.P_supply_max"),
          unit: 'bar',
          color: '#2566cb',
          textColor: 'white'
        },
        returnMin: {
          label: this.$t("panels.plant.data_fields.P_return_min"),
          unit: 'bar',
          color: "#8cdcfe",
          textColor: 'black'
        },
        returnMax: {
          label: this.$t("panels.plant.data_fields.P_return_max"),
          unit: 'bar',
          color: "#8cdcfe",
          textColor: 'black'
        },
        diffMin: {
          label: this.$t("panels.plant.data_fields.P_diff_min"),
          unit: 'bar',
          color: '#25b7ab',
          textColor: 'white'
        },
        diffMax: {
          label: this.$t("panels.plant.data_fields.P_diff_max"),
          unit: 'bar',
          color: '#25b7ab',
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        legend: {
          showForSingleSeries: true,
        },
        stroke: {
          curve: "smooth",
          // dashArray: [0, 0, 15, 15],
        },
        // colors: ["#25b7ab", process.env.VUE_APP_SECONDARY_COLOR, "#25b789", "#ee4e31"],
        colors: ["#2566cb", "#8cdcfe"],
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} bar`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    prepareData() {
      const baseDataSets = this.prepareBaseData();
      const simulationDataSets = this.prepareSimulationData();

      const prefixSimulation = "";
      // const prefixBase = this.$t('general.base_data') + ': '

      this.chartSeries = [
        // {
        //   name: this.$t("panels.plant.data_fields.P_return"),
        //   data: baseDataSets.dataReturn,
        // },
        {
          name: this.$t("panels.plant.data_fields.P_supply"),
          data: baseDataSets.dataSupply,
        },
        {
          name: prefixSimulation + this.$t("panels.plant.data_fields.P_return"),
          data: simulationDataSets.dataReturn,
        },
        // {
        //   name: prefixSimulation + this.$t("panels.plant.data_fields.P_supply"),
        //   data: simulationDataSets.dataSupply,
        // },
      ];
    },
    prepareBaseData() {
      const dataReturn = [];
      const dataSupply = this.prepareNormalizedData(this.baseData, 'P_supply', 0);
      return {
        dataReturn,
        dataSupply,
      };
    },
    prepareSimulationData() {
      const dataReturn = this.prepareNormalizedData(this.simulationData, 'P_return', 0);
      const dataSupply = [];
      return {
        dataReturn,
        dataSupply,
      };
    },
    tooltipFormatY(value) {
      //TODO later
      // return `${value?.toFixed(2)} ${this.chartSeries[options.seriesIndex].unit}`;
      return `${value?.toFixed(2)} bar`;
    },
  },
};
</script>
