<template>
  <div v-if="locales.length" class="mr-3">
    <v-menu :z-index="31">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :loading="loading"
          :disabled="loading"
          icon
        >
          {{ userLocale }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="locale in locales"
          :key="locale"
          @click="() => setLocale(locale)"
          :input-value="locale === userLocale"
          :title="$t('panels.settings.locale.values.' + locale)"
        >
          <span>{{ locale.toUpperCase() }}</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelect',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    locales () {
      return this.$store.getters['settings/locales']
    },
    userLocale () {
      return this.$store.getters['account/userLocale']
    }
  },
  methods: {
    async setLocale(locale) {
      this.loading = true
      await this.$store.dispatch('account/updateUserLocale', locale)
      this.loading = false
    }
  }
}
</script>
