<template>
  <v-dialog :value="true" max-width="800" @input="close">
    <v-card>
      <v-card-title>
        {{ $t('panels.energyPlanner.fields.edit_field_header') }}
        <v-spacer />
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="errors && errors.general" type="error" color="error">
          {{ errors.general }}
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-text-field :label="$t('general.name')" v-model="item.name"
            :rules="[rules.required, rules.maxLength(255)]" :error-messages="errorMessages('name')" />
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field :label="$t('panels.energyPlanner.attributes.field.area')" v-model.number="item.area" type="number"
                :rules="[rules.validOptionalFloat]" suffix="m²" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('panels.energyPlanner.attributes.field.energyconsumption')"
                  v-model.number="item.energyconsumption"
                  type="number"
                  :rules="[rules.validOptionalFloat]"
                  suffix="MWh p.a."
                 />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('panels.energyPlanner.attributes.field.mainconsumptionpercentage')"
                  v-model.number="item.mainconsumptionpercentage"
                  type="number"
                  :rules="[rules.validOptionalFloat]"
                  suffix="%"
                 />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('panels.energyPlanner.attributes.field.dailyminimumconsumption')"
                  v-model.number="item.dailyminimumconsumption"
                  type="number"
                  :rules="[rules.validOptionalFloat]"
                  suffix="kWh"
                 />
              </v-col>
            </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="accent" @click="save">{{ $t('general.save') }}</v-btn>
        <v-btn @click="close">{{ $t('general.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'

export default {
  name: "FieldEditDialog",
  props: {
    item: {
      type: Object,
      default() {
        return {
          name: ''
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      errors: false
    }
  },
  computed: {
    rules() {
      return RulesUtil
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      this.errors = await this.$store.dispatch('energyPlanner/field/saveField', this.item)
      this.loading = false
      if (!this.errors) {
        this.close()
      }
    },
    errorMessages(field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field]
      }

      return ''
    }
  },
  watch: {
    item() {
      this.errors = false
    }
  }
}
</script>
