<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <date-picker
      :date.sync="date"
      :date_end.sync="date_end"
      :disabled="loading"
    />
    <div class="d-flex align-center justify-center mb-2 flex-wrap" style="margin-top: -12px; gap: 4px;" v-if="dataAggregates">
      <v-chip v-for="field in ['diffMin', 'diffMax']" :key="field" :color="dataAggregatesSettings[field].color" label
        :style="{ color: dataAggregatesSettings[field].textColor }">
        {{ dataAggregatesSettings[field].label }}: {{ dataAggregates[field] }} {{ dataAggregatesSettings[field].unit }}
      </v-chip>
    </div>
    <v-divider />
    <v-alert
      type="warning"
      color="secondary"
      v-if="
        data && data.base_data && data.base_data.length < 1 && data.simulation_data && data.simulation_data.length < 1
      "
      >{{ $t("general.no_data") }}</v-alert>
    <apexchart
      type="line"
      height="500"
      :options="chartOptions"
      :series="chartSeries"
    />
  </div>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: "SubstationMapPopupTemperature",
  data() {
    return {
      chartSeries: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    simulationData() {
      return this.data?.simulation_data ?? [];
    },
    dataAggregates() {
      const simulationData = this.simulationData

      if (simulationData.length <= 1) {
        return undefined
      }

      let diffMin;
      let diffMax;

      for (let i = 0; i < simulationData.length; i++) {
        const tInP = Math.max(10, simulationData[i]?.Tin_P)
        const tOutP = Math.max(10, simulationData[i]?.Tout_P)

        const diff = Math.abs(tInP - tOutP)
        if (diffMin === undefined || diff < diffMin) diffMin = diff
        if (diffMax === undefined || diff > diffMax) diffMax = diff
      }

      return {
        diffMin: diffMin.toFixed(2),
        diffMax: diffMax.toFixed(2)
      }
    },
    dataAggregatesSettings() {
      return {
        diffMin: {
          label: this.$t("panels.substation.data_fields.T_diff_min"),
          unit: '°C',
          color: '#25b7ab',
          textColor: 'white'
        },
        diffMax: {
          label: this.$t("panels.substation.data_fields.T_diff_max"),
          unit: '°C',
          color: '#25b7ab',
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        stroke: {
          curve: "smooth",
          // dashArray: [0, 0, 0, 0, 15, 15, 15, 15],
        },
        // colors: [
        //   process.env.VUE_APP_SECONDARY_COLOR,
        //   "#25b7ab",
        //   "#ee4e31",
        //   "#25b789",
        //   process.env.VUE_APP_SECONDARY_COLOR,
        //   "#25b7ab",
        //   "#ee4e31",
        //   "#25b789",
        // ],
        // colors: ["#2566cb","#2566cb", "#8cdcfe","#8cdcfe"],
        colors: [
          // "#edbc31",
          "#de6a2f",
          "#25b7ab",
          // "#2566cb",
        ],
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} °C`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    prepareData() {
      // const baseDataSets = this.prepareBaseData();
      const simulationDataSets = this.prepareSimulationData();

      const prefixSimulation = "";
      // const prefixBase = this.$t('general.base_data') + ': '

      this.chartSeries = [
        // {
        //   name: this.$t("panels.substation.data_fields.Tin_P"),
        //   data: baseDataSets.dataTInP,
        // },
        // {
        //   name: this.$t("panels.substation.data_fields.Tout_P"),
        //   data: baseDataSets.dataTOutP,
        // },
        // {
        //   name: this.$t("panels.substation.data_fields.Tout_S"),
        //   data: baseDataSets.dataTOutS,
        // },
        // {
        //   name: this.$t("panels.substation.data_fields.Tin_S"),
        //   data: baseDataSets.dataTInS,
        // },
      ];
      // if (simulationDataSets.dataTInP.length > 0) {
      this.chartSeries.push(
        {
          name:
            prefixSimulation + this.$t("panels.substation.data_fields.Tin_P"),
          data: simulationDataSets.dataTInP,
        },
        {
          name:
            prefixSimulation + this.$t("panels.substation.data_fields.Tout_P"),
          data: simulationDataSets.dataTOutP,
        },
        // {
        //   name:
        //     prefixSimulation +
        //     this.$t("panels.substation.data_fields.Tout_S"),
        //   data: simulationDataSets.dataTOutS,
        // },
        // {
        //   name:
        //     prefixSimulation + this.$t("panels.substation.data_fields.Tin_S"),
        //   data: simulationDataSets.dataTInS,
        // }
      );
      // }
    },
    prepareBaseData() {
      const dataTInP = this.prepareNormalizedData(this.baseData, 'Tin_P', 10);
      const dataTInS = this.prepareNormalizedData(this.baseData, 'Tin_S', 10);
      const dataTOutP = this.prepareNormalizedData(this.baseData, 'Tout_P', 10);
      const dataTOutS = this.prepareNormalizedData(this.baseData, 'Tout_S', 10);

      // for (const item of this.baseData) {
      //   const x = item.timestamp;
      //   if (item.Tin_P) {
      //     dataTInP.push({
      //       x,
      //       y: item.Tin_P,
      //     });
      //   }
      //   if (item.Tin_S) {
      //     dataTInS.push({
      //       x,
      //       y: item.Tin_S,
      //     });
      //   }
      //   if (item.Tout_P) {
      //     dataTOutP.push({
      //       x,
      //       y: item.Tout_P,
      //     });
      //   }
      //   if (item.Tout_S) {
      //     dataTOutS.push({
      //       x,
      //       y: item.Tout_S,
      //     });
      //   }
      // }

      return {
        dataTInP,
        dataTInS,
        dataTOutP,
        dataTOutS,
      };
    },
    prepareSimulationData() {
      const dataTInP = this.prepareNormalizedData(this.simulationData, 'Tin_P', 10);
      // const dataTInS = this.prepareNormalizedData(this.simulationData, 'Tin_S', 10);
      const dataTOutP = this.prepareNormalizedData(this.simulationData, 'Tout_P', 10);
      // const dataTOutS = this.prepareNormalizedData(this.simulationData, 'Tout_S', 10);

      // for (const item of this.simulationData) {
      //   const x = item.timestamp;
      //   dataTInP.push({
      //     x,
      //     y: item.Tin_P,
      //   });
      //   dataTInS.push({
      //     x,
      //     y: item.Tin_S,
      //   });
      //   dataTOutP.push({
      //     x,
      //     y: item.Tout_P,
      //   });
      //   dataTOutS.push({
      //     x,
      //     y: item.Tout_S,
      //   });
      // }

      return {
        dataTInP,
        // dataTInS,
        dataTOutP,
        // dataTOutS,
      };
    },
    tooltipFormatY(value) {
      return `${value?.toFixed(2)} °C`;
    },
  },
};
</script>
