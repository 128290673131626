<template>
  <div>
    <Units v-if="coolingHouseId" />
    <CoolingHouses v-else ref="coolingHouses"/>
  </div>
</template>

<script>
import CoolingHouses from './CoolingHouses.vue';
import Units from './units/Units.vue';

export default {
  name: 'CoolingHousesWrapper',
  components: {
    CoolingHouses,
    Units
},
  data () {
    return {
    }
  },
  computed: {
    coolingHouseId() {
      return this.$store.getters['energyPlanner/coolingHouse/coolingHouseId']
    },
  },
  mounted() {
    this.$store.dispatch('energyPlanner/coolingHouse/resetFlags')
  },
  beforeDestroy() {
    this.$store.dispatch('energyPlanner/coolingHouse/resetFlags')
  },
  methods: {
    onMapEnergyPlannerItemClick (coolingHouse) {
      this.$store.dispatch('energyPlanner/coolingHouse/leaveUnits')
      this.$nextTick(() => {
        this.$refs.coolingHouses.openEdit(coolingHouse)
      })
    }
  }
}
</script>
