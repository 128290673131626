<template>
  <panel name="settings" :initial-active="active">
    <v-card-text>
      <v-progress-linear :active="loading" indeterminate />
      <v-form ref="form" v-model="valid" @submit.prevent="save">
        <v-select
          :items="locales"
          v-model="editableUserSettings.locale"
          :label="$t('panels.settings.locale.label')"
          :error-messages="errorMessages('locale')"
        />
        <v-select
          :items="mapSources"
          v-model="editableUserSettings.map_source"
          :label="$t('panels.settings.map_source.label')"
          :error-messages="errorMessages('map_source')"
        />
        <v-select
          :items="edgeLabelData"
          v-model="editableUserSettings.edgeLabelData"
          :label="$t('panels.settings.label_data.edges.name')"
          :error-messages="errorMessages('edgeLabelData')"
        />
        <v-select
          :items="nodeLabelData"
          v-model="editableUserSettings.nodeLabelData"
          :label="$t('panels.settings.label_data.nodes.name')"
          :error-messages="errorMessages('nodeLabelData')"
        />
        <v-checkbox
          v-model="editableUserSettings.rawData.enabled"
          :label="$t('panels.settings.raw_data.enabled')"
          :error-messages="errorMessages('rawData.enabled')"
        ></v-checkbox>
        <v-slider
          :label="$t('panels.settings.raw_data.opacity')"
          v-model="editableUserSettings.rawData.opacity"
          min="0"
          max="1"
          :step="0.1"
          :tick-size="0.1"
        >
          <template v-slot:append>
            {{ rawDataOpacityAsPercent }}%
          </template>
        </v-slider>
      </v-form>
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="save"
        >{{ $t("general.save") }}</v-btn
      >
    </v-card-text>
  </panel>
</template>

<script>
import Panel from "../Panel.vue";
import { get } from 'lodash'

export default {
  name: "SettingsPanel",
  props: {
    active: Boolean,
  },
  components: {
    Panel,
  },
  data() {
    return {
      loading: false,
      options: {},
      errors: false,
      valid: true,
      editableUserSettings: {
        rawData: {}
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.editableUserSettings = {
        ...this.userSettings,
        rawData: {
          ...this.userSettings.rawData
        }
      };
    });
  },
  computed: {
    userSettings() {
      return this.$store.getters["account/userSettings"];
    },
    rawDataOpacityAsPercent () {
      const opacity = this.editableUserSettings.rawData.opacity ?? 0

      return (opacity * 100).toFixed(0)
    },
    locales() {
      const options = this.$store.getters["settings/locales"] ?? [];
      const items = [];

      for (const option of options) {
        items.push({
          value: option,
          text: this.$t("panels.settings.locale.values." + option),
        });
      }
      return items;
    },
    mapSources() {
      const options = this.$store.getters["settings/mapSources"] ?? [];
      // console.log(options);
      const items = [];
      for (const option of options) {
        items.push({
          value: option,
          text: this.$t("panels.settings.map_source.values." + option),
        });
      }
      return items;
    },
    edgeLabelData() {
      const options = this.$store.getters["settings/edgeLabelData"] ?? [];
      // console.log(options);
      const items = [];
      for (const option of options) {
        items.push({
          value: option,
          text: this.$t("panels.settings.label_data.edges.values." + option),
        });
      }
      return items;
    },
    nodeLabelData() {
      const options = this.$store.getters["settings/nodeLabelData"] ?? [];
      // console.log(options);
      const items = [];
      for (const option of options) {
        items.push({
          value: option,
          text: this.$t("panels.settings.label_data.nodes.values." + option),
        });
      }
      return items;
    },
  },
  watch: {
    userSettings(value) {
      this.editableUserSettings = {
        ...value,
        rawData: {
          ...value.rawData
        }
      };
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.errors = await this.$store.dispatch("account/updateUserSettings", {
        locale: this.editableUserSettings.locale,
        map_source: this.editableUserSettings.map_source,
        edgeLabelData: this.editableUserSettings.edgeLabelData,
        nodeLabelData: this.editableUserSettings.nodeLabelData,
        rawData: {
          enabled: this.editableUserSettings.rawData.enabled,
          opacity: this.editableUserSettings.rawData.opacity
        }
      });
      this.loading = false;
    },
    errorMessages(field) {
      if (this.errors) {
        return get(this.errors, field, '')
      }
      return ''
    },
  },
};
</script>
