import Api from "./Api";

export default {
  index () {
    return Api().get('/fields')
  },
  store (payload = {}) {
    return Api().post('/fields', { ...payload.item })
  },
  update (payload = {}) {
    return Api().post('/fields/' + payload.item.id, { ...payload.item })
  },
  updateGeometry (payload = {}) {
    return Api().post(`/fields/${payload.id}/geometry`, { geometry: payload.geometry, osm_id: payload.featureId, area: payload.area })
  },
  delete (payload = {}) {
    return Api().delete('/fields/' + payload.item.id)
  },
  storeCrop (payload = {}) {
    return Api().post(`/fields/${payload.fieldId}/crops`, { ...payload.item })
  },
  updateCrop (payload = {}) {
    return Api().post(`/fields/${payload.fieldId}/crops/${payload.item.id}`, { ...payload.item })
  },
  deleteCrop (payload = {}) {
    return Api().delete(`/fields/${payload.fieldId}/crops/${payload.item.id}`)
  },
}
