<template>
  <div>
    <h5>{{ $t('panels.energyPlanner.producer.' + producerPrefix) }}</h5>
    <div class="d-flex" style="gap: 8px">
      <template v-if="hasProducer">
        <v-btn color="accent" @click="producerEdit" :title="$t('general.edit')" :outlined="showForm"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn color="error" @click="producerDelete" :title="$t('general.delete')"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
      <template v-else>
        <v-btn color="accent" :title="$t('general.new')" @click="producerEdit"><v-icon>mdi-plus</v-icon></v-btn>
      </template>
    </div>
    <div v-if="showForm">
      <v-select
        :label="$t('panels.energyPlanner.attributes.building.unit.producer.fuel_type') + '*'"
        v-model="item[producerPrefix].fuel_type"
        :items="productionFuelTypes"
        :rules="[rules.required]"
        :error-messages="errorMessages(producerPrefix + '.fuel_type')"
      />
      <v-select
        :label="$t('panels.energyPlanner.attributes.building.unit.producer.storage_type')"
        v-model="item[producerPrefix].storage_type"
        :items="storageTypes"
        clearable
        @click:clear="onStorageTypeClear"
        :error-messages="errorMessages(producerPrefix + '.storage_type')"
      />
      <template v-if="item[producerPrefix].storage_type">
        <v-text-field
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.charging_start_hour')"
          type="number"
          min="0"
          max="24"
          v-model.number="item[producerPrefix].charging_start_hour"
          :rules="[rules.validOptionalInteger]"
          :error-messages="errorMessages(producerPrefix + '.charging_start_hour')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('charging_start_hour')"
          :suffix="$t('general.hour')"
        />
        <v-text-field
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.charging_end_hour')"
          type="number"
          min="0"
          max="24"
          v-model.number="item[producerPrefix].charging_end_hour"
          :rules="[rules.validOptionalInteger]"
          :error-messages="errorMessages(producerPrefix + '.charging_end_hour')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('charging_end_hour')"
          :suffix="$t('general.hour')"
        />
        <v-text-field
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.charging_hours')"
          type="number"
          v-model.number="item[producerPrefix].charging_hours"
          :rules="[rules.validOptionalInteger]"
          :error-messages="errorMessages(producerPrefix + '.charging_hours')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('charging_hours')"
          :suffix="$t('general.hours')"
        />
        <v-text-field
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.max_capacity_storage')"
          type="number"
          v-model.number="item[producerPrefix].max_capacity_storage"
          :rules="[rules.validOptionalFloat]"
          :error-messages="errorMessages(producerPrefix + '.max_capacity_storage')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('max_capacity_storage')"
        />
        <v-text-field
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.charging_capacity')"
          type="number"
          v-model.number="item[producerPrefix].charging_capacity"
          :rules="[rules.validOptionalFloat]"
          :error-messages="errorMessages(producerPrefix + '.charging_capacity')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('charging_capacity')"
        />
        <v-text-field v-if="item[producerPrefix].storage_type == 1"
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.starting_charge')"
          type="number"
          v-model.number="item[producerPrefix].starting_charge"
          :rules="[rules.validOptionalFloat]"
          :error-messages="errorMessages(producerPrefix + '.starting_charge')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('starting_charge')"
        />
        <v-text-field v-if="item[producerPrefix].storage_type == 1"
          :label="$t('panels.energyPlanner.attributes.building.unit.producer.starting_energy')"
          type="number"
          v-model.number="item[producerPrefix].starting_energy"
          :rules="[rules.validOptionalFloat]"
          :error-messages="errorMessages(producerPrefix + '.starting_energy')"
          persistent-placeholder
          :placeholder="storageDefaultPlaceholder('starting_energy')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import RulesUtil from '@/util/RulesUtil'
import { EnergyProductionFuelType, EnergyStorageType } from '@/util/structs'
import {get} from 'lodash'

export default {
  name: 'ProducerForm',
  props: {
    item: {
      fuel_type: Object,
      default: () => {}
    },
    errors: {
      type: [Boolean, Object],
      default: () => false
    },
    producerPrefix: String
  },
  data () {
    return {
      showForm: false
    }
  },
  computed: {
    rules () {
      return RulesUtil
    },
    hasProducer () {
      return !!this.item[this.producerPrefix]
    },
    productionFuelTypes() {
      const types = []
      for (const key in EnergyProductionFuelType) {
        types.push({ value: EnergyProductionFuelType[key], text: this.$t('panels.energyPlanner.production_fuel_type.' + key) })
      }

      return types
    },
    storageTypes () {
      const types = []
      for (const key in EnergyStorageType) {
        types.push({ value: EnergyStorageType[key], text: this.$t('panels.energyPlanner.storage_type.' + key)})
      }
      return types
    },
    storageDefaults () {
      const storageType = this.item[this.producerPrefix].storage_type
      if (!storageType) {
        return {}
      }

      if (EnergyStorageType.STANDARD == storageType) { // boiler?
        return {
          charging_start_hour: 0,
          charging_end_hour: 6,
          charging_hours: 6,
          starting_charge: 0,
          starting_energy: 0,
          max_capacity_storage: 4,
          charging_capacity: 1.5
        }
      } else if(EnergyStorageType.DYNASTRAT == storageType){
        return {
          charging_start_hour: 9,
          charging_end_hour: 17,
          charging_hours: 8,
          max_capacity_storage: 4,
          charging_capacity: 0.5
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    storageDefaultPlaceholder(key) {
      return this.storageDefaults[key]?.toString()
    },
    errorMessages(field) {
      if (this.errors) {
        return get(this.errors, field)
      }

      return ''
    },
    producerEdit () {
      if (!this.hasProducer) {
        this.item[this.producerPrefix] = {}
      }

      this.showForm = true
    },
    producerDelete() {
      this.showForm = false
      this.item[this.producerPrefix] = undefined
    },
    onStorageTypeClear () {
      // preserve only the fuel_type
      this.item[this.producerPrefix] = {
        fuel_type: this.item[this.producerPrefix].fuel_type
      }
    }
  },
}
</script>
