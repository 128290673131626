<template>
  <div>
    <portal to="energy-planner-fixed">
      <div class="mb-4">
        <v-btn color="accent" @click="() => openEdit()">
          {{ $t("panels.energyPlanner.fields.new_field") }}
        </v-btn>
      </div>
      <SearchAddress />
    </portal>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table :items="fields" :headers="headers" single-expand>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div class="d-flex justify-space-between">
                <div>
                  {{ item.name }}
                  <v-btn icon @click="() => openEdit(item)" :title="$t('general.edit')"
                    color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
                </div>
                <div class="d-flex justify-end">
                  <v-btn v-if="item.geom" icon @click="() => fitField(item.id)"
                    :title="$t('panels.energyPlanner.find_in_map')" color="accent">
                    <v-icon>mdi-map-search</v-icon>
                  </v-btn>
                  <v-btn icon :outlined="
                    clickStateDrawField && clickOptions.id === item.id
                  " @click="() => drawField(item)" :title="$t('panels.energyPlanner.fields.draw_field')"
                    color="accent">
                    <v-icon>mdi-draw</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => viewCrops(item)" color="accent">
                    <v-icon>mdi-hexagon-multiple-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="() => openDelete(item)" :title="$t('general.delete')"
                    color="error"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </td>
            <td class="text-right" :style="{ width: '1%' }">
              {{ fieldCropsCount(item) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <FieldEditDialog v-if="editOpen" :item="editItem" @close="closeEdit" />
    <FieldDeleteDialog v-if="deleteOpen" :item="deleteItem" @close="closeDelete" />
  </div>
</template>

<script>
import { clickStates } from '@/util/structs'
import SearchAddress from '../common/SearchAddress.vue'
import FieldEditDialog from './FieldEditDialog.vue'
import FieldDeleteDialog from './FieldDeleteDialog.vue'

export default {
  name: 'Fields',
  components: {
    SearchAddress,
    FieldEditDialog,
    FieldDeleteDialog
},
  data () {
    return {
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false
    }
  },
  computed: {
    clickStates() {
      return clickStates
    },
    clickState() {
      return this.$store.getters['map/clickState']
    },
    clickOptions() {
      return this.$store.getters['map/clickOptions']
    },
    clickStateQueryField() {
      return this.clickState === clickStates.FIELD_QUERY
    },
    clickStateDrawField() {
      return this.clickState === clickStates.FIELD_DRAW
    },
    fieldQueryItem() {
      return this.$store.getters['energyPlanner/field/fieldQueryItem']
    },
    hasFieldQueryItem() {
      return this.fieldQueryItem !== undefined
    },
    fieldQueryPoint() {
      return this.$store.getters['energyPlanner/field/fieldQueryPoint']
    },
    fields() {
      return this.$store.getters['energyPlanner/field/fields']
    },
    loading() {
      return this.$store.getters['energyPlanner/loading']
    },
    queryDone() {
      return this.$store.getters['energyPlanner/field/queryDone']
    },
    panelErrors() {
      return this.$store.getters['energyPlanner/panelErrors']
    },
    mapComponent() {
      return this.$store.getters['map/component']
    },
    headers() {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('panels.energyPlanner.fields.crops'),
          value: 'crops.length',
          align: 'end'
        }
      ]
    }
  },
  watch: {
    fieldQueryItem(value) {
      if (value !== undefined && this.overpassItemHasAddress(value)) {
        this.address = this.queryItemName(value)
      }
    },
    clickState(value) {
      if (value === clickStates.IDLE) {
        this.address = ''
      }
    },
  },
  methods: {
    fieldCropsCount(field) {
      return field.crops.length ?? 0
    },
    refreshFields() {
      this.$store.dispatch('energyPlanner/field/loadData')
    },
    viewCrops(field) {
      this.$store.dispatch('energyPlanner/field/viewCrops', field.id)
    },
    closeEdit() {
      this.editOpen = false
      this.editItem = undefined
    },
    openEdit(item) {
      this.editItem = item ? { ...item } : undefined
      this.editOpen = true
    },
    openDelete(item) {
      this.deleteItem = item
      this.deleteOpen = true
    },
    closeDelete() {
      this.deleteOpen = false
      this.deleteItem = undefined
    },
    resetClickState() {
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      })
    },
    drawFieldActive(item) {
      return this.clickStateDrawField && this.clickOptions.id === item.id
    },
    drawField(item) {
      if (this.drawFieldActive(item)) {
        this.resetClickState()
        return
      }

      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.FIELD_DRAW,
        clickOptions: { id: item.id }
      })
    },
    fitField(id) {
      this.fitFeature(id, 'fields')
    },
    fitQueryItem(id) {
      this.fitFeature(id ?? 'QueryPoint', 'drawing')
    },
    fitFeature(id, layer) {
      if (!this.mapComponent) return

      this.mapComponent.locateFeature(layer, id)
    }
  }
}
</script>
