import { clickStates } from "@/util/structs"
import OverpassApiService from '@/services/OverpassApiService'
import BuildingService from "@/services/BuildingService"
import GeocodeService from "@/services/GeocodeService"

import i18n from '@/lang/lang'
import turfArea from '@turf/area'
import { roundWithPrecision } from "@/util/NumberUtil"

// initial state
const getDefaultState = () => {
  return {
    queryDone: false,
    buildings: [],
    buildingQueryItem: undefined,
    buildingQueryPoint: undefined,
    buildingId: undefined
  }
}

const state = getDefaultState()

// getters
const getters = {
  queryDone: state => state.queryDone,
  buildings: state => state.buildings,
  buildingId: state => state.buildingId,
  building(state) {
    return state.buildings.find(item => item.id === state.buildingId)
  },
  buildingQueryItem: state => state.buildingQueryItem,
  buildingQueryPoint: state => state.buildingQueryPoint
}

// actions
const actions = {
  resetFlags({ commit }) {
    commit('queryDone', false)
    commit('buildingQueryItem', undefined)
    commit('buildingQueryPoint', undefined)
    commit('buildingId', undefined)
  },
  leaveUnits({ commit }) {
    commit('buildingId', undefined)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  viewUnits({ commit }, id) {
    commit('buildingId', id)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  async loadData({ commit }) {
    try {
      const response = await BuildingService.index()
      commit('buildings', response.data.buildings)
    } catch (e) {
      console.error(e)
    }
  },
  resetState({ commit }) {
    commit('resetState')
  },
  initQueryBuilding({ commit, dispatch }, payload) {
    commit('queryDone', false)
    commit('buildingQueryItem', undefined)
    commit('buildingQueryPoint', undefined)
    commit('energyPlanner/panelErrors', false, { root:true })

    dispatch('map/setClickState', {
      clickState: clickStates.BUILDING_QUERY,
      clickOptions: payload
    }, { root: true })
  },
  async buildingQueryPoint({ commit, rootGetters }, payload) {
    commit('energyPlanner/loading', true, { root:true })
    commit('queryDone', false)
    commit('energyPlanner/panelErrors', false, { root:true })
    const { lon, lat } = payload
    commit('buildingQueryPoint', { lon, lat })
    commit('buildingQueryItem', undefined)
    let buildingQueryItem
    let multipleQueryResults = false
    try {
      const response = await OverpassApiService.queryPoint(lon, lat)
      buildingQueryItem = response.data.elements[0]
      multipleQueryResults = response.data.elements.length > 1

      const mapComponent = rootGetters['map/component']
      mapComponent.createOverpassQueryFeature(buildingQueryItem, { lon, lat })
    } catch {
      commit('energyPlanner/panelErrors', { buildingQueryPoint: i18n.t('panels.energyPlanner.errors.overpass_query_failed') }, { root:true })
    }

    if (multipleQueryResults) {
      commit('energyPlanner/panelErrors', { buildingQueryPoint: i18n.t('panels.energyPlanner.errors.multiple_query_results') }, { root:true })
    }

    commit('buildingQueryItem', buildingQueryItem)
    commit('queryDone', true)
    commit('energyPlanner/loading', false, { root:true })
  },
  async buildingQueryAddress({ commit, rootGetters }, payload) {
    commit('energyPlanner/loading', true, { root:true })
    commit('queryDone', false)
    commit('energyPlanner/panelErrors', false, { root:true })
    commit('buildingQueryPoint', undefined)
    commit('buildingQueryItem', undefined)
    let buildingQueryItem
    let queryError = false
    let queryOverpass = false
    let multipleQueryResults = false
    let location
    // first geocode address query
    try {
      const geocodeResponse = await GeocodeService.geocode(payload.address)
      location = geocodeResponse.data.location
      multipleQueryResults = (geocodeResponse.data.count ?? 0) > 1
      if (location.lon !== null && location.lat !== null) {
        // create a point feature in the map
        commit('buildingQueryPoint', location)
        queryOverpass = true
      } else {
        queryError = true
      }
    } catch (e) {
      console.error(e)
      queryError = true
    }

    // perform overpass query with geocoded coordinates
    if (queryOverpass) {
      try {
        const response = await OverpassApiService.queryPoint(location.lon, location.lat)
        buildingQueryItem = response.data.elements[0]
        if (multipleQueryResults === false) {
          multipleQueryResults = response.data.elements.length > 1
        }
      } catch (e) {
        console.error(e)
        queryError = true
      }
    }

    const mapComponent = rootGetters['map/component']
    mapComponent.createOverpassQueryFeature(buildingQueryItem, location)

    if (queryError) {
      commit('energyPlanner/panelErrors', { buildingQueryAddress: i18n.t('panels.energyPlanner.errors.address_not_found') }, { root:true })
    } else if (multipleQueryResults) {
      commit('energyPlanner/panelErrors', { buildingQueryAddress: i18n.t('panels.energyPlanner.errors.multiple_query_results') }, { root:true })
    }
    commit('buildingQueryItem', buildingQueryItem)
    commit('queryDone', true)
    commit('energyPlanner/loading', false, { root:true })
  },
  cancelQueryPoint({ commit, dispatch }) {
    commit('buildingQueryItem', undefined)
    commit('queryDone', false)
    commit('energyPlanner/loading', false, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    dispatch('map/setClickState', {
      clickState: clickStates.IDLE
    }, { root: true })
  },
  async saveQueryPoint({ state, dispatch }, payload) {
    if (state.buildingQueryPoint === undefined) {
      console.error("no query point")
      return
    }

    const geometry = {
      type: "Point",
      coordinates: [state.buildingQueryPoint.lon, state.buildingQueryPoint.lat]
    }

    const errors = await dispatch('saveQueryGeometry', {
      id: payload.id,
      geometry
    })

    if (!errors) {
      dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      }, { root: true })
    }
  },
  async saveQueryItem({ rootGetters, dispatch }, payload) {
    const component = rootGetters['map/component']
    if (!component) {
      console.error("no map component")
      return
    }
    const geometry = component.getDrawingFeatureGeometry(payload.featureId)

    if (!geometry) {
      console.error("no geometry")
      return
    }

    const errors = await dispatch('saveQueryGeometry', {
      id: payload.id,
      featureId: payload.featureId,
      geometry
    })

    if (!errors) {
      dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      }, { root: true })
    }
  },
  async saveDrawnGeometry({ commit, dispatch }, payload) {
    commit('queryDone', true)
    return await dispatch('saveQueryGeometry', payload)
  },
  async saveQueryGeometry({ commit, rootGetters }, payload) {
    commit('energyPlanner/panelErrors', false, { root:true })
    commit('energyPlanner/loading', true, { root:true })
    let errors = false

    const geometry = payload.geometry

    try {

      const response = await BuildingService.updateGeometry({
        id: payload.id,
        featureId: payload.featureId,
        geometry: JSON.stringify(geometry),
        area: (geometry.type === "Point") ? undefined : roundWithPrecision(turfArea(geometry), 2)
      })
      const building = response.data.building
      commit('saveBuildingGeometry', building)
      // update building feature
      const mapComponent = rootGetters['map/component']
      mapComponent.refreshBuildingFeature(building)
      commit('queryDone', false)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }
    commit('energyPlanner/panelErrors', errors, { root:true })
    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async saveBuilding({ commit }, building) {
    commit('energyPlanner/loading', true, { root:true })
    const action = building.id !== undefined ? 'update' : 'store'

    let errors = false
    try {
      const response = await BuildingService[action]({
        item: building
      })
      commit('saveBuilding', response.data.building)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteBuilding({ commit }, building) {
    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await BuildingService.delete({
        item: building
      })
      commit('deleteBuilding', building)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
  async saveUnit({ commit }, payload) {
    const unit = payload.unit
    const buildingId = payload.buildingId
    if (!buildingId) return

    commit('energyPlanner/loading', true, { root:true })
    const action = unit.id !== undefined ? 'updateUnit' : 'storeUnit'
    let errors = false
    try {
      const response = await BuildingService[action]({
        buildingId,
        item: unit
      })
      commit('saveUnit', {
        buildingId,
        unit: response.data.unit
      })
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteUnit({ commit }, payload) {
    const buildingId = payload.buildingId
    if (!buildingId) return
    const unit = payload.unit

    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await BuildingService.deleteUnit({
        buildingId,
        item: unit
      })
      commit('deleteUnit', payload)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
}

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  queryDone(state, queryDone) {
    state.queryDone = queryDone
  },
  buildingId(state, buildingId) {
    state.buildingId = buildingId
  },
  buildings(state, buildings) {
    state.buildings = buildings
  },
  buildingQueryItem(state, buildingQueryItem) {
    state.buildingQueryItem = buildingQueryItem
  },
  buildingQueryPoint(state, buildingQueryPoint) {
    state.buildingQueryPoint = buildingQueryPoint
  },
  saveBuilding(state, building) {
    const buildings = state.buildings

    const index = buildings.findIndex(t => t.id === building.id)
    if (index === -1) {
      buildings.push(building)
    } else {
      buildings[index] = building
    }

    state.buildings = [
      ...buildings
    ]
  },
  saveUnit(state, payload) {
    const buildingIndex = state.buildings.findIndex(t => t.id === payload.buildingId)
    if (buildingIndex === -1) {
      return // building not found - cant save unit
    }

    const building = state.buildings[buildingIndex]

    const unit = payload.unit
    const units = building.units ?? []
    const unitIndex = units.findIndex(t => t.id === unit.id)
    if (unitIndex === -1) {
      units.push(unit)
    } else {
      units[unitIndex] = unit
    }

    building.units = [...units]
  },
  saveBuildingGeometry(state, building) {
    const buildings = state.buildings
    const index = buildings.findIndex(t => t.id === building.id)
    if (index !== -1) {
      buildings[index] = {
        ...state.buildings[index],
        area: building.area,
        geom: building.geom
      }
      state.buildings = [...buildings]
    }
  },
  deleteBuilding(state, building) {
    const buildings = state.buildings

    state.buildings = buildings.filter(t => t.id !== building.id)
  },
  deleteUnit(state, payload) {
    const buildingIndex = state.buildings.findIndex(t => t.id === payload.buildingId)
    if (buildingIndex === -1) return

    const building = state.buildings[buildingIndex]
    building.units = building.units.filter(t => t.id !== payload.unit.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
