<template>
  <div>
    <div class="ma-5">
      <v-icon x-large color="secondary" v-if="item.supply">
        mdi-arrow-up-bold-circle-outline
      </v-icon>
      <v-icon x-large color="primary" v-else-if="item.supply === false">
        mdi-arrow-down-bold-circle-outline
      </v-icon>
    </div>
    <v-simple-table style="width: 100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('general.attribute') }}</th>
            <th class="text-right">{{ $t('general.value') }}</th>
            <th class="text-right" style="width: 10%">{{ $t('general.unit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(att, index) in itemAttributes" :key="index">
            <td>{{ att.key }}</td>
            <td class="text-right">{{ att.value }}</td>
            <td class="text-right">{{ att.unit }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="ma-5" v-if="additionalItemAttributes">
      {{ additionalItemAttributes }}
    </div>
  </div>
</template>
<script>
import OlUtil from '@/util/OlUtil';

export default {
  name: "GeneralInfoTable",
  props: {
    item: Object,
    fields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userConfig() {
      return this.$store.getters["account/config"];
    },
    itemAttributes() {
      let result = [];
      const filter = ["project_id", "rented", "supply", "ua", "fid", 'supply_node_id', 'return_node_id', 'node_up_id', 'node_down_id', "name","area", "singleloss"];
      // const fields = this.fields.length > 0 ? this.fields : Object.keys(this.item)
      // for (let name of fields) {
      for (let name in this.item) {
        if (!filter.includes(name) && this.item[name]) {
          if (name == "geom" && (this.item[name].type == "Polygon" || this.item[name].type == "MultiPolygon")) {
            continue;
          }
          let row = {};
          row.key = this.$t("general." + name);
          if (typeof this.item[name] == "number") {
            const {digits, fixed} = this.getAttributePrecision(name)
            row.value = this.roundToPrecision(this.item[name] * this.getAttributeMultiplier(name), digits, fixed)
          } else if (name == "geom") {
            let geom = [...this.item.geom]
            // need to convert if displayed projection is defined
            const projectionName = this.userConfig?.displayed_projection?.name
            let digits = 6
            if (projectionName) {
              geom = OlUtil.transformCoordinates(geom, 'EPSG:4326', projectionName)
              digits = 0
            }
            row.value = `[${OlUtil.displayCoordinates(geom, digits)}]`
          } else {
            row.value = this.item[name];
          }
          row.unit = this.getAttributeUnit(name);
          result.push(row);
        }
      }
      return result;
    },
    additionalItemAttributes() {
      const fields = ['fid', 'supply_node_id', 'return_node_id', 'node_up_id', 'node_down_id']
      const output = []

      for (const field of fields) {
        const value = this.item[field]
        if (value) {
          const label = this.$t(`general.${field}`)

          output.push(`${label}: ${value}`)
        }
      }

      if (output.length) {
        return output.join(', ')
      }

      return undefined
    }
  },
  methods: {
    roundToPrecision(value, precision, fixed = false) {
      if (fixed) {
        return value.toFixed(precision)
      } else {
        const factor = 10 ** precision
        return Math.round(value * factor) / factor
      }
    },
    getAttributePrecision(attributeName) {
      let digits = 4
      let fixed = false
      switch (attributeName) {
        case 'edgelength':
          digits = 2
          break;
        case 'diameter':
          digits = 2
          fixed = true
          break;
        case 'pressure':
          digits = 1
          break;
        case 'temperature':
          digits = 0
          fixed = true
          break;
      }

      return {digits, fixed}
    },
    getAttributeMultiplier(attributeName) {
      switch (attributeName) {
        case 'diameter':
          return 100
        case 'roughness':
        case 'htc':
          return 1000
        default:
          return 1
      }
    },
    getAttributeUnit(attributeName) {
      let unit = "";
      //   console.log(attributeName.toString());
      switch (attributeName) {
        case "edgelength":
          unit = "m";
          break
        case "diameter":
          unit = "cm";
          break
        case "roughness":
          unit = "mm";
          break;
        case "htc":
          unit = "W/mK";
          break;
        case "ua":
          unit = "kW/K";
          break;
        case "phi":
          unit = "kW/K";
          break;
        case "mass":
          unit = "kg/s";
          break;
        case "area":
          unit = "m²";
          break;
        case "power":
          unit = "kW";
          break;
        case "pressure":
          unit = "bar"
          break
        case "temperature":
          unit = "°C"
          break
        default:
          break;
      }
      return unit;
    },
  },
};
</script>
