import Api from "./Api";

export default {
  index () {
    return Api().get('/buildings')
  },
  store (payload = {}) {
    return Api().post('/buildings', { ...payload.item })
  },
  update (payload = {}) {
    return Api().post('/buildings/' + payload.item.id, { ...payload.item })
  },
  updateGeometry (payload = {}) {
    return Api().post(`/buildings/${payload.id}/geometry`, { geometry: payload.geometry, osm_id: payload.featureId, area: payload.area })
  },
  delete (payload = {}) {
    return Api().delete('/buildings/' + payload.item.id)
  },
  storeUnit (payload = {}) {
    return Api().post(`/buildings/${payload.buildingId}/units`, { ...payload.item })
  },
  updateUnit (payload = {}) {
    return Api().post(`/buildings/${payload.buildingId}/units/${payload.item.id}`, { ...payload.item })
  },
  deleteUnit (payload = {}) {
    return Api().delete(`/buildings/${payload.buildingId}/units/${payload.item.id}`)
  },
}
