<template>
  <div style="height: 654px;">
    <!-- <h2>{{ this.$t("general.general_data") }}</h2> -->
    <!-- <v-divider></v-divider> -->
    <general-info-table :item="item"/>

  </div>
</template>

<script>
import GeneralInfoTable from "../../../../components/GeneralInfoTable.vue";
export default {
  name: "NodeMapPopupGeneral",
  components: { GeneralInfoTable },
  data() {
    return {};
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
  },
};
</script>
