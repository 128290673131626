<template>
  <panel name="storage" :initial-active="active">
    <template v-slot:fixed-content>
      <v-card-text class="pb-0">
        <v-btn color="primary" @click="newStorage">{{ $t('general.new') }}</v-btn>
      </v-card-text>
    </template>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        single-expand
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn icon :disabled="!item.geom" @click="() => locateStorage(item)" :title="$t('general.locate_on_map')" color="primary"><v-icon>mdi-map-marker-circle</v-icon></v-btn>
          <v-btn icon @click="() => editStorage(item)" :title="$t('general.edit')" color="primary"><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-btn>
          <v-btn icon @click="() => editStorage(item)" :title="$t('general.edit')" color="green"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon @click="() => deleteStorage(item)" :title="$t('general.delete')" color="error"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="(headers.length + 1)">
            <div class="text-center">
              <v-btn :outlined="clickState === clickStates.STORAGE_PLACE_GEOM && clickOptions.id === item.id" class="mr-1" @click="() => placeGeom(item)" color="primary"><v-icon>mdi-map-marker-down</v-icon>{{ $t('panels.storage.btn_place_geom') }}</v-btn>
              <v-btn :outlined="clickState === clickStates.STORAGE_CHOOSE_SUPPLY && clickOptions.id === item.id" class="mr-1" @click="() => chooseSupplyNode(item)" color="primary"><v-icon>mdi-map-marker-down</v-icon>{{ $t('panels.storage.btn_place_supply') }}</v-btn>
              <v-btn :outlined="clickState === clickStates.STORAGE_CHOOSE_RETURN && clickOptions.id === item.id" @click="() => chooseReturnNode(item)" color="primary"><v-icon>mdi-map-marker-down</v-icon>{{ $t('panels.storage.btn_choose_return') }}</v-btn>
            </div>
            <v-alert color="error" v-if="panelErrors && panelErrors.geometry" type="error" class="mt-3">
              {{ panelErrors.geometry[0] }}
            </v-alert>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <storage-edit-dialog v-if="editOpen" :item="editItem" :open="editOpen" @close="closeEdit" />
    <storage-delete-dialog v-if="deleteOpen" :item="deleteItem" :open="deleteOpen" @close="closeDelete" />
  </panel>
</template>

<script>
import Panel from '../Panel.vue'
import StorageEditDialog from './StorageEditDialog.vue'
import StorageDeleteDialog from './StorageDeleteDialog.vue'
import { clickStates } from '../../../util/structs'

export default {
  name: 'StoragePanel',
  props: {
    active: Boolean
  },
  components: {
    Panel,
    StorageEditDialog,
    StorageDeleteDialog
  },
  data () {
    return {
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      expanded: []
    }
  },
  computed: {
    clickStates () {
      return clickStates
    },
    panelErrors () {
      return this.$store.getters['storage/panelErrors']
    },
    items () {
      return this.$store.getters['storage/items']
    },
    mapComponent () {
      return this.$store.getters['map/component']
    },
    clickState () {
      return this.$store.getters['map/clickState']
    },
    clickOptions () {
      return this.$store.getters['map/clickOptions']
    },
    headers () {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('general.actions'),
          value: 'actions',
          align: 'end',
          filterable: false,
          sortable: false
        }
      ]
    }
  },
  watch: {
    expanded (newValue, oldValue) {
      if (newValue.length === 0 || newValue[0] !== oldValue[0]) {
        this.resetClickState()
      }
    }
  },
  methods: {
    closeEdit () {
      this.editOpen = false
      this.editItem = undefined
    },
    newStorage () {
      this.openEdit()
    },
    editStorage (item) {
      this.openEdit(item)
    },
    openEdit (item) {
      this.editItem = item ? { ...item } : undefined
      this.editOpen = true
    },
    deleteStorage (item) {
      this.deleteItem = item
      this.deleteOpen = true
    },
    closeDelete () {
      this.deleteOpen = false
      this.deleteItem = undefined
    },
    locateStorage (item) {
      this.mapComponent.locateStorage(item.id)
    },
    placeGeom (item) {
      if (this.clickState === clickStates.STORAGE_PLACE_GEOM) {
        this.resetClickState()
        return
      }
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.STORAGE_PLACE_GEOM,
        clickOptions: {
          id: item.id
        }
      })
    },
    chooseReturnNode (item) {
      if (this.clickState === clickStates.STORAGE_CHOOSE_RETURN) {
        this.resetClickState()
        return
      }
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.STORAGE_CHOOSE_RETURN,
        clickOptions: {
          id: item.id,
          oldId: item.return_node_id,
          supply: false
        }
      })
    },
    chooseSupplyNode (item) {
      if (this.clickState === clickStates.STORAGE_CHOOSE_SUPPLY) {
        this.resetClickState()
        return
      }
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.STORAGE_CHOOSE_SUPPLY,
        clickOptions: {
          id: item.id,
          oldId: item.supply_node_id,
          supply: true
        }
      })
    },
    resetClickState () {
      this.$store.dispatch('storage/resetPanelError')
      this.$store.dispatch('map/setClickState', {
        clickState: clickStates.IDLE
      })
    }
  }
}
</script>
