<template>
  <div>
    <div>
      <h5>{{ $t('panels.energyPlanner.pv_module.section_header') }}</h5>
      <div class="d-flex" style="gap: 8px">
        <template v-if="item.pv_module">
          <v-btn color="accent" @click="pvModuleEdit" outlined :title="$t('general.edit')"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn color="error" @click="pvModuleDelete" :title="$t('general.delete')"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <template v-else>
          <v-btn color="accent" :title="$t('general.new')" @click="pvModuleEdit"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
      </div>
    </div>
    <div v-if="showForm">
      <v-row align="center">
        <v-col cols="12" sm="6">
          <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.kwp') + '*'" type="number"
            v-model.number="item.pv_module.kwp" :rules="[rules.required, rules.validFloat]"
            :error-messages="errorMessages('pv_module.kwp')" suffix="kWp" />
        </v-col>
        <v-col cols="12" sm="6">
          <template v-if="configureModule">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.investment')" type="number"
              v-model.number="item.pv_module.investment" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.investment')" persistent-placeholder suffix="€/kWp"
              :placeholder="defaultPvModule.investment.toString()" />
          </template>
          <template v-else>
            <v-btn @click="configureModule = true">{{ $t('panels.energyPlanner.pv_module.configure') }}</v-btn>
          </template>
        </v-col>
      </v-row>
      <template v-if="configureModule">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.insuranceprovision')" type="number"
              v-model.number="item.pv_module.insuranceprovision" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.insuranceprovision')" persistent-placeholder
              :suffix="'€/' + $t('general.month')" :placeholder="defaultPvModule.insuranceprovision.toString()" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.subsidy')" type="number"
              v-model.number="item.pv_module.subsidy" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.subsidy')" persistent-placeholder suffix="€/kWp"
              :placeholder="defaultPvModule.subsidy.toString()" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.fixoperationcosts')" type="number"
              v-model.number="item.pv_module.fixoperationcosts" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.fixoperationcosts')" persistent-placeholder
              :suffix="'€/' + $t('general.month')" :placeholder="defaultPvModule.fixoperationcosts.toString()" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.installationcosts')" type="number"
              v-model.number="item.pv_module.installationcosts" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.installationcosts')" persistent-placeholder suffix="€"
              :placeholder="defaultPvModule.installationcosts.toString()" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.creditperiod')" type="number"
              v-model.number="item.pv_module.creditperiod" :rules="[rules.validOptionalInteger]"
              :error-messages="errorMessages('pv_module.creditperiod')" persistent-placeholder
              :suffix="$t('general.years')" :placeholder="defaultPvModule.creditperiod.toString()" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.lifetime')" type="number"
              v-model.number="item.pv_module.lifetime" :rules="[rules.validOptionalInteger]"
              :error-messages="errorMessages('pv_module.lifetime')" persistent-placeholder :suffix="$t('general.years')"
              :placeholder="defaultPvModule.lifetime.toString()" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.contractor')" type="number"
              v-model.number="item.pv_module.contractor" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.contractor')" persistent-placeholder suffix="%"
              :placeholder="defaultPvModule.contractor.toString()" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.pv_module.financinginterest')" type="number"
              v-model.number="item.pv_module.financinginterest" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('pv_module.financinginterest')" persistent-placeholder suffix="%"
              :placeholder="defaultPvModule.financinginterest.toString()" />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import RulesUtil from '@/util/RulesUtil';
import { get } from 'lodash'

const defaultPvModule = {
  investment: 1500,
  insuranceprovision: 0.5,
  subsidy: 100,
  fixoperationcosts: 10,
  installationcosts: 2000,
  creditperiod: 15,
  lifetime: 25,
  contractor: 2,
  financinginterest: 2
}

export default {
  name: 'PvModuleForm',
  props: {
    errors: {
      type: [Boolean, Object],
      default: false
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      showForm: false,
      configureModule: false
    }
  },
  computed: {
    rules() {
      return RulesUtil
    },
    defaultPvModule() {
      return defaultPvModule
    }
  },
  methods: {
    errorMessages(field) {
      if (this.errors) {
        return get(this.errors, field)
      }

      return ''
    },
    pvModuleEdit() {
      if (!this.item.pv_module) {
        this.item.pv_module = {}
      }
      this.showForm = true
    },
    pvModuleDelete() {
      this.showForm = false
      this.item.pv_module = undefined
    },
  }
}
</script>
