<template>
  <v-row>
    <v-col offset-sm="2" sm="8" offset-md="3" md="6" cols="12">
      <v-form ref="form" v-model="valid" @submit.prevent="submit">
        <v-card>
          <template v-if="registered">
            <v-card-title>{{ $t("auth.register.done_title") }}</v-card-title>
            <v-card-text>
              {{ $t("auth.register.done_text") }}
            </v-card-text>
          </template>
          <template v-else>
            <v-card-title>{{ $t("auth.register.title") }}</v-card-title>
            <v-card-subtitle
              >
              {{ $t("auth.register.subtitle") }}

              </v-card-subtitle
            >
            <v-card-text>
              <v-text-field
                :rules="[rules.required, rules.maxLength(255)]"
                label="Name"
                counter="255"
                :error="!!errorMessages.name"
                :error-messages="errorMessages.name"
                placeholder=" "
                v-model="item.name"
              ></v-text-field>
              <v-text-field
                :rules="[
                  rules.required,
                  rules.validEmail,
                  rules.maxLength(255),
                ]"
                label="Email"
                counter="255"
                :error="!!errorMessages.email"
                :error-messages="errorMessages.email"
                placeholder=" "
                v-model="item.email"
              ></v-text-field>
              <v-text-field
                :rules="[ruleEmailConfirmed]"
                label="Email confirmation"
                placeholder=" "
                v-model="item.email_confirmation"
              ></v-text-field>
              <v-text-field
                :rules="[rules.required, rules.minLength(8)]"
                :error="!!errorMessages.password"
                :error-messages="errorMessages.password"
                label="Password"
                type="password"
                placeholder=" "
                v-model="item.password"
              >
              </v-text-field>
              <v-text-field
                :rules="[rulePasswordConfirmed]"
                label="Password confirmation"
                type="password"
                placeholder=" "
                v-model="item.password_confirmation"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-checkbox
                prepend-icon="mdi-file-document-edit-outline"
                :rules="[rules.required]"
              >
                <template v-slot:label>
                  <div>
                    I agree to the
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://www.arteria-tech.com/arteria-agb.html"
                          @click.stop
                          v-on="on"
                        >
                          Terms of Service
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                    of the Arteria Platform.
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                prepend-icon="mdi-shield-key"
                :rules="[rules.required]"
              >
                <template v-slot:label>
                  <div>
                    I agree to the
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://www.arteria-tech.com/arteria-privacy.html"
                          @click.stop
                          v-on="on"
                        >
                          Data Privacy Act (GDPR)
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                    of the Arteria Technologies Platform.
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" type="submit">
                {{ $t("auth.register.submit") }}
                <v-progress-circular
                  v-if="loading"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>

import AccountService from "../../services/AccountService";
import RulesUtil from "../../util/RulesUtil";

export default {
  name: "Register",
  data() {
    return {
      valid: false,
      loading: false,
      registered: false,
      item: {
        name: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
      },
      error: false,
      errorMessages: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await AccountService.register(this.item);
          this.error = false;
          this.errorMessages = {};
          this.registered = true;
        } catch (e) {
          if (e.response && e.response.status === 422) {
            this.error = true;
            this.errorMessages = e.response.data.errors;
          }
        }
        this.loading = false;
      }
    },
    rulePasswordConfirmed(value) {
      if (value !== this.item.password) {
        return this.$t("rules.samePassword");
      }

      return true;
    },
    ruleEmailConfirmed(value) {
      if (value !== this.item.email) {
        return this.$t("rules.sameEmail");
      }

      return true;
    },
  },
  computed: {
    rules() {
      return RulesUtil;
    },
  },
  watch: {
    "item.name"() {
      if (this.error && this.errorMessages.name) {
        delete this.errorMessages.name;
      }
    },
    "item.email"() {
      if (this.error && this.errorMessages.email) {
        delete this.errorMessages.email;
      }
    },
    "item.password"() {
      if (this.error && this.errorMessages.password) {
        delete this.errorMessages.password;
      }
    },
  },
};
</script>
