<template>
  <div>
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <!-- <date-picker :date_end.sync="date_end" :disabled="loading" /> -->
    <date-picker :date.sync="date" :date_end.sync="date_end" :disabled="loading" />
    <!-- <date-picker v-model="date" :disabled="loading" /> -->
    <div class="d-flex align-center justify-center mb-2 flex-wrap" style="margin-top: -12px; gap: 4px;"
      v-if="dataAggregates">
      <v-chip v-for="field in kpiFields" :key="field" :color="dataAggregatesSettings[field].color" label
        :style="{ color: dataAggregatesSettings[field].textColor }">
        {{ dataAggregatesSettings[field].label }}: {{ dataAggregatesValueUnit(field) }}
      </v-chip>
    </div>
    <v-divider />
    <v-alert type="warning" color="secondary" v-if="data && data.simulation_data && data.simulation_data.length < 1
      ">{{ $t("general.no_data_yet") }}</v-alert>
    <apexchart type="line" height="500" :options="chartOptions" :series="chartSeries" />

  </div>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import NormalizeDataSeries from "../../../../util/NormalizeDataSeries.js";
import { WATER_CP } from "@/util/EnvConfig"

export default {
  mixins: [NormalizeDataSeries],
  components: { DatePicker },
  name: "PlantMapPopupEnergy",
  data() {
    return {
      chartSeries: []
    };
  },
  computed: {
    item() {
      return this.$store.getters["mapPopup/item"];
    },
    loading() {
      return this.$store.getters["mapPopup/loading"];
    },
    data() {
      return this.$store.getters["mapPopup/data"];
    },
    baseData() {
      return this.data?.base_data ?? [];
    },
    simulationData() {
      return this.data?.simulation_data ?? [];
    },
    hasSupplyOpti() {
      return this.simulationData.some(item => (item.T_supply_opti ?? 0) > 0)
    },
    kpiFields() {
      const fields = ['min', 'max', 'total']

      if (this.hasSupplyOpti) {
        fields.push('saved')
      }

      return fields
    },
    optimizedPower() {
      if (!this.hasSupplyOpti) {
        return
      }

      const simulationData = this.simulationData
      const baseData = this.baseData
      const optimizedPower = []

      for (let i = 0; i < simulationData.length; i++) {
        const simulationDataItem = simulationData[i]
        const baseDataItem = baseData[i]
        const {
          timestamp,
          Thermal_Power: thermalPower,
          T_supply_opti: tSupplyOpti,
          mass_flow: massFlow
        } = simulationDataItem
        const {
          T_supply: tSupply
        } = baseDataItem


        const tSupplyDelta = Math.abs(tSupply - tSupplyOpti)
        const optimizedThermalPower = tSupplyDelta * massFlow * WATER_CP
        optimizedPower.push({
          timestamp,
          optimizedPower: (thermalPower - optimizedThermalPower) * 0.3
        })
      }

      return optimizedPower
    },
    dataAggregates() {
      const thermalPowerData = this.simulationData
      const optimizedPower = this.optimizedPower
      const hasSupplyOpti = this.hasSupplyOpti
      // no point in computing min/max
      if (thermalPowerData.length <= 1) {
        return undefined
      }

      const resolution = this.$moment(thermalPowerData[1].timestamp).diff(thermalPowerData[0].timestamp, 'hours', true)

      let minThermalPower;
      let maxThermalPower;
      let totalThermalPower = 0;
      let totalOptimizedPower = 0

      for (const [index, item] of thermalPowerData.entries()) {
        const { Thermal_Power: thermalPower } = item
        if (minThermalPower === undefined || thermalPower < minThermalPower) minThermalPower = thermalPower
        if (maxThermalPower === undefined || thermalPower > maxThermalPower) maxThermalPower = thermalPower

        totalThermalPower += thermalPower

        // also compute optimized power
        if (hasSupplyOpti) {
          totalOptimizedPower += optimizedPower[index].optimizedPower
        }
      }

      totalThermalPower = totalThermalPower / resolution
      totalOptimizedPower = totalOptimizedPower / resolution

      return {
        min: minThermalPower,
        max: maxThermalPower,
        total: totalThermalPower,
        saved: totalThermalPower - totalOptimizedPower
      }
    },
    dataAggregatesSettings() {
      return {
        min: {
          label: this.$t("panels.plant.data_fields.thermal_power_min"),
          baseUnit: 'W',
          color: '#8cdcfe',
          textColor: 'black'
        },
        max: {
          label: this.$t("panels.plant.data_fields.thermal_power_max"),
          baseUnit: 'W',
          color: '#8cdcfe',
          textColor: 'black'
        },
        total: {
          label: this.$t("panels.plant.data_fields.thermal_power_total"),
          baseUnit: 'Wh',
          color: '#8cdcfe',
          textColor: 'black'
        },
        saved: {
          label: this.$t("panels.plant.data_fields.thermal_power_saved"),
          baseUnit: 'Wh',
          color: '#2566cb',
          textColor: 'white'
        }
      }
    },
    date: {
      get() {
        return this.$store.getters["mapPopup/date"];
      },
      set(value) {
        this.$store.dispatch("mapPopup/date", value);
      },
    },
    date_end: {
      get() {
        return this.$store.getters["mapPopup/date_end"];
      },
      set(value) {
        // console.log(value);
        this.$store.dispatch("mapPopup/date_end", value);
      },
    },
    chartOptions() {
      const date = this.$moment(this.date);
      const dateEnd = this.$moment(this.date_end);
      return {
        stroke: {
          curve: "smooth",
        },
        legend: {
          showForSingleSeries: true,
        },
        colors: ["#8cdcfe"],
        xaxis: {
          type: "datetime",
          min: date.startOf("day").valueOf(),
          max: dateEnd.endOf("day").valueOf(),
          labels: {
            datetimeUTC: true,
          },
        },
        yaxis: [
          {
            min: 0,
            labels: {
              formatter: (val) => `${val.toFixed(2)} kW`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter: this.tooltipFormatY,
          },
        },
      };
    },
  },
  mounted() {
    this.prepareData();
  },
  watch: {
    data() {
      this.prepareData();
    },
  },
  methods: {
    dataAggregatesValueUnit(field) {
      const { unit, baseUnit } = this.dataAggregatesSettings[field]
      let value = this.dataAggregates[field]
      if (unit) {
        return `${value.toFixed(2)} ${unit}`
      }

      const prefixes = ['k', 'M', 'G']
      let prefixIndex = 0
      for (; prefixIndex < prefixes.length; prefixIndex++) {
        if (value < 1000) {
          break;
        }

        value = value / 1000
      }

      return `${value.toFixed(2)} ${prefixes[prefixIndex]}${baseUnit}`
    },
    prepareData() {
      // const baseDataSets = this.prepareBaseData();
      const simulationDataSets = this.prepareSimulationData();

      const prefixSimulation = "";
      // console.log(simulationDataSets);
      const chartSeries = [
        {
          name:
            prefixSimulation +
            this.$t("panels.plant.data_fields.thermal_power"),
          data: simulationDataSets.thermalpower,
        }
      ];

      if (this.hasSupplyOpti) {
        chartSeries.push({
          name: prefixSimulation + this.$t("panels.plant.data_fields.thermal_power_opti"),
          data: simulationDataSets.optimizedPower,
          color: '#2566cb'
        })
      }

      this.chartSeries = chartSeries
    },
    prepareBaseData() {
      const thermalpower = [];
      const dataSupply = [];


      // for (const item of this.baseData) {
      //   const x = item.timestamp;
      //   if (item.Thermal_Power > 0) {
      //     thermalpower.push({
      //       x,
      //       y: item.Thermal_Power,
      //     });
      //   }
      //   // dataSupply.push({
      //   //   x,
      //   //   y: item.power_supply
      //   // })
      // }
      // console.log(thermalpower);
      return {
        thermalpower,
        dataSupply,
      };
    },
    prepareSimulationData() {
      const thermalpower = this.prepareNormalizedData(this.simulationData, 'Thermal_Power');
      const optimizedPower = this.hasSupplyOpti ? this.prepareNormalizedData(this.optimizedPower, 'optimizedPower') : []
      const dataSupply = [];
      return {
        thermalpower,
        dataSupply,
        optimizedPower
      };
    },
    tooltipFormatY(value) {
      return `${value?.toFixed(2)} kW`;
    },
  },
};
</script>
