import { clickStates } from "../../util/structs"

// initial state
const getDefaultState = () => {
  return {
    clickState: clickStates.IDLE,
    clickOptions: {},
    component: undefined
  }
}
const state = getDefaultState()

// getters
const getters = {
  clickState: state => state.clickState,
  clickOptions: state => state.clickOptions,
  component (state) {
    return state.component
  }
}

// actions
const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  setComponent ({ commit }, component) {
    commit('component', component)
  },
  setClickState ({ commit }, payload) {
    commit('click', payload)
  }
}

// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  click (state, payload) {
    state.clickState = payload.clickState
    state.clickOptions = payload.clickOptions || {}
  },
  component (state, component) {
    state.component = component
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
