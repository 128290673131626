<template>
  <panel name="node" :initial-active="active">
    <template v-slot:fixed-content>
      <v-card-text class="py-0">
        <div class="d-flex" style="gap: 24px">
          <v-btn color="secondary" @click="newNode(true)">
            <v-icon left>mdi-map-marker-radius</v-icon>
            {{ $t("panels.node.new_supply") }}
          </v-btn>
          <v-btn color="primary" @click="newNode(false)">
            <v-icon left>mdi-map-marker-radius</v-icon>
            {{ $t("panels.node.new_return") }}
          </v-btn>
        </div>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('general.search')" class="mt-3"/>
        <v-tabs v-model="tab">
          <v-tab tab-value="all">{{ $t('general.all') }}</v-tab>
          <v-tab tab-value="supply"><v-icon color="secondary" left>mdi-map-marker-radius</v-icon>{{ $t('general.supply')
          }}</v-tab>
          <v-tab tab-value="return"><v-icon color="primary" left>mdi-map-marker-radius</v-icon>{{ $t('general.return')
          }}</v-tab>
        </v-tabs>
      </v-card-text>
    </template>
    <v-card-text>
      <div class="pb-10" style="background-color: #fafafa">
        <v-data-table v-model="selected" :items="items" :headers="headers" :options.sync="options" :search="search">
          <template v-slot:[`item.name`]="{item}">
            <span :style="itemNameStyle(item)">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.supply`]="{ item }">
            <v-icon v-if="item.supply" color="green">mdi-check</v-icon>
            <v-icon v-else color="error">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.actions.place_node`]="{ item }">
            <v-btn icon @click="() => placeGeom(item)" :title="$t('panels.node.place_node')"
              :color="placeNodeColor(item)" :outlined="clickState === clickStates.PLACE_NODE &&
                clickOptions.id === item.id
                ">
              <v-icon>mdi-map-marker-radius</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions.locate_on_map`]="{ item }">
            <v-btn :disabled="!item.geom" icon @click="() => locateNode(item)" :title="$t('general.locate_on_map')"
              :color="itemActionColor(item)"><v-icon>mdi-map-marker-circle</v-icon></v-btn>
          </template>
          <template v-slot:[`item.actions.show_data`]="{ item }">
            <v-btn icon @click="() => openMapPopup(item)" :title="$t('general.show_data')"
              :color="itemActionColor(item)"><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-btn>
          </template>
          <template v-slot:[`item.actions.rest`]="{ item }">
            <span class="d-inline-flex">
              <v-btn icon @click="() => editNode(item)" :title="$t('general.edit')"
                color="green"><v-icon>mdi-pencil</v-icon></v-btn>
              <v-btn icon @click="() => deleteNode(item)" :title="$t('general.delete')"
                color="error"><v-icon>mdi-delete</v-icon></v-btn>
            </span>
          </template>
        </v-data-table>
        <node-edit-dialog v-if="editOpen" open :item="editItem" @close="closeEdit" />
        <node-delete-dialog v-if="deleteOpen" open :item="deleteItem" @close="closeDelete" />
      </div>
    </v-card-text>
  </panel>
</template>

<script>
import Panel from "../Panel.vue";
import { clickStates, featureTypes } from "../../../util/structs";
import NodeEditDialog from "./NodeEditDialog.vue";
import NodeDeleteDialog from "./NodeDeleteDialog.vue";
import { colors } from "vuetify/lib";

const highlighColor =
  process.env.VUE_APP_NODE_HIGHLIGHT_COLOR ?? colors.red.accent4;

export default {
  name: "NodePanel",
  props: {
    active: Boolean,
  },
  components: {
    Panel,
    NodeEditDialog,
    NodeDeleteDialog,
  },
  data() {
    return {
      tab: 'all',
      options: {},
      selected: [],
      editItem: undefined,
      editOpen: false,
      deleteItem: undefined,
      deleteOpen: false,
      search: "",
    };
  },
  computed: {
    clickStates() {
      return clickStates;
    },
    clickState() {
      return this.$store.getters["map/clickState"];
    },
    clickOptions() {
      return this.$store.getters["map/clickOptions"];
    },
    itemsUnfiltered() {
      return this.$store.getters["node/items"];
    },
    items() {
      if (this.tab === 'all') {
        return this.itemsUnfiltered
      }

      const desiredSupply = this.tab === 'supply'
      return this.itemsUnfiltered.filter(_ => _.supply === desiredSupply)
    },
    panelErrors() {
      return this.$store.getters["node/panelErrors"];
    },
    mapComponent() {
      return this.$store.getters["map/component"];
    },
    headers() {
      return [
        {
          text: this.$t("general.name"),
          value: "name",
        },
        {
          text: this.$t("panels.node.place_node"),
          value: "actions.place_node",
          align: "center",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("general.locate_on_map"),
          value: "actions.locate_on_map",
          align: "center",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("general.show_data"),
          value: "actions.show_data",
          align: "center",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("general.actions"),
          value: "actions.rest",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ];
    },
  },
  methods: {
    itemActionColor(item) {
      return item.supply ? 'secondary' : 'primary'
    },
    itemNameStyle(item) {
      const style = {}

      if (!item.geom) {
        style.color = highlighColor
      }
      return style
    },
    placeNodeColor(item) {
      return item.geom ? this.itemActionColor(item) : highlighColor
    },
    openMapPopup(item) {
      this.$store.dispatch("mapPopup/open", {
        item,
        type: featureTypes.NODE,
      });
    },
    highlightRow(item) {
      this.selected = [item];
      if (this.options.itemsPerPage === -1) return;
      // find correct page
      const index = this.items.findIndex((t) => t.id === item.id);
      if (index !== -1) {
        this.options.page = Math.floor(index / this.options.itemsPerPage) + 1;
      }
    },
    closeEdit() {
      this.editOpen = false;
      this.editItem = undefined;
    },
    newNode(isSupply = false) {
      if (isSupply) {
        this.openEdit({ supply: true, fid: null, name: "" });
      } else {
        this.openEdit();
      }
    },
    editNode(item) {
      this.openEdit(item);
    },
    openEdit(item) {
      this.editItem = item ? { ...item } : undefined;
      this.editOpen = true;
    },
    deleteNode(item) {
      this.deleteItem = item;
      this.deleteOpen = true;
    },
    closeDelete() {
      this.deleteOpen = false;
      this.deleteItem = undefined;
    },
    locateNode(item) {
      this.mapComponent.locateNode(item.id, item.supply);
    },
    placeGeom(item) {
      let payload = null;

      if (this.clickState === clickStates.PLACE_NODE) {
        payload = {
          clickState: clickStates.IDLE,
        };
      } else {
        payload = {
          clickState: clickStates.PLACE_NODE,
          clickOptions: {
            id: item.id,
          },
        };
      }

      this.$store.dispatch("map/setClickState", payload);
    },
  },
};
</script>
