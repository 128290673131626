import FieldService from "@/services/FieldService"

import i18n from '@/lang/lang'
import turfArea from '@turf/area'
import { roundWithPrecision } from "@/util/NumberUtil"

// initial state
const getDefaultState = () => {
  return {
    queryDone: false,
    fields: [],
    fieldId: undefined
  }
}

const state = getDefaultState()

// getters
const getters = {
  queryDone: state => state.queryDone,
  fields: state => state.fields,
  fieldId: state => state.fieldId,
  field(state) {
    return state.fields.find(item => item.id === state.fieldId)
  }
}

// actions
const actions = {
  resetFlags({ commit }) {
    commit('fieldId', undefined)
  },
  leaveCrops({ commit }) {
    commit('fieldId', undefined)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  viewCrops({ commit }, id) {
    commit('fieldId', id)
    commit('energyPlanner/panelErrors', false, { root: true })
  },
  async loadData({ commit }) {
    try {
      const response = await FieldService.index()
      commit('fields', response.data.fields)
    } catch (e) {
      console.error(e)
    }
  },
  resetState({ commit }) {
    commit('resetState')
  },
  async saveDrawnGeometry({ commit, dispatch }, payload) {
    commit('queryDone', true)
    return await dispatch('saveQueryGeometry', payload)
  },
  async saveQueryGeometry({ commit, rootGetters }, payload) {
    commit('energyPlanner/panelErrors', false, { root:true })
    commit('energyPlanner/loading', true, { root:true })
    let errors = false

    const geometry = payload.geometry

    try {
      const response = await FieldService.updateGeometry({
        id: payload.id,
        featureId: payload.featureId,
        geometry: JSON.stringify(geometry),
        area: (geometry.type === "Point") ? undefined : roundWithPrecision(turfArea(geometry), 2)
      })
      const field = response.data.field
      commit('saveFieldGeometry', field)
      // update field feature
      const mapComponent = rootGetters['map/component']
      mapComponent.refreshFieldFeature(field)
      commit('queryDone', false)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }
    commit('energyPlanner/panelErrors', errors, { root:true })
    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async saveField({ commit }, field) {
    commit('energyPlanner/loading', true, { root:true })
    const action = field.id !== undefined ? 'update' : 'store'

    let errors = false
    try {
      const response = await FieldService[action]({
        item: field
      })
      commit('saveField', response.data.field)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteField({ commit }, field) {
    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await FieldService.delete({
        item: field
      })
      commit('deleteField', field)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
  async saveCrop({ commit }, payload) {
    const crop = payload.crop
    const fieldId = payload.fieldId
    if (!fieldId) return

    commit('energyPlanner/loading', true, { root:true })
    const action = crop.id !== undefined ? 'updateCrop' : 'storeCrop'
    let errors = false
    try {
      const response = await FieldService[action]({
        fieldId,
        item: crop
      })
      commit('saveCrop', {
        fieldId,
        crop: response.data.crop
      })
    } catch (e) {
      if (e.response && e.response.status === 422) {
        errors = e.response.data.errors
      } else {
        errors = {
          general: i18n.t('panels.energyPlanner.general_error')
        }
        console.error(e)
      }
    }

    commit('energyPlanner/loading', false, { root:true })
    return errors
  },
  async deleteCrop({ commit }, payload) {
    const fieldId = payload.fieldId
    if (!fieldId) return
    const crop = payload.crop

    commit('energyPlanner/loading', true, { root:true })
    commit('energyPlanner/panelErrors', false, { root:true })
    try {
      await FieldService.deleteCrop({
        fieldId,
        item: crop
      })
      commit('deleteCrop', payload)
    } catch (e) {
      commit('panelErrors', {
        general: i18n.t('panels.energyPlanner.general_error')
      })
      console.error(e)
    }
    commit('energyPlanner/loading', false, { root:true })
  },
}

// mutations
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  queryDone(state, queryDone) {
    state.queryDone = queryDone
  },
  fieldId(state, fieldId) {
    state.fieldId = fieldId
  },
  fields(state, fields) {
    state.fields = fields
  },
  saveField(state, field) {
    const fields = state.fields

    const index = fields.findIndex(t => t.id === field.id)
    if (index === -1) {
      fields.push(field)
    } else {
      fields[index] = field
    }

    state.fields = [
      ...fields
    ]
  },
  saveCrop(state, payload) {
    const fieldIndex = state.fields.findIndex(t => t.id === payload.fieldId)
    if (fieldIndex === -1) {
      return // field not found - cant save crop
    }

    const field = state.fields[fieldIndex]

    const crop = payload.crop
    const crops = field.crops ?? []
    const cropIndex = crops.findIndex(t => t.id === crop.id)
    if (cropIndex === -1) {
      crops.push(crop)
    } else {
      crops[cropIndex] = crop
    }

    field.crops = [...crops]
  },
  saveFieldGeometry(state, field) {
    const fields = state.fields
    const index = fields.findIndex(t => t.id === field.id)
    if (index !== -1) {
      fields[index] = {
        ...state.fields[index],
        area: field.area,
        geom: field.geom
      }
      state.fields = [...fields]
    }
  },
  deleteField(state, field) {
    const fields = state.fields

    state.fields = fields.filter(t => t.id !== field.id)
  },
  deleteCrop(state, payload) {
    const fieldIndex = state.fields.findIndex(t => t.id === payload.fieldId)
    if (fieldIndex === -1) return

    const field = state.fields[fieldIndex]
    field.crops = field.crops.filter(t => t.id !== payload.crop.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
