<template>
    <div class="d-flex align-center text-uppercase">
        <span>{{ $t('general.mass_flow_kilogram') }}</span>
        <v-switch :input-value="value" @change="onChange" hide-details dense class="mt-0 ms-2"/>
        <span>{{ $t('general.mass_flow_cubic_meters') }}</span>
      </div>
</template>

<script>

export default {
    name: 'MassFlowSwitch',
    props: {
        value: Boolean
    },
    methods: {
        onChange() {
            this.$emit('input', !this.value)
        }
    }
}
</script>
