<template>
  <v-card :class="classes" tile>
    <v-card-title>
      <template v-if="$slots.title">
        <div>
          <slot name="title"></slot>
        </div>
      </template>
      <template v-else>
        {{ $t(`panels.${name}.title`) }}
      </template>
      <v-spacer />
      <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <div class="map-panel-content-wrapper">
      <div class="map-panel-actions" ref="actionsRef">
        <slot name="fixed-content"></slot>
      </div>
      <div class="map-panel-content">
        <slot></slot>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    name: String,
    initialActive: Boolean
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    classes() {
      return [
        'map-panel',
        'map-panel-right',
        `map-panel-${this.name}`,
        { active: this.active }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.active = true
      this.$store.dispatch('setActivePanelRef', {
        name: this.name,
        panel: this.$parent
      })
    })
  },
  destroyed() {
    this.$store.dispatch('setActivePanelRef', {
      name: this.name,
      panel: null
    })
  },
  methods: {
    close() {
      this.active = false
      window.setTimeout(() => {
        this.$store.dispatch('setActivePanel', false)
      }, 500)
    }
  },
  watch: {
    initialActive(value) {
      if (!value) {
        this.active = false
      }
    }
  }
}
</script>
