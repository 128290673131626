<template>
  <div>
    <portal to="energy-planner-fixed">
      <v-btn color="accent" @click="() => openEdit()">{{ $t('panels.energyPlanner.fields.new_crop') }}</v-btn>
      <v-btn class="ml-3" color="accent" @click="() => leaveCrops()" :title="$t('general.back')"><v-icon>mdi-keyboard-return</v-icon></v-btn>
    </portal>
    <v-card-text>
      <h5>{{ field.name }}</h5>
      <v-data-table
        :items="crops"
        :headers="headers"
      >
        <template v-slot:item.croptype="{item}">
          {{ $t('panels.energyPlanner.crop_type.' + item.croptype) }}
        </template>
        <template v-slot:item.croppercentage="{item}">
          {{ item.croppercentage }}%
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="() => openEdit(item)" :title="$t('general.edit')" color="accent"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon @click="() => openDelete(item)" :title="$t('general.delete')" color="error"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <CropEditDialog v-if="editOpen" :item="editItem" @close="closeEdit" />
    <CropDeleteDialog v-if="deleteOpen" :item="deleteItem" @close="closeDelete" />
  </div>
</template>

<script>
  import CropDeleteDialog from './CropDeleteDialog.vue'
  import CropEditDialog from './CropEditDialog.vue'

  export default {
    name: 'Crops',
    components: {
      CropDeleteDialog,
      CropEditDialog
    },
    data () {
      return {
        editItem: undefined,
        editOpen: false,
        deleteItem: undefined,
        deleteOpen: false
      }
    },
    computed: {
      panelErrors () {
        return this.$store.getters['energyPlanner/panelErrors']
      },
      loading () {
        return this.$store.getters['energyPlanner/loading']
      },
      field () {
        return this.$store.getters['energyPlanner/field/field']
      },
      crops () {
        return this.field ? this.field.crops : []
      },
      headers () {
        return [
          {
            text: this.$t('panels.energyPlanner.attributes.field.crop.type'),
            value: 'croptype'
          },
          {
            text: this.$t('panels.energyPlanner.attributes.field.crop.percentage'),
            value: 'croppercentage',
          },
          {
            text: this.$t('general.actions'),
            value: 'actions',
            align: 'end',
            sortable: false,
            filterable: false
          }
        ]
      }
    },
    methods: {
      closeEdit () {
        this.editOpen = false
        this.editItem = undefined
      },
      openEdit (item) {
        this.editItem = item ? { ...item } : undefined
        this.editOpen = true
      },
      openDelete (item) {
        this.deleteItem = item
        this.deleteOpen = true
      },
      closeDelete () {
        this.deleteOpen = false
        this.deleteItem = undefined
      },
      leaveCrops() {
        this.$store.dispatch('energyPlanner/field/leaveCrops')
      }
    }
  }
</script>
