<template>
  <div>
    <div>
      <h5>{{ $t('panels.energyPlanner.battery.section_header') }}</h5>
      <div class="d-flex" style="gap: 8px">
        <template v-if="item.battery">
          <v-btn color="accent" @click="batteryEdit" :outlined="showForm"
            :title="$t('general.edit')"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn color="error" @click="batteryDelete"
            :title="$t('general.delete')"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <template v-else>
          <v-btn color="accent" :title="$t('general.new')" @click="batteryEdit"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
      </div>
    </div>
    <div v-if="showForm">
      <v-row align="center">
        <v-col cols="12" sm="6">
          <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.batterycapacity') + '*'" type="number"
            v-model.number="item.battery.batterycapacity" :rules="[rules.required, rules.validFloat]"
            :error-messages="errorMessages('battery.batterycapacity')" suffix="kWh" />
        </v-col>
        <v-col cols="12" sm="6">
          <template v-if="configureBattery">
            <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.specificbatterycost')" type="number"
              v-model.number="item.battery.specificbatterycost" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('battery.specificbatterycost')" persistent-placeholder
              :placeholder="defaultBattery.specificbatterycost.toString()" suffix="€/kWh" />
          </template>
          <template v-else>
            <v-btn @click="configureBattery = true">{{ $t('panels.energyPlanner.battery.configure') }}</v-btn>
          </template>
        </v-col>
      </v-row>
      <template v-if="configureBattery">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.startingcapacity')" type="number"
              v-model.number="item.battery.startingcapacity" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('battery.startingcapacity')" persistent-placeholder
              :placeholder="defaultBattery.startingcapacity.toString()" suffix="%" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.nominalbatterycapacity')" type="number"
              v-model.number="item.battery.nominalbatterycapacity" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('battery.nominalbatterycapacity')" persistent-placeholder
              :placeholder="defaultBattery.nominalbatterycapacity.toString()" suffix="kWh" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.nominaldischargepower')" type="number"
              v-model.number="item.battery.nominaldischargepower" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('battery.nominaldischargepower')" persistent-placeholder
              :placeholder="defaultBattery.nominaldischargepower.toString()" suffix="kW" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :label="$t('panels.energyPlanner.attributes.building.unit.battery.nominalchargingpower')" type="number"
              v-model.number="item.battery.nominalchargingpower" :rules="[rules.validOptionalFloat]"
              :error-messages="errorMessages('battery.nominalchargingpower')" persistent-placeholder
              :placeholder="defaultBattery.nominalchargingpower.toString()" suffix="kW" />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import RulesUtil from '@/util/RulesUtil';

const defaultBattery = {
  specificbatterycost: 0,
  startingcapacity: 10,
  nominalbatterycapacity: 11.04,
  nominaldischargepower: 10.2,
  nominalchargingpower: 10.2
}

export default {
  name: 'BatteryForm',
  props: {
    errors: {
      type: [Boolean, Object],
      default: false
    },
    item: {
      type: Object,
      default() {
        return {

        }
      }
    }
  },
  data() {
    return {
      showForm: false,
      configureBattery: false
    }
  },
  computed: {
    rules() {
      return RulesUtil
    },
    defaultBattery() {
      return defaultBattery
    }
  },
  methods: {
    batteryEdit() {
      if (!this.item.battery) {
        this.item.battery = {}
      }
      this.showForm = true
    },
    batteryDelete() {
      this.showForm = false
      this.item.battery = undefined
    },
    errorMessages(field) {
      if (this.errors) {
        return get(this.errors, field)
      }

      return ''
    }
  }
}
</script>
