import Vue from 'vue'
import App from './views/App.vue'
import i18n, { DEFAULT_LANG } from './lang/lang'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'

// window.plotty = require('plotty')
window.Vue = require('vue')

// moment js
const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
  moment
});

// apex charts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
const apexLocaleDe = require('apexcharts/dist/locales/de.json')
// const apexLocaleIt = require('apexcharts/dist/locales/it.json')
const apexLocaleFr = require('apexcharts/dist/locales/fr.json')
const apexLocaleEn = require('apexcharts/dist/locales/en.json')
window.Apex.chart = {
  // locales: [apexLocaleDe, apexLocaleEn, apexLocaleFr, apexLocaleIt],
  locales: [apexLocaleDe, apexLocaleEn, apexLocaleFr],
  defaultLocale: DEFAULT_LANG
}

// portal
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// vue-meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false;


new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
